<template>
  <el-dialog :visible.sync="visible" center :close-on-click-modal="false" width="60%">
    <!-- 标题 -->
    <div slot="title" class="header-title text-left">
      <span>不动产权证号统一赋值</span>
    </div>
    <!-- 表单区域 -->
    <dynamic-form ref="form" :form-config="formConfig" :formModel="formData" />
    <!-- 底部操作按钮 -->
    <span slot="footer">
      <el-button @click="submit" size="small" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import rules from '@/utils/validate.js';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import validate from '@iebdc/mixin/validate';

export default {
  mixins: [validate],
  data() {
    return {
      // 弹窗状态
      visible: false,
      formData: {},
      // 表单配置
      formConfig: [
        {
          title: '不动产权证号',
          span: 24,
          type: 'chooseBdcqzh',
          key: 'bdcqzh',
          props: {
            mbanType: '证书模板'
          },
          rule: [rules.required]
        }
      ]
    };
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData'])
  },
  methods: {
    // 打开弹窗
    open() {
      this.visible = true;
      this.$nextTick(() => {
        // 清空数据
        this.$refs.form.resetFields;
      });
    },
    // 接口提交成功
    success(msg) {
      this.$emit('refresh');
      this.visible = false;
      this.$message.success(msg);
    },
    // 新增权利人
    async submit() {
      let data = {
        bdcdyhs: [],
        params: this.$refs.form.formModel,
        ywh: this.flowInfoIng.ywh
      };
      this.processData[this.flowInfoIng.index].qlxxExMhs.forEach((item) => {
        if (item.bdcdyh) {
          data.bdcdyhs.push(item.bdcdyh);
        }
      });
      this.validateForm(this.$refs.form.$refs.form, () => {
        applicantOperatorService.unifiedAssignment(data).then((res) => {
          if (res.success && res.data) {
            this.success('保存成功');
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';
/deep/ .el-dialog__header {
  margin: 0 26px;
  padding: 20px;
  font-size: $font-size-medium;
  font-weight: bold;
  border-bottom: $border-base;
}
/deep/ .el-dialog--center .el-dialog__body {
  max-height: 450px;
  overflow-y: auto;
  @extend .scrollbar-default;
  padding: 0 26px 30px;
}
.header-title {
  font-weight: bold;
  font-size: $font-size-medium;
  .el-button {
    margin-left: $spacing-medium;
  }
}
</style>
