<template>
  <el-table ref="e-table" :data="tableData" row-key="id" height="440px" @selection-change="selectionChangeHandle">
    <el-table-column type="selection" width="55" align="center"></el-table-column>
    <el-table-column type="index" label="序号" align="center"></el-table-column>
    <el-table-column type="fileUrl" label="缩略图" align="center">
      <template slot-scope="scope">
        <el-image
          style="width: 80px; height: 40px; background-color: #cccccc;"
          fit="fill"
          :src="scope.row.fileUrl"
          :preview-src-list="[scope.row.fileUrl]"
        ></el-image>
      </template>
    </el-table-column>
    <el-table-column prop="clmc" label="文件名" align="left">
      <template slot-scope="scope">
<!--        {{ scope.row.clmc.substring(0, scope.row.clmc.indexOf(scope.row.clfl)) }}{{ scope.row.clfl }}{{ scope.$index + 1 }}-->
        <span :class="scope.row.sfbz === '1' ? 'f-c' : 'xx'"
          >{{ scope.row.clmc.substring(0, scope.row.clmc.indexOf(scope.row.clfl)) }}{{ scope.row.clfl }}{{ scope.$index + 1 }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="index" label="页码" align="center">
      <template slot-scope="scope">第 {{ scope.$index + 1 }} 页</template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template slot-scope="scope">
        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        <template v-if="hasUpDownBtn">
          <el-button
            class="base-btn"
            plain
            type="primary"
            icon="el-icon-top"
            v-if="scope.$index > 0"
            @click="moveHandle(scope.$index, 'UP')"
          ></el-button>
          <el-button
            class="base-btn"
            plain
            type="info"
            icon="el-icon-bottom"
            v-if="scope.$index < tableData.length - 1"
            @click="moveHandle(scope.$index, 'DOWN')"
          ></el-button>
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { up, down } from './array.operation.js';
import Sortable from 'sortablejs';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';

export default {
  data() {
    return {
      key: 'id_default',
      hasUpDownBtn: false,
      selected: [],
      sortObj: ''
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    updateTable() {
      this.updateKeyAfterRowDrop();
    },
    selectionChangeHandle(val) {
      // console.log(val,'当前勾选图片')
      val.forEach((c) => {
        this.selected.push(c);
      });
      this.$emit('selection-change', val);
    },
    getSelected() {
      console.log(this.selected,'当前已经选中的')
      return this.selected;
    },
    emitHandle() {
      this.$emit('change', this.tableData);
    },
    updateKey() {
      this.key = `id_${new Date().getTime()}`;
    },
    updateIndex() {
      this.tableData.forEach(() => {});
    },
    indexMethod(index) {
      return index + 1;
    },
    removeAllHandle() {
      this.tableData.forEach((c) => {
        this.deleteFile(c);
      });
    },
    handleDelete(index, row) {
      this.deleteFile(row);
      this.$emit('delete', index);
    },
    deleteFile(item) {
      applicantUploadService.delImage({ id: item.fileId }).then((res) => {
        if (res.success) {
          this.$message.success('删除成功！');
        } else {
          this.$message.error(res.message);
        }
      });
    },
    moveHandle(index, type) {
      let fn = type === 'UP' ? up : down;
      fn(this.tableData, index);
      this.updateKey();
    },
    updateKeyAfterRowDrop() {
      //this.key = `id_${new Date().getTime()}`;
      this.$nextTick(() => {
        this.rowDrop();
        this.selected = [];
      });
    },
    //设置排序
    setSort() {
      let tbody = document.querySelector('.el-table__body-wrapper tbody');
      const _this = this;
      this.sortObj = Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currRow);
          _this.emitHandle();

          _this.updateKeyAfterRowDrop();
        }
      });
    },
    rowDrop() {
      if (!this.sortObj) {
        this.setSort();
      }
      this.$nextTick(() => {
        let timer = setInterval(function() {
          let table = document.getElementsByClassName('el-table__body-wrapper is-scrolling-none')[0];
          if (table && table.scrollHeight) {
            table.scrollTop = table.scrollHeight;
            if (table.scrollTop > 0) {
              clearInterval(timer);
            }
          }
        }, 200);
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.rowDrop();
      //console.log('初始化');
    });
  }
};
</script>

<style lang="less" scoped>
.base-btn {
  /deep/ i {
    font-weight: bolder;
  }
}
/deep/ .el-table__body-wrapper .is-scrolling-none {
  display: block;
}
.f-c {
  color: red;
}
</style>
