<template>
  <div class="main">
    <div v-for="(group, groupIndex) in tableData" :key="groupIndex">
      <div
        v-if="group.familyGroup && group.familyGroup.familyMemberList && group.familyGroup.familyMemberList.length > 0"
      >
        <!-- 表格标题 -->
        <div class="type-title">
          <h5>
            <span class="span"
              ><img src="@iebdc/assets/images/family.png" />{{ group.qlrmc || group.ywrmc }}的家庭组</span
            >
            <div class="family-title" v-show="!disabled">
              <el-dropdown @command="addFamily">
                <el-button type="primary" class="add-button" plain size="mini"
                  ><i class="el-icon-circle-plus-outline"></i>添加家庭成员
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="addFamilyHandle(item, groupIndex)"
                    v-for="(item, index) in tableData"
                    :key="index"
                    >{{ item.qlrmc || item.ywrmc }}</el-dropdown-item
                  >
                  <el-dropdown-item :command="addFamilyHandle({}, groupIndex)">其它家庭成员</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" plain size="mini" @click="deleteFamilyGroup(groupIndex)">
                <i class="el-icon-delete"></i>删除家庭组</el-button
              >

              <!--  -->
              <el-form ref="famliyform" :model="group.familyGroup" label-width="130px" class="form-self">
                <el-form-item label="家庭房屋套数" prop="houseNumber" :rules="[rules.required, rules.number]">
                  <el-input
                    v-model="group.familyGroup.houseNumber"
                    @input="changeHouseNumber(group)"
                    placeholder="请填写家庭为单位拥有的所有房屋套数"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </h5>
        </div>
        <!-- 表格区域 -->
        <div v-for="(data, i) in group.familyGroup.familyMemberList" :key="i">
          <div class="operator" v-show="!disabled">
            <span class="sqr-name-title"><span class="circle"></span>{{ data.name }}</span>
            <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
              <el-link
                class="update-button"
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="update(data, i, groupIndex)"
                >修改</el-link
              >
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
              <el-link
                class="delete-button"
                type="primary"
                icon="el-icon-delete"
                size="mini"
                @click="deletedata(data, i, groupIndex)"
                >删除</el-link
              >
            </el-tooltip>
          </div>
          <dynamic-form-old
            ref="sqr"
            v-if="familyColumnConfig"
            :form-config="familyColumnConfig"
            :formModel="data"
            :disabled="true"
          >
          </dynamic-form-old>
        </div>
        <!-- 弹窗 -->
        <add-family
          ref="dialog"
          v-if="familyColumnConfig"
          @updateService="updateService"
          :formConfig="familyColumnConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rules from '@/utils/validate.js';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import addFamily from '@iebdc/views/apply/flow/applicantTable/dialog/addFamily.vue';
export default {
  components: { addFamily },
  props: {
    familyTableConfig: {},
    familyColumnConfig: {},
    tableData: Array,
    disabled: Boolean,
  },
  data() {
    return {
      rules: rules,
      buttonList: [
        {
          label: '修改',
          name: this.update,
        },
        {
          label: '删除',
          name: this.deletedata,
        },
      ],
      //当前增加修改的家庭组
      familyGroupModel: {},
    };
  },
  // mounted() {
  //   console.log(this.tableData);
  // },
  methods: {
    initFamilyGroup() {
      return {
        familyMemberList: [],
        houseNumber: '0',
      };
    },
    openDialog(descDialog) {
      this.$refs.dialog[descDialog.groupIndex].open({ ...descDialog });
    },
    // 重新获取数据
    getData() {
      this.$emit('getData');
    },
    //更新数据
    setFamilyData(sqrRow) {
      this.addFamilyGroup(sqrRow);
    },
    initFamilyPerson(sqr) {
      let person = {
        name: '',
        familyRelationshipEnum: '',
        zjzl: '1',
        zjh: '',
      };
      //如果menter(成员)有值 则赋值
      if (sqr) {
        //新建成员户主 成员关系为本人
        person.name = sqr.ywrmc || sqr.qlrmc;
        person.familyRelationshipEnum = '';
        person.zjzl = sqr.zjzl;
        person.zjh = sqr.zjh;
      }
      return person;
    },
    initFamilyParams(row, familyGroup) {
      let data = {
        type: row && row.qlrmc ? 'qlr' : 'ywr',
        params: {},
      };
      data.params = {
        sqrId: row.id,
        familyGroup: {
          familyMemberList: familyGroup.familyMemberList || [],
          houseNumber: familyGroup.houseNumber,
        },
      };
      return data;
    },
    //增加
    addFamilyGroup(sqrData) {
      let person = this.initFamilyPerson(sqrData);
      person.familyRelationshipEnum = 'SELF';
      this.familyGroupModel = Object.assign({}, sqrData);
      //如果没有家庭组
      if (!this.familyGroupModel.familyGroup) {
        this.familyGroupModel.familyGroup = this.initFamilyGroup();
      }
      if (!this.familyGroupModel.familyGroup.familyMemberList) {
        this.familyGroupModel.familyGroup.familyMemberList = [];
      }
      this.familyGroupModel.familyGroup.familyMemberList.push(person);
      this.familyGroupModel.familyGroup.houseNumber = this.houseNumberTemp;
      const params = this.initFamilyParams(this.familyGroupModel, this.familyGroupModel.familyGroup);
      this.saveFamilyGroup(params);
    },
    //删除家庭组
    deleteFamilyGroup(groupIndex) {
      // 删除操作
      this.$confirm('是否删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.tableData[groupIndex].familyGroup.familyMemberList = [];
          this.houseNumberTemp = '';
          const params = this.initFamilyParams(this.tableData[groupIndex], this.tableData[groupIndex].familyGroup);
          this.saveFamilyGroup(params);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //addFamilyhandle 添加家庭成员的数据
    addFamilyHandle(sqr, groupIndex) {
      return {
        sqr: sqr,
        groupIndex: groupIndex,
      };
    },
    //增加新的家庭成员  data.sqr 当前选中的家庭成员 groupIndex增加到的组
    addFamily(data) {
      //家庭组参数
      let person = this.initFamilyPerson(data.sqr);
      this.familyGroupModel = this.tableData[data.groupIndex];
      this.openDialog({
        row: person,
        index: this.familyGroupModel.familyGroup.familyMemberList.length,
        groupIndex: data.groupIndex,
      });
    },
    //修改
    update(row, index, groupIndex) {
      this.openDialog({ row: row, index: index, groupIndex: groupIndex });
    },
    updateService(data) {
      this.familyGroupModel = this.tableData[data.groupIndex];
      let params = Object.assign({}, this.familyGroupModel);
      params.familyGroup.familyMemberList[data.index] = data.value;
      params = this.initFamilyParams(this.familyGroupModel, this.familyGroupModel.familyGroup);
      this.saveFamilyGroup(params);
    },
    deletedata(row, index, groupIndex) {
      // 删除操作
      this.$confirm('是否删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.familyGroupModel = this.tableData[groupIndex];
          let params = Object.assign({}, this.familyGroupModel);
          params.familyGroup.familyMemberList.splice(index, 1);
          params = this.initFamilyParams(this.familyGroupModel, this.familyGroupModel.familyGroup);
          this.saveFamilyGroup(params);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //修改家庭组套数
    changeHouseNumber(group) {
      const params = this.initFamilyParams(group, group.familyGroup);
      this.saveFamilyGroup(params);
    },
    //保存家庭组
    saveFamilyGroup(params) {
      this.houseParams = params;
      applicantOperatorService
        .editFamilyList(params)
        .then((res) => {
          if (res.success) {
            // 提交成功
            this.$message.success('操作成功');
            this.$emit('refresh');
          } else {
            this.$emit('refresh');
            this.$message.error(res.message);
          }
        })
        .catch((error) => {
          this.$emit('refresh');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';
.table-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  h5 {
    position: relative;
  }
  .family-title {
    display: flex;
    /deep/.el-form-item {
      margin-bottom: 0px;
    }
    .add-button {
      margin: 0px 10px;
    }
  }
}
.type-title {
  display: block;
  text-align: left;
  background-color: #ffffff;
  border-left: 1px solid #ddd;
  h5 {
    padding: 25px 0px 0px 5px;
    font-size: 15px;
    color: #333;
    font-weight: 600;
    .family-title {
      display: inline-block;
    }
    .span {
      padding-left: 10px;
      img {
        vertical-align: text-bottom;
        margin-right: 10px;
      }
    }
  }
  .el-button {
    margin-left: 10px;
    i {
      padding-right: 5px;
      font-size: 14px;
      vertical-align: text-bottom;
    }
  }
}
.operator {
  position: relative;
  text-align: right;
  height: 40px;
  line-height: 40px;
  //border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  .sqr-name-title {
    position: absolute;
    left: 35px;
    font-size: 14px;
    color: #000;
  }
  a {
    margin: 0px 10px;
  }
  .circle {
    width: 7px;
    height: 7px;
    background: #2c8bff;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
}

.el-button--primary.is-plain {
  color: #2c8bff;
  background: rgba($color: #000000, $alpha: 0);
  border-color: #2c8bff;
}
/deep/ .family-title {
  .el-input.el-input--small {
    position: absolute;
    width: 150px;
  }
  .el-input--small .el-input__inner {
    border-radius: 4px;
  }
  .star {
    color: red;
    margin-left: 20px;
    margin-right: 2px;
  }
  .num {
    font-weight: 400;
    font-size: 13px;
  }
}
.form-self {
  display: inline-block;
  position: absolute;
}
</style>
