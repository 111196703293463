export default {
  methods: {
    validateForm(form, callback) {
      form.validate((valid) => {
        if (valid) {
          callback();
        } else {
          this.$message.error('请完善表单');
        }
      });
    }
  }
};
