<template>
  <div class="block">
    <div class="flex fellWidth" v-for="(bdcqzh, bdcqzhIndex) in bdcqzhList" :key="bdcqzhIndex">
      <div class="width400">
        <el-select
          v-model="bdcqzh.selectedMban"
          @change="change(bdcqzh)"
          placeholder="请选择不动产证号模板"
          size="small"
          :disabled="disabled"
        >
          <el-option v-for="(pitem, index) in bdcqzh.moban" :key="pitem.name" :label="pitem.name" :value="index">
          </el-option>
        </el-select>
      </div>
      <div class="flex1 mobanList">
        <el-form class="flex flex1" ref="bdcqzhList" :model="bdcqzhList[bdcqzhIndex]">
          <div
            class="moban"
            :class="{
              flex1:
                index != 0 ||
                bdcqzh.moban[bdcqzh.selectedMban].start == 0 ||
                (index == 0 && bdcqzh.moban[bdcqzh.selectedMban].array.length == 1),
            }"
            v-for="(item, index) in bdcqzh.moban[bdcqzh.selectedMban].array"
            :key="index"
          >
            <el-form-item
              class="margin0 flex1"
              v-if="
                !(
                  index == 0 &&
                  bdcqzh.moban[bdcqzh.selectedMban].start != 0 &&
                  bdcqzh.moban[bdcqzh.selectedMban].array.length > 1
                )
              "
              :prop="'num.bdcqzh' + index"
            >
              <el-input
                class="form-control"
                v-model="bdcqzhList[bdcqzhIndex].num['bdcqzh' + index]"
                :disabled="disabled"
                :placeholder="bdcqzhList[bdcqzhIndex].placeholder['bdcqzh' + index]"
              ></el-input>
            </el-form-item>
            <span v-show="bdcqzh.moban[bdcqzh.selectedMban].start > -1">{{ item }}</span>
          </div>
        </el-form>
      </div>

      <!-- <div class="flex1 mobanList" v-if="bdcqzh.bdcqzhs">
        <el-form class="flex flex1" ref="bdcqzhList" :model="bdcqzhList[bdcqzhIndex]">
          <el-form-item class="margin0 flex1" :prop="'num.bdcqzh' + bdcqzhIndex">
            <el-input
              class="form-control"
              v-model="bdcqzh.bdcqzhs"
              :disabled="disabled"
              :placeholder="bdcqzh.bdcqzhs"
            ></el-input>
          </el-form-item>
          <span v-show="bdcqzh.moban[bdcqzh.selectedMban].start > -1">{{ item }}</span>
         </el-form>
      </div> -->
      <div class="width200" v-if="$hasPermission('IEBDC:TXSQB:BDCQZHADD')">
        <el-button type="primary" @click.stop="add()">添加</el-button>
        <el-button type="primary" v-if="bdcqzhList.length > 1" @click.stop="deleteOne(bdcqzhIndex)">删除</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { bdcMban } from '@public/bdcMban.js';
import { getCookie } from '@iebdc/utils/cookies';
export default {
  props: {
    bdcqzhData: {
      type: String,
      default: '其它',
    },
    bdcqzhConfig: {},
  },
  data() {
    return {
      required: {
        required: true,
        message: '必填',
        trigger: 'change',
      },
      bdcqzhConfigInit: {},
      disabled: (this.bdcqzhConfig && this.bdcqzhConfig.disabled) || false,
      mbanConfig: [], //配置的模板数组
      bdcqzhList: [], //不动产权证号组
      // selectedMban: "",
    };
  },
  created() {
    let bdcqzhConfigInit = {
      mbanType: '证书模板',
      disabled: false,
    };
    this.bdcqzhConfigInit = Object.assign(bdcqzhConfigInit, this.bdcqzhConfig);
    //1,找出对应模板 2,根据***分割模板插入input
    this.getMbanConfig();
    //给模板数组赋初始值
    this.setBdcqzhList();
  },
  watch: {
    bdcqzhList: {
      handler(newVal) {
        if (!newVal) return;
        let bdcqData = [];
        this.bdcqzhList.forEach((item) => {
          if (!item.num.bdcqzh0 && !item.num.bdcqzh1 && !item.num.bdcqzh2 && !item.num.bdcqzh3) return;
          let bdcqzhs = '';
          item.moban[item.selectedMban].array.forEach((vm, index) => {
            if (
              Number(item.moban[item.selectedMban].start) !== 0 &&
              index === 0 &&
              item.moban[item.selectedMban].array.length === 1
            ) {
              bdcqzhs = bdcqzhs + item.num['bdcqzh' + index];
            } else if (
              Number(item.moban[item.selectedMban].start) !== 0 &&
              index === 0 &&
              item.moban[item.selectedMban].array.length > 1
            ) {
              item.num['bdcqzh' + index] = item.num['bdcqzh' + index] || '';
              bdcqzhs = vm + item.num['bdcqzh' + index];
            } else {
              bdcqzhs = bdcqzhs + item.num['bdcqzh' + index] + vm;
            }
          });
          bdcqData.push(bdcqzhs);
        });

        for (let index = 0; index < this.bdcqzhList.length; index++) {
          this.$refs.bdcqzhList[index].validate((valid) => {});
        }
        // for (const key in this.bdcqzhList) {
        //   this.$refs.bdcqzhList[key].validate((valid) => {});
        // }

        this.$emit('update:bdcqzhData', bdcqData.join(','));
      },
      deep: true,
    },
  },
  methods: {
    //找出对应模板配置
    getMbanConfig() {
      bdcMban.forEach((item) => {
        if (this.bdcqzhConfigInit.mbanType === item.mbanType) {
          this.mbanConfig = item.mbanConfig;
          //分割模板
          this.getSpliceMban();
        }
      });
    },
    //分割模板
    getSpliceMban() {
      this.mbanConfig.forEach((item) => {
        item.start = item.name.indexOf('***'); //第一个***开始的位置
        item.array = item.name.split('***'); //根据***分割数组
        if (!item.array[0]) {
          item.array.splice(0, 1);
        }
      });
    },
    //初始化赋值
    setBdcqzhList() {
      let mbanConfigLen = this.mbanConfig.length;
      this.bdcqzhList = [
        {
          moban: this.mbanConfig,
          bdcqzhs: '',
          num: {},
          placeholder: {},
          selectedMban: mbanConfigLen - 1,
        },
      ];
      if (this.bdcqzhData && this.bdcqzhData !== '') {
        let array = this.bdcqzhData.split(',');
        // // array.forEach((item0) => {
        // //   if (item0) {
        // //     if (item0.substr(0, 1) === "新") {
        // //       this.selectedMban = 0;
        // //     } else if (item0.substr(4, 1) === "国") {
        // //       this.selectedMban = 1;
        // //     } else if (item0.substr(4, 1) === "放") {
        // //       this.selectedMban = 2;
        // //     } else {
        // //       this.selectedMban = 3;
        // //     }
        // //   }
        // // });
        this.bdcqzhList = array.map((item) => ({
          moban: this.mbanConfig,
          bdcqzhs: item || '',
          num: {
            bdcqzh0: item || '',
          },
          placeholder: {
            bdcqzh0: '',
          },
          // selectedMban: this.selectedMban,
          selectedMban: mbanConfigLen - 1,
        }));
      }
    },
    //添加不动产权证号
    add() {
      this.bdcqzhList.push({
        moban: this.mbanConfig,
        bdcqzhs: '',
        num: {},
        placeholder: {},
        selectedMban: this.mbanConfig.length - 1,
      });
    },
    //删除不动产权证号
    deleteOne(index) {
      this.bdcqzhList.splice(index, 1);
    },
    //修改模板
    change(bdcqzh) {
      if (bdcqzh.moban[bdcqzh.selectedMban] && bdcqzh.moban[bdcqzh.selectedMban].array.length > 1) {
        const date = new Date();
        this.$set(bdcqzh.num, 'bdcqzh0', '');
        this.$set(bdcqzh.num, 'bdcqzh1', '');
        this.$set(bdcqzh.num, 'bdcqzh2', '');
        this.$set(bdcqzh.num, 'bdcqzh3', '');
        if (bdcqzh.moban[bdcqzh.selectedMban].start === 0) {
          this.$set(bdcqzh.placeholder, 'bdcqzh0', date.getFullYear());
          this.$set(bdcqzh.placeholder, 'bdcqzh1', getCookie('selectedAreaTitle'));
          this.$set(bdcqzh.placeholder, 'bdcqzh2', '0000001');
          this.$set(bdcqzh.placeholder, 'bdcqzh3', '');
        } else {
          this.$set(bdcqzh.placeholder, 'bdcqzh0', '');
          this.$set(bdcqzh.placeholder, 'bdcqzh1', date.getFullYear());
          this.$set(bdcqzh.placeholder, 'bdcqzh2', getCookie('selectedAreaTitle'));
          this.$set(bdcqzh.placeholder, 'bdcqzh3', '0000001');
        }
      } else {
        this.$set(bdcqzh.num, 'bdcqzh0', '其它');
        bdcqzh.placeholder = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block;
}
.flex1 {
  flex: 1;
  padding-right: 10px;
}
.flex1:last-child {
  padding-right: 0px;
}
.fellWidth {
  width: 100%;
}
.mobanList {
  display: flex;
}
.moban {
  display: flex;
  .flex1:last-child {
    padding-right: 0px;
  }
}
.form-control {
  flex: 1;
}
.width200 {
  width: 200px;
  text-align: right;
}
.width400 {
  width: 25%;
  margin-right: 10px;
}
.el-select {
  width: 100%;
}
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}

/deep/.el-input__inner:focus::-webkit-input-placeholder {
  color: transparent;
}
/* Firefox < 19 */
/deep/.el-input__inner:focus:-moz-placeholder {
  color: transparent;
}
/* Firefox > 19 */
/deep/.el-input__inner:focus::-moz-placeholder {
  color: transparent;
}

/* Internet Explorer 10 */
/deep/.el-input__inner:focus:-ms-input-placeholder {
  color: transparent;
}
</style>
