<template>
  <el-dialog
    title="捷宇高拍仪"
    width="80%"
    :visible.sync="visible"
    :before-close="handleClose"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <el-row :gutter="16" v-if="visible" v-loading="ready" element-loading-text="加载高拍仪中......">
      <el-col :span="12">
        <div class="gpy-container">
          <img :src="imgSrc" id="bigPriDev" />
        </div>
        <div class="operation">
          <div class="gpyBtn">
            <div class="topBtn">
              <el-button @click="start('bStartPlay')" icon="undo">启动主摄像头</el-button>
              <el-button @click="start('bStartPlay2')" icon="redo">启动副摄像头</el-button>
              <el-button @click="start('bStopPlay')" icon="redo">停止</el-button>
            </div>
          </div>
          <div class="gpy-photo-btns">
            <el-button @click="goPhoto()" class="camera-btn" type="primary" icon="el-icon-camera">拍照 </el-button>
            <el-button @click="initGpy()" class="initGpyBtn" type="primary" icon="el-icon-refresh"
              >初始化高拍仪
            </el-button>
            <el-button @click="visible = false" class="initGpyBtn" type="primary" icon="el-icon-circle-close"
              >完成
            </el-button>
            <!--<el-button @click="uploadPhotoHandle()" class="uploadPhotoBtn" type="primary" icon="el-icon-upload">-->
            <!--上传图片-->
            <!--</el-button>-->
          </div>
        </div>
      </el-col>
      <el-col :span="12" v-if="singleFile">
        <p class="single-title">拍照预览</p>
        <img v-if="singleTempUrl" :src="singleTempUrl" style="width: 100%; height: 400px; background-color: #cccccc" />
        <el-image
          v-else
          style="width: 100%; height: 400px; background-color: #cccccc"
          fit="fill"
          :src="singleImgUrl"
          :preview-src-list="[singleImgUrl]"
        >
        </el-image>
      </el-col>
      <el-col :span="12" v-else>
        <div>
          <el-button @click.stop="tableBtnsHandle('qzy')">签章页</el-button>
          <el-button @click.stop="tableBtnsHandle('qxy')">期限页</el-button>
          <el-button @click.stop="tableBtnsHandle('jey')">金额页</el-button>
          <el-button @click.stop="deleteAllPhoto" type="danger" icon="el-icon-delete" style="float: right">
            清空
          </el-button>
        </div>
        <DragTable ref="DragTableRef" :tableData="gpyImgList" @delete="deleteHandle" @change="changeHandle" />
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import DragTable from './drag-table/index.vue';
import { base64UrlToBlob } from './oprations.utils';

export default {
  components: { DragTable },
  data() {
    return {
      singleTempUrl: null,
      // 是否是单文件
      singleFile: false,
      fileNamePrefix: '合同',
      ready: true,
      visible: false,
      popoverVisible: false,
      jbcb: false,
      //---
      bigSrc: null,
      imgSrc: '',
      //拍摄图片
      gpyImgList: [],
    };
  },
  props: {
    initData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    singleImgUrl() {
      if (this.gpyImgList.length > 0) {
        return this.gpyImgList[this.gpyImgList.length - 1].uploadUrl;
      } else {
        return '';
      }
    },
  },
  watch: {
    gpyImgList: {
      deep: true,
      immediate: false,
      handler() {
        if (this.$refs.DragTableRef) {
          this.$refs.DragTableRef.updateTable();
        }
        //this.gpyKey = new Date().getTime();
      },
    },
    initData: {
      deep: true,
      immediate: false,
      handler(val) {
        this.gpyImgList = val;
      },
    },
    //当前弹框是否可见
    visible: {
      immediate: false,
      handler(val) {
        if (val) {
          this.ready = false;
          //执行高拍仪
          this.gpyImgList = [];
          this.$nextTick(() => {
            // this.isTime = true;
            this.initGpy(); //初始化设备
          });
        } else {
        }
      },
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    //关闭弹出框
    close() {
      try {
        this.start('bStopPlay');
        this.visible = false;
      } catch (e) {
        this.visible = false;
        return;
      }
    },
    //关闭弹框前的回调
    handleClose(done) {
      try {
        this.start('bStopPlay');
        done();
      } catch (e) {
        done();
      }
    },
    // 初始化高拍仪
    initGpy() {
      const vm = this;
      vm.imgSrc = '';
      if (!window.webSocket) {
        window.webSocket = new WebSocket('ws://localhost:1818');
        console.log('dakai');
      } else {
        if (window.webSocket.readyState === WebSocket.OPEN) {
          window.webSocket.send('bStopPlay()');
          window.webSocket.send('bStartPlay2()');
          console.log('dakai成功');
        }
        if (window.webSocket.readyState === WebSocket.CLOSED) {
          window.webSocket = new WebSocket('ws://localhost:1818');
        }
      }
      window.onbeforeunload = function () {
        // window.webSocket.send("vSetResolution(5)");
        console.log('onbeforeunload');
        window.webSocket.send('bStopPlay()');
      };

      window.webSocket.onerror = function (event) {
        console.log('onerror');
      };

      window.webSocket.onopen = function (event) {
        window.webSocket.send('bStopPlay()');
        window.webSocket.send('bStartPlay()');
        console.log('onopen');
      };

      window.webSocket.onclose = function (event) {
        window.webSocket.send('bStopPlay()');
        window.webSocket.send('bStopPlay4()');
      };

      window.webSocket.onmessage = function (event) {
        vm.onMessage(event);
      };
    },
    onMessage(event) {
      //读取副摄像头
      if (event.data.indexOf('BeginbStartPlay2') >= 0) {
        console.log('副摄像头开启');
        let result = event.data.replace('BeginbStartPlay2', '').replace('EndbStartPlay2', '');
        //读取主摄像头
        if (result === 'true') {
          this.message = '副摄像头启动成功...';
        } else {
          this.message = '副摄像头启动失败...';
        }
      } else if (event.data.indexOf('BeginbStartPlay') >= 0) {
        console.log('主摄像头开启');
        let result = event.data.replace('BeginbStartPlay', '').replace('EndbStartPlay', '');
        //读取主摄像头
        if (result === 'true') {
          this.message = '主摄像头启动成功...';
        } else {
          this.message = '主摄像头启动失败...';
        }
      } else {
        this.imgSrc = 'data:image/jpeg;base64,' + event.data;
        if (!event.data) {
          this.message = '摄像头没画面了，请关掉页面，拔掉USB重新连接高拍仪再试';
        }
      }
    },
    start(el) {
      console.log('打开摄像头');
      if (window.webSocket && window.webSocket.readyState === WebSocket.OPEN) {
        if (el === 'bStartPlay') {
          console.log('主摄像头开启');
          this.message = '正在启动主摄像头，请稍候...';
          window.webSocket.send('vSetResolution(5)');
          window.webSocket.send('bStopPlay()');
          window.webSocket.send(el);
        }
        if (el === 'bStartPlay2') {
          console.log('副摄像头开启');
          this.message = '正在启动副摄像头，请稍候...';
          window.webSocket.send('vSetResolutionEx(2, 5)');
          window.webSocket.send('bStopPlay()');
          window.webSocket.send(el);
        }
        window.webSocket.send(el);
      }
    },
    // 设置是否是单文件上传
    setSingleFile(val) {
      this.singleFile = val;
    },
    // table上侧按钮操作区
    setFileNamePrefix(name) {
      this.fileNamePrefix = name;
    },
    //-----------------------
    //返回删除后的图片数组
    deleteArrayByIndex(i, data) {
      data.splice(i, 1);
      return data;
    },
    //删除图片
    deleteHandle(index) {
      this.gpyImgList = this.deleteArrayByIndex(index, JSON.parse(JSON.stringify(this.gpyImgList)));
      // 更新序号
      this.updateGpyImgList(this.gpyImgList);
      this.$emit('update', this.gpyImgList);
    },
    // 表格数据发生变化
    changeHandle(list) {
      this.updateGpyImgList(list);
      this.$emit('update', this.gpyImgList);
    },
    //更新高拍仪图片列表
    updateGpyImgList() {
      let temp = [];
      let itemTemp = {};
      JSON.parse(JSON.stringify(this.gpyImgList)).forEach((c, i) => {
        itemTemp = Object.assign(c, {
          sxh: i + 1,
          clmc: c.clfl + (i + 1) + '.jpg',
        });
        temp.push(JSON.parse(JSON.stringify(itemTemp)));
      });
      this.gpyImgList = temp;
    },
    //
    visibleChange(val) {
      this.docSelectVisible = val;
    },
    //拍照
    goPhoto() {
      // this.ready = true;
      let val = this.gpyImgList.length + 1;
      let num = this.getFormatNum(val);
      if (this.imgSrc) {
        this.singleTempUrl = null;
        this.uploadNow({
          id: num,
          url: this.imgSrc,
          name: this.fileNamePrefix + val,
        });
        this.singleTempUrl = this.imgSrc;
      }
    },
    uploadNow(file) {
      let temp = [];
      let f = base64UrlToBlob(file.url);
      f.name = file.name + '.jpg';
      f['pageType'] = file.pre || '';
      temp.push(f);
      this.$emit('upload', temp);
    },
    // 获取图片文件名
    getFormatNum(source) {
      let strTemp = '';
      let souNum = source;
      if (souNum < 10) {
        this.len = 4;
      } else if (souNum < 100) {
        this.len = 3;
      } else if (souNum < 1000) {
        this.len = 2;
      }
      for (let i = 0; i < this.len; i++) {
        souNum += '0';
      }
      return strTemp + souNum;
    },
    deleteAllPhoto() {
      this.gpyImgList = [];
      this.$emit('update', this.gpyImgList);
    },
    setTips(tips) {
      this.tips = tips;
    },
    // table上侧按钮操作区
    tableBtnsHandle(type) {
      console.log(this.gpyImgList, '列表数据');
      if (this.$refs.DragTableRef.getSelected().length <= 0) {
        this.$message.info('请先选择要操作的数据！');
      }
      let setPre = (pre) => {
        this.$refs.DragTableRef.getSelected().forEach((c) => {
          this.gpyImgList.forEach((c1, i1) => {
            if (c1.id === c.id) {
              //选中的
              let preTemp = this.gpyImgList[i1].clmc.substring(
                0,
                this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl)
              );
              let endTemp = this.gpyImgList[i1].clmc.substring(
                this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl),
                this.gpyImgList[i1].clmc.length
              );
              if (preTemp === pre) {
                this.gpyImgList[i1].clmc = endTemp;
                this.gpyImgList[i1].sfbz = '0';
              } else {
                this.gpyImgList[i1].clmc = pre + endTemp;
                this.gpyImgList[i1].sfbz = '1';
              }
            } else {
              //未选中的
              if (this.gpyImgList[i1].sfbz !== '1') {
                this.gpyImgList[i1].sfbz = '0';
              }
            }
          });
        });
      };
      switch (type) {
        case 'qzy':
          setPre('签章页');
          this.$refs.DragTableRef.updateTable();
          break;
        case 'qxy':
          setPre('期限页');
          this.$refs.DragTableRef.updateTable();
          break;
        case 'jey':
          setPre('金额页');
          this.$refs.DragTableRef.updateTable();
          break;
      }
      this.$emit('update', this.gpyImgList);
    },
    // // table上侧按钮操作区
    // tipHandle(tip) {
    //   if (this.$refs.DragTableRef.getSelected().length <= 0) {
    //     this.$message.info('请先选择要操作的数据！');
    //   }
    //   let setPre = (pre) => {
    //     this.$refs.DragTableRef.getSelected().forEach((c) => {
    //       this.gpyImgList.forEach((c1, i1) => {
    //         if (c1.id === c.id) {
    //           let preTemp = this.gpyImgList[i1].clmc.substring(0, this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl));
    //           let endTemp = this.gpyImgList[i1].clmc.substring(
    //             this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl),
    //             this.gpyImgList[i1].clmc.length
    //           );
    //           if (preTemp === pre) {
    //             this.gpyImgList[i1].clmc = endTemp;
    //           } else {
    //             this.gpyImgList[i1].clmc = pre + endTemp;
    //           }
    //         }
    //       });
    //     });
    //   };
    //   setPre(tip);
    //   this.$refs.DragTableRef.updateTable();
    //   this.$emit('update', this.gpyImgList);
    // }
  },
  beforeDestroy() {
    this.webSocket.close();
  },
};
</script>

<style lang="scss" scoped>
/deep/ #Capture {
  height: 300px !important;
}

.gpy-container {
  height: 300px;
  border: 1px solid #ccc;
  img {
    height: 100%;
    width: 100%;
  }
}

.gpy-label {
  display: inline-block;
  width: 80px;
}

.gpy-setting-btn {
  .el-col {
    margin: 10px 0 0 0;
  }
}

.bottom-btn {
  margin-top: 10px;
  .el-button:nth-child(3) {
    margin-right: 5px;
  }
}

.gpy-photo-btns {
  margin-top: 10px;
  .el-button:last-child {
    margin-bottom: 0px;
  }
}

.jbcb {
  display: inline-block;
  width: 120px;
}

.single-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
