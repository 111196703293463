<template>
  <div class="main">
    <!-- 表格标题 -->
    <div class="table-title flex-space-between">
      <h5>{{ commonTableConfig.tableTitle }}</h5>
      <div v-show="!disabled">
        <el-button type="primary" @click="tfQlxx()">统赋</el-button>
        <el-button type="primary" @click="addTableList('add', operator.add, operator.sqrType)">添加</el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <base-table :columns="commonTableConfig.tableColumnConfig" :data="tableData">
      <!-- 操作列 -->
      <template slot="column-end">
        <el-table-column label="操作" fixed="right" width="200px" align="center">
          <template slot-scope="{ row, $index }">
            <el-button
              v-for="button in buttonList"
              v-show="button.show"
              :key="button.type"
              size="small"
              type="text"
              :class="button.type ? `el-button-${button.type}` : ''"
              @click="button.name(button.type, operator['search'], operator[button.type], row, operator.sqrType, $index)"
              >{{ button.label }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </base-table>
    <!-- 权利信息详情弹窗 -->
    <table-qlxx-dialog
      ref="dialog"
      v-if="commonTableConfig.dialogFormConfig"
      @refresh="getData"
      :formConfig="commonTableConfig.dialogFormConfig"
      :disabled="disabled"
    />
    <!-- 统一赋值 -->
    <qlxxTfDialog ref="dialogTf" @refresh="getData"></qlxxTfDialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import tableOperator from '@iebdc/mixin/tableOperator';
import qlxxTfDialog from '@iebdc/views/apply/flow/applicantTable/dialog/qlxxTfDialog';
export default {
  mixins: [tableOperator],
  components: { qlxxTfDialog },
  props: {
    commonTableConfig: {},
    formModel: {},
    disabled: Boolean,
  },
  data() {
    return {
      //当前弹框
      currentStatus: '',
    };
  },
  created() {
    console.log('this.commonTableConfig==', this.commonTableConfig);

    this.setOperator('qlxxOperator');
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData']),
    /**
     * 表格数据
     */
    tableData() {
      return this.formModel['qlxxExMhs'] || [];
    },
  },
  methods: {
    ...mapMutations('wysq-data', ['setFormData']),
    // 重新获取数据
    openDialog(descDialog) {
      console.log('descDialog====', descDialog);
      this.$refs.dialog.open({ ...descDialog });
    },
    getData() {
      this.$emit('getData');
    },
    getDeleteParams(row, index) {
      this.tableData.splice(index, 1);
      const params = {
        qlxxExMhList: this.tableData,
        wwywh: this.flowInfoIng.ywh,
        qlxxId: this.flowInfoIng.id,
      };
      return params;
    },
    tfQlxx() {
      this.$refs.dialogTf.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';
.table-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  h5 {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      height: 3px;
      width: 108%;
      bottom: -20px;
      left: -7%;
      background-color: $color-primary;
    }
  }
}
/deep/ .el-button-delete span {
  color: #ff2c2c !important;
}
</style>
