const utils = {
  //用于生成uuid
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  },
  getUuid() {
    return this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4();
  },

  dateFtt(date, fmt) {
    //author: meizz
    try {
      if (date === null) {
        return '';
      }
      let dateTime;
      if (typeof date === 'string') {
        dateTime = new Date(date);
        if (JSON.stringify(dateTime) === 'null') {
          return date;
        }
      } else if (typeof date === 'number') {
        dateTime = new Date(date);
      } else {
        dateTime = date;
      }

      var o = {
        'M+': dateTime.getMonth() + 1, //月份
        'd+': dateTime.getDate(), //日
        'h+': dateTime.getHours(), //小时
        'm+': dateTime.getMinutes(), //分
        's+': dateTime.getSeconds(), //秒
        'q+': Math.floor((dateTime.getMonth() + 3) / 3), //季度
        S: dateTime.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (dateTime.getFullYear() + '').substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      return fmt;
    } catch (e) {
      return dateTime;
    }
  },

  downloadImg(url, name) {
    let image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
    image.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, image.width, image.height);
      canvas.toBlob((blob) => {
        let url = URL.createObjectURL(blob);
        this.download(url, name);
        // 用完释放URL对象
        URL.revokeObjectURL(url);
      });
    };
  },

  download(href, name) {
    let eleLink = document.createElement('a');
    eleLink.download = name;
    eleLink.href = href;
    eleLink.click();
    eleLink.remove();
  },
  decodeUnicode(str) {
    return str.replace(/\\u[\dA-F]{4}/gi, function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
  },
};

export default utils;

export function paramObject(obj) {
  if (obj === null) {
    return '';
  }
  let query = '';
  let name;
  let value;
  let fullSubName;
  let subName;
  let subValue;
  let innerObj;
  let i;
  let strTempQuery;

  for (name in obj) {
    value = obj[name];

    if (value instanceof Array) {
      for (i = 0; i < value.length; ++i) {
        subValue = value[i];
        if (subValue instanceof Object) {
          fullSubName = name + '[' + i + ']';
        } else {
          fullSubName = name;
        }
        innerObj = {};
        innerObj[fullSubName] = subValue;
        strTempQuery = paramObject(innerObj);
        if (strTempQuery) {
          query += strTempQuery + '&';
        }
      }
    } else if (value instanceof Object) {
      for (subName in value) {
        subValue = value[subName];
        fullSubName = name + '.' + subName;
        //fullSubName = name + '[' + subName + ']';
        innerObj = {};
        innerObj[fullSubName] = subValue;
        strTempQuery = paramObject(innerObj);
        if (strTempQuery) {
          query += strTempQuery + '&';
        }
      }
    } else if (value !== undefined && value !== null) {
      query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
    }
  }

  return query.length ? query.substr(0, query.length - 1) : query;
}

//循环判断是否有权限
const hasPerRouter = (tree, allPermission) => {
  tree.forEach((item, index) => {
    //判断是否有权限
    if (item.meta && item.meta.permission) {
      if (allPermission.indexOf(item.meta.permission) < 0) {
        tree.splice(index, 1);
      } else if (item.children > 0) {
        hasPerRouter(tree.children, allPermission);
      }
    } else if (item.children > 0) {
      hasPerRouter(tree.children, allPermission);
    }
  });
};

export function routerPermission(subRoutes, allPermission) {
  hasPerRouter(subRoutes, allPermission);
}
