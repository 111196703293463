<template>
  <div class="table-filter flex" style="justify-content: space-between">
    <div class="flex-column">
      <div
        class="left-panel flex"
        v-for="(filterRow, index) in filterGroupRow"
        :key="index"
        v-show="index == 0 || EDialogConfig.visible"
      >
        <div class="filter-item" v-for="(filter, index) in filterRow" :key="index">
          <label class="input-label" v-if="filter.label" :class="'input-label-' + index">{{ filter.label }}</label>
          <!-- select -->
          <template v-if="filter.type === 'select'">
            <el-select
              @change="change"
              v-model="filter.value"
              :placeholder="filter.placeholder"
              size="small"
              filterable
            >
              <el-option
                v-for="item in filter.options"
                :key="filter.props ? item[filter.props.value] : item.value"
                :label="filter.props ? item[filter.props.label] : item.label"
                :value="filter.props ? item[filter.props.value] : item.value"
              ></el-option>
            </el-select>
          </template>

          <template v-else-if="filter.type === 'date'">
            <el-date-picker
              v-model="filter.value"
              type="date"
              :format="filter.format || 'yyyy-MM-dd'"
              :value-format="filter.valueformat || 'timestamp'"
              :placeholder="filter.placeholder"
            ></el-date-picker>
          </template>

          <template v-else-if="filter.type === 'dateRange'">
            <el-date-picker
              v-model="filter.options[0].value"
              type="date"
              :format="filter.options[0].format || 'yyyy-MM-dd'"
              :value-format="filter.options[0].valueformat || 'timestamp'"
              :placeholder="filter.options[0].placeholder"
              :picker-options="startTime(filter.options[1].value)"
            ></el-date-picker
            >&nbsp;
            <label class="input-label input-date-end" v-if="filter.centerLabel">{{ filterCenterLabel }}</label>
            &nbsp;
            <el-date-picker
              v-model="filter.options[1].value"
              type="date"
              :format="filter.options[1].format || 'yyyy-MM-dd'"
              :value-format="filter.options[1].valueformat || 'timestamp'"
              :placeholder="filter.options[1].placeholder"
              :picker-options="endTime(filter.options[0].value)"
            ></el-date-picker>
          </template>

          <!--dicSelect-->
          <template v-else-if="filter.type === 'dicSelect'">
            <dict-select
              v-model="filter.value"
              @change="change"
              :type="filter.dicType"
              :placeholder="filter.placeholder"
              filterable
              clearable
            ></dict-select>
          </template>

          <!-- cascader -->
          <template v-else-if="filter.type === 'cascader'">
            <el-cascader
              ref="cascader"
              @change="elCascaderOnlick"
              v-model="filter.value"
              @visible-change="elCascaderOnlick"
              @expand-change="elCascaderOnlick"
              :options="filter.options"
              :placeholder="filter.placeholder"
              :show-all-levels="false"
              :props="{ ...cascaderProps, ...filter.props }"
              size="small"
              filterable
            ></el-cascader>
          </template>

          <!-- input -->
          <template v-if="filter.type === 'input'">
            <el-input v-model="filter.value" :placeholder="filter.placeholder" size="small"></el-input>
          </template>
        </div>
      </div>
    </div>
    <div class="right-panel flex1">
      <el-button type="primary" @click.stop="search()">查询</el-button>
      <el-button v-show="btnsConfig.showResetBtn" type="primary" @click.stop="reset()"
        >{{ btnsConfig.clearBtnText }}
      </el-button>
      <el-button v-show="btnsConfig.showExportBtn" type="primary" @click.stop="exportExcel()">导出</el-button>
      <div v-if="$slots.otherBtn" class="other-btn">
        <slot name="otherBtn"></slot>
      </div>
      <el-button
        type="primary"
        v-show="filterGroupRow.length > 1 && btnsConfig.showMoreBtn"
        @click="EDialogConfig.visible = !EDialogConfig.visible"
        >更多
      </el-button>
    </div>
  </div>
</template>

<script>
/**
 * 表格过滤组件
 *
 * @prop filterGroup 过滤配置组，作为 model，支持三种类型数据（查看下面实例结构）
 *
 * @event change(filterGroup) 数据更新事件
 * @event search() 查询
 * @event reset() 重置
 */
export default {
  props: {
    /**
     * 过滤配置组
     * example: [
     *   // 级联选择器 props 参考 data 中的配置
     *   { type: 'cascader', options: [{label: 'test', value: 1, children: []}], placeholder: '', value: '', props: {} },
     *
     *   // 下拉框
     *   { type: 'select', options: [{label: 'test', value: 1}], placeholder: '', value: '' },
     *
     *   // 下拉字典
     *   { type: 'dicSelect', placeholder: '', dicType: '', value: '' },
     *
     *   // 输入框
     *   { type: 'input', placeholder: '', value: '' },
     *
     *   // 时间段 默认格式 2010-10-01 默认值 时间戳
     *   { type: 'date', options: [{placeholder: '', value: '', format: 'yyyy - MM - dd', valueformat: 'timestamp'}] },
     * ]
     */
    filterGroup: Array,
    EDialogConfig: {
      type: Object,
      default: () => {
        return {
          visible: false, //默认不显示更多
          maxLength: 3,
        };
      },
    },
    btnsConfig: {
      type: Object,
      default: () => {
        return {
          clearBtnText: '重置',
          showResetBtn: false,
          showExportBtn: false,
          showMoreBtn: false,
        };
      },
    },
  },
  model: {
    prop: 'filterGroup',
    event: 'change',
  },
  data() {
    return {
      /* EDialogConfig: {
                  visible: false, //默认不显示更多
                  maxLength: 3
                }, */
      filterGroupRow: [],
      /*      options: [
                  {
                    value: '选项1',
                    label: 'test'
                  }
                ],
                value: '', */
      cascaderProps: {
        children: 'children',
        value: 'value',
        label: 'label',
        emitPath: false,
      },
      endTime: {},
      startTime: {},
    };
  },
  mounted() {
    if (this.EDialogConfig.hasOwnProperty('name')) {
      $('.flex-column').css({ position: 'absolute' });
      $('.right-panel').css({ 'margin-top': '40px', 'margin-left': '72%' });
    } else {
      $('.flex-column').css({ position: 'static' });
      $('.right-panel').css('margin', '0px');
    }
    let result = [];
    let i = 0;
    let j = 0;
    let maxLength = this.EDialogConfig.maxLength;
    result[j] = [];
    this.filterGroup.forEach((item) => {
      if (item.type !== 'dateRange') {
        result[j].push(item);
        i++;
      } else if (result[j].length < maxLength - 1) {
        result[j].push(item);
        i++;
        maxLength = maxLength - 1;
      } else if (result[j].length >= maxLength - 1) {
        i = 0;
        j++;
        result[j].push(item);
      }

      if (i === maxLength) {
        i = 0;
        j++;
        result[j] = [];
        maxLength = this.EDialogConfig.maxLength;
      }
    });
    if (result[result.length - 1].length <= 0) {
      result.splice(result.length - 1, 1);
    }
    this.filterGroupRow = result;
  },
  created() {
    this.startTime = function (value) {
      return {
        disabledDate(time) {
          return time.getTime() > new Date(value).getTime();
        },
      };
    };
    this.endTime = function (value) {
      return {
        disabledDate(time) {
          return time.getTime() < new Date(value).getTime();
        },
      };
    };
  },
  methods: {
    change() {
      // this.handlerValue();
      // console.log("=======");
      // this.$refs.cascader.dropDownVisible = false; //监听值发生变化就关闭它
      this.$emit('change', this.filterGroup);
    },
    elCascaderOnlick() {
      let that = this;
      console.log('23123');
      setTimeout(function () {
        document.querySelectorAll('.el-cascader-node__label').forEach((el) => {
          el.onclick = function () {
            this.previousElementSibling.click();
            that.$refs.cascader.dropDownVisible = false;
          };
        });
        document.querySelectorAll('.el-cascader-panel .el-radio').forEach((el) => {
          el.onclick = function () {
            that.$refs.cascader.dropDownVisible = false;
          };
        });
      }, 100);
    },
    search() {
      let params = {};
      this.filterGroup.forEach((item) => {
        if (item.key) {
          params[item.key] = item.value;
        }
        if (item.type === 'dateRange') {
          item.options.forEach((dateValue) => {
            if (dateValue.key) {
              params[dateValue.key] = dateValue.value;
            }
          });
        }
      });
      this.$emit('search', params);
    },
    reset() {
      this.filterGroup.forEach((item) => {
        item.value = '';
        if (item.type === 'dateRange') {
          item.options.forEach((params) => {
            params.value = '';
          });
        }
      });
      this.$emit('reset');
    },
    exportExcel() {
      this.$emit('exportExcel');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-filter {
  height: 100%;
}

.filter-item {
  margin-right: 8px;
}

.input-label {
  // width: 60px;
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}

/deep/ {
  .el-input,
  .el-select,
  .el-cascader {
    width: 150px !important;
  }
}

.left-panel {
  margin-top: 10px;
}

.right-panel {
  height: 100%;
}

.left-panel:first-child {
  margin-top: 0px;
}

.input-date-end {
  margin-left: 8px;
}

.other-btn {
  display: inline-block;
}
</style>
