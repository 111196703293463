<template>
  <div>
    <div class="left">
      <div class="gpyShow">
        <object
          id="Capture"
          type="application/ZCaptureVideo"
          style="width: 99%;height: 550px;border: 5px gray solid;"
          align="middle"
        >
        </object>
      </div>
      <img :src="img" />
      <div class="gpyBtn">
        <el-row>
          <el-col :span="6">
            <label>文件名</label>
            <el-input v-model="fileName"> </el-input>
          </el-col>
        </el-row>
      </div>
      <div class="gpyBtn">
        <div class="topBtn">
          <el-row>
            <el-col :span="6">
              <label>摄像头</label>
              <el-select v-model="priCammeraType" @change="selectCammeraType" placeholder="请选择摄像头">
                <el-option v-for="item in CammeraTypeGroup" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <label>设备分辨率</label>
              <el-select v-model="priResolution" @change="selectResolution" placeholder="请选择设备分辨率">
                <el-option v-for="(item, index) in resolutionGroup" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
        <div class="bottomBtn">
          <el-button @click="SetDeviceRotation(90)" icon="undo">图片左转</el-button>
          <el-button @click="SetDeviceRotation(-90)" icon="redo">图片右转</el-button>
          <el-button @click="SetDeviceRotation(0)" icon="redo">还原</el-button>
          <el-button @click="CaptureToFile()" icon="redo">拍照</el-button>
        </div>
      </div>
    </div>
    <div class="errorMessage">
      <p>{{ errorMessage }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //设备
      Capture: {},
      //摄像头类型组
      deviceGroup: [
        {
          value: 0,
          label: '文档设备'
        },
        {
          value: 1,
          label: '人像设备'
        },
        {
          value: 2,
          label: '附加设备'
        }
      ],
      CammeraTypeGroup: [],
      //选中摄像头
      priCammeraType: '-1',
      //设备分辨率组
      resolutionGroup: [],
      //选中设备分辨率
      priResolution: '-1',
      //默认旋转角度
      rotation: '0',
      //错误信息
      errorMessage: '',
      //默认图片名称序号
      imgeId: 0,
      img: '',
      //文件名
      fileName: '测试图片'
    };
  },
  mounted() {
    this.initCapture();
  },
  methods: {
    //初始化设备
    initCapture() {
      //获取设备
      this.Capture = document.getElementById('Capture');
      //初始化设备
      if (this.Capture.InitDevice() === 0) {
        //获取设备摄像头
        this.getCammeraType();
        //选中第一个设备
        this.selectCammeraType(this.CammeraTypeGroup[0].value);
      } else {
        this.errorMessage = '未找到ICaptureVideo.ocx控件，请重新安装';
      }
    },
    //打开设备 item 打开的设备
    startCapture() {
      //关闭除选中外的所有摄像头
      this.CammeraTypeGroup.forEach((num) => {
        if (num.value !== this.priCammeraType) {
          const data = this.Capture.StopDevice(num.value);
        }
      });
      //打开选中摄像头
      if (this.Capture.StartDevice(this.priCammeraType) === 0) {
        this.errorMessage = `打开${this.priCammeraType}成功`;
        //根据摄像头调整摄像头的角度
        if (this.priCammeraType === '0') {
          this.SetDeviceRotation(90);
        } else {
          this.SetDeviceRotation(0);
        }
      } else {
        this.errorMessage = `打开${this.priCammeraType}失败`;
      }
    },
    //关闭设备释放资源 退出页面时调用
    releaseDevice() {
      this.Capture.ReleaseDevice();
    },
    //获取摄像头
    getCammeraType() {
      const DeviceCount = this.Capture.GetDeviceCount();
      switch (DeviceCount) {
        case 1: //有且只有文档设备
          this.CammeraTypeGroup = [this.deviceGroup[0]];
          break;
        case 2: //有文档设备和人像设备
          this.CammeraTypeGroup = [this.deviceGroup[0], this.deviceGroup[1]];
          break;
        case 3: //附加设备
          this.CammeraTypeGroup = [this.deviceGroup[0], this.deviceGroup[1], this.deviceGroup[2]];
          break;
        default:
          this.errorMessage = '未找到设备摄像头';
          break;
      }
    },
    //获取选择的摄像头的分辨率
    getResolution() {
      //清空选中分辨率
      this.priResolution = '';
      //清空摄像头组
      this.resolutionGroup = [];
      //获取选中设备的分辨率数组
      const strResolutions = this.Capture.GetResolution(this.priCammeraType);
      JSON.parse(strResolutions).Resolution.forEach((resolution) => {
        this.resolutionGroup.push({
          label: resolution,
          value: resolution
        });
      });
    },
    //选择摄像头 item选中的设备 item.label 名称 item.value对应 0 1 2
    selectCammeraType(item) {
      this.priCammeraType = item;
      //获取分辨率列表
      this.getResolution();
      this.selectResolution(this.resolutionGroup[0].value);
      //打开摄像头
      this.startCapture();
    },
    //选择分辨率 value选中的摄像头的值 第几个分辨路
    selectResolution(resolution) {
      this.priResolution = resolution;
      //设置默认分辨率
      const width = resolution.split('*')[0];
      const height = resolution.split('*')[1];
      const select = this.Capture.SetResolution(this.priCammeraType, width, height);
      if (select === 0) {
        this.errorMessage = `选中${resolution}成功`;
      } else {
        this.errorMessage = `选中${resolution}失败`;
      }
    },
    //旋转图片
    SetDeviceRotation(value) {
      if (value === 0) {
        this.rotation = '0';
      }
      this.rotation = (parseInt(this.rotation) + value) % 360;
      this.Capture.SetDeviceAngle(this.priCammeraType, this.rotation.toString());
    },
    //拍照
    CaptureToFile() {
      const strFilePath = 'D:\\DocImage\\';
      const strFileName = strFilePath + this.fileName + this.imgeId.toString() + '.jpg';
      const img = this.Capture.CaptureImage(this.priCammeraType, strFileName);
      if (img === 0) {
        const base64 = this.Capture.EncodeBase64(strFileName);
        this.img = 'data:image/gif;base64,' + base64;
        this.errorMessage = this.img;
      }
      this.imgeId++;
    }
  }
};
</script>
