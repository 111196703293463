import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 获取设备列表
export const getDeviceList = (params) =>
  request({
    url: REST_SERVICE.device.getDeviceList,
    method: 'get',
    params
  });
// 添加设备
export const deviceRegister = (params) =>
  request({
    url: REST_SERVICE.device.deviceRegister,
    method: 'post',
    data: params
  });

// 根据设备id获取设备使用次数 getUsageByDeviceId
export const getUsageByDeviceId = (params) => {
  return request({
    url: REST_SERVICE.device.getUsageByDeviceId,
    method: 'get',
    params
  });
};

// 根据id删除当前设备 deleteDeviceById
export const deleteDeviceById = (params) =>
  request({
    url: REST_SERVICE.device.deleteDeviceById,
    method: 'post',
    data: params
  });

// 更新设备信息 updateSenseDevice
export const updateSenseDevice = (data) =>
  request({
    url: REST_SERVICE.device.updateSenseDevice,
    method: 'post',
    data: data
  });

//修改用户中心的高拍仪
export const saveCompany = (data) =>
  request({
    url: REST_SERVICE.device.saveCompany,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: data
  });


/**
 * @method getIdcardByqszm 根据设备编号获取3分钟内最新的一条记录，且获取后即失效
 * @param {string} deviceBh 设备编号
 * @param {object} sqrData 申请人信息
 */
export const getInfoByDeviceBh = (params) => {
  return request({
    url: REST_SERVICE.device.getInfoByDeviceBh,
    method: 'get',
    params
  });
};
