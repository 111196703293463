const utils = {
  //用于生成uuid
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  },
  getUuid() {
    return this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4() + this.S4();
  },

  /*  获取当前时间
   *   0默认代表获取年月日
   *   1代表获取年月日时分秒
   */
  getNowDate(dataType = 0) {
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth() + 1;
    let dd = new Date().getDate();
    let hh = new Date().getHours();
    let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
    let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
    if (dataType === 1) {
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    }
    return yy + '-' + mm + '-' + dd;
  },

  dateFtt(date, fmt) {
    //author: meizz
    try {
      if (date == null) {
        return '';
      }
      let dateTime;
      if (typeof date === 'string') {
        dateTime = new Date(date);
        if (JSON.stringify(dateTime) === 'null') {
          return date;
        }
      } else if (typeof date === 'number') {
        dateTime = new Date(date);
      } else {
        dateTime = date;
      }

      var o = {
        'M+': dateTime.getMonth() + 1, //月份
        'd+': dateTime.getDate(), //日
        'h+': dateTime.getHours(), //小时
        'm+': dateTime.getMinutes(), //分
        's+': dateTime.getSeconds(), //秒
        'q+': Math.floor((dateTime.getMonth() + 3) / 3), //季度
        S: dateTime.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (dateTime.getFullYear() + '').substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      return fmt;
    } catch (e) {
      return dateTime;
    }
  },

  downloadImg(url, name) {
    console.log(url, name);
    let image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
    image.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, image.width, image.height);
      canvas.toBlob((blob) => {
        let url = URL.createObjectURL(blob);
        this.download(url, name);
        // 用完释放URL对象
        URL.revokeObjectURL(url);
      });
    };
  },

  download(href, name) {
    let eleLink = document.createElement('a');
    eleLink.download = name;
    eleLink.href = href;
    eleLink.click();
    eleLink.remove();
  },

  dateFormat(time) {
    var date = new Date(time);
    var year = date.getFullYear();
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    // 拼接
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  },
  // 判断当前是什么浏览器用于光阵高拍仪
  myBrowser() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf('Opera') > -1;
    if (isOpera) {
      return 'Opera';
    } //判断是否Opera浏览器
    else if (userAgent.indexOf('Firefox') > -1) {
      return 'FF';
    } //判断是否Firefox浏览器
    else if (userAgent.indexOf('Edge') > -1) {
      return 'Edge';
    } //判断是否Safari浏览器
    else if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } else {
      return 'IE';
    } //判断是否IE浏览器
  },
  /**
   * 删除对象中空key
   * @param obj
   * @returns {any}
   */
  deleteEmptyKey(obj) {
    let temp = JSON.parse(JSON.stringify(obj));
    Object.keys(temp).forEach((k) => {
      if (temp[k] === '' || temp[k] === null || typeof temp[k] === 'undefined') {
        delete temp[k];
      }
    });
    return temp;
  },
  selectTreeNode(tree, node) {
    for (var i in tree) {
      if (tree.code === 'node') {
        console.log(tree.code);
        return tree;
      }
      if (tree[i].children) {
        this.selectTreeNede(tree[i].children, node);
      } else {
        return;
      }
    }
  },
  plusXing(str) {
    let frontLen, endLen;
    if (str.length > 2) {
      frontLen = 1;
      endLen = 1;
    } else {
      frontLen = 1;
      endLen = 0;
    }
    var len = str.length - frontLen - endLen;
    var xing = '';
    for (var i = 0; i < len; i++) {
      xing += '*';
    }
    return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
  },
};

export default utils;
