<template>
  <el-dialog
    :title="configComputed.title"
    :visible.sync="configComputed.visible"
    :width="configComputed.width"
    :close-on-click-modal="false"
    :before-close="beforeClose"
    :append-to-body="configComputed.appendBody"
    :lock-scroll="false"
    ref="eldialog"
    :destroy-on-clos="true"
  >
    <slot name="content"></slot>
    <span v-if="configComputed.hasBtn" slot="footer">
      <span v-if="btns && btns.length > 0">
        <template v-for="btn in btns">
          <el-button
            :loading="btn.btnloading"
            :key="btn.id"
            :type="btn.type"
            :class="btn.class"
            v-if="btn.show"
            @click.stop.native.prevent="btn.method"
            >{{ btn.label }}</el-button
          >
        </template>
      </span>
      <span v-else>
        <el-button class="public-el-button" type="primary" @click="cancelClick">确 定</el-button>
        <el-button class="public-el-button-default" type="primary" @click="confirmClick">取 消</el-button>
      </span>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      // 所有可配置项，不传则默认
      configTemp: {
        title: '',
        width: '900px',
        visible: false,
        hasConfirm: true,
        hasCancel: true,
        hasBtn: true,
      },
    };
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
    btns: {
      type: Array,
      default: () => {
        return [
          {
            label: '',
            type: '',
            method: null,
            show: true,
            btnloading: false,
          },
        ];
      },
    },
  },
  computed: {
    configComputed() {
      return Object.assign(this.configTemp, this.config);
    },
  },
  methods: {
    close() {
      this.config.visible = false;
    },
    beforeClose() {
      this.config.visible = false;
      this.$emit('beforeClose');
    },
    confirmClick() {
      this.$emit('afterConfirm');
    },
    cancelClick() {
      this.$emit('afterCancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/common-variables';
/deep/ .el-dialog {
  .el-dialog__header {
    padding-top: $spacing-base !important;
    padding-bottom: $spacing-base !important;
    //height: 44px !important;
    background-color: #f3f3f3;
    .el-dialog__title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    button {
      top: 12px;
      .el-icon-close {
        color: rgba(24, 144, 255, 1);
        font-size: 22px;
      }
    }
  }
  .el-dialog__body {
    padding: 35px 50px;
    max-height: 360px;
    overflow-y: auto;
    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
    }
  }
  .el-dialog__footer {
    padding-bottom: 38px;
    text-align: center;
  }
  /deep/.public-el-button-default,
  .public-el-button {
    border-radius: 5px;
  }
  /deep/ .el-date-editor.el-input,
  .el-select,
  .el-cascader {
    width: 100%;
  }
  /deep/ .el-form-item__label {
    padding: 0 12px;
  }
  .line {
    text-align: center;
  }
}
</style>
