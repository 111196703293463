const { provinces_jc } = window.CONFIG;
export const bdcMban = [
  {
    mbanName: '不动产登记证明号', // 原来是抵押证明号
    mbanType: '抵押证明模板',
    mbanCode: '2',
    code: ['N200112', 'N200301', 'N300301', 'N400301', 'N360099'],
    mbanConfig: [
      {
        name: '***房预***字第***号',
        rules: 'estateCertificationNo',
      },
      {
        name: '***房他证***字第***号',
        rules: 'estateCertificationNo',
      },
      {
        name: `${provinces_jc}（***）***不动产证明第***号`,
        rules: 'estateCertificationNo',
      },
      {
        name: '其它',
        rules: '',
      },
    ],
  },
  {
    mbanName: '预告抵押证明号',
    mbanType: '预告抵押证明模板',
    mbanCode: '2',
    code: ['N300126'], //预告抵押注销
    mbanConfig: [
      {
        name: '***房预***字第号',
        rules: 'estateCertificationNo',
      },
      {
        name: '***房他证***字第***号',
        rules: 'estateCertificationNo',
      },
      {
        name: `${provinces_jc}（***）***不动产证明第***号`,
        rules: 'estateCertificationNo',
      },
      {
        name: '其它',
        rules: '',
      },
    ],
  },
  {
    mbanName: '预告证明号',
    mbanType: '预告证明模板',
    mbanCode: '3',
    code: ['N360000', 'N100203'],
    mbanConfig: [
      {
        name: '***房预***字第号',
        rules: 'estateCertificationNo',
      },
      {
        name: '***房他证***字第***号',
        rules: 'estateCertificationNo',
      },
      {
        name: `${provinces_jc}（***）***不动产证明第***号`,
        rules: 'estateCertificationNo',
      },
      {
        name: `${provinces_jc}（***）***不动产权证明第***号`,
        rules: 'estateCertificationNo',
      },
      {
        name: '其它',
        rules: '',
      },
    ],
  },
  {
    mbanName: '不动产权证书号',
    mbanType: '证书模板',
    mbanCode: '1',
    code: [
      'N200111',
      'N200112',
      'N100303',
      'H999002',
      'H100311',
      'H999003', //变更加转移
      'N300099',
      'N200104',
      'N500104',
      'N300104',
      'N400104',
      'N100301',
      'N900102',
      'N900101',
      'N200106', // 宅基地使用权及房屋所有权转移登记
      'N300106', // 宅基地使用权及房屋所有权变更登记
      'N500106', // 宅基地使用权及房屋所有权更正登记
      'N400106', // 宅基地使用权及房屋所有权注销登记
      'N600106', // 宅基地使用权及房屋所有权补正登记
      'N700106', // 宅基地使用权及房屋所有权换证登记
      'N200105', // 宅基地所有权转移登记
      'N300105', // 宅基地所有权变更登记
      'N500105', // 宅基地所有权更正登记
      'N400105', // 宅基地所有权注销登记
      'N600105', // 宅基地所有权补正登记
      'N700105', // 宅基地所有权换证登记
      'F400200', // 土地承包经营权
      'F500200', // 土地经营权
      'N510001', // 林地所有权首次登记
      'N510002', // 林地所有权转移登记
      'N510003', // 林地所有权更正登记
      'N510004', // 林地所有权变更登记
      'N510005', // 林地所有权注销登记
      'H999006', // 房屋所有权转移登记+抵押权变更登记（同行带押过户）
      'N610001', // 林地使用权/森林、林木使用权
      'N710001', // 林地承包经营权及林木所有权首次登记
      'N710002', // 林地承包经营权及林木所有权转移登记
      'N710003', // 林地承包经营权及林木所有权更正登记
      'N710004', // 林地承包经营权及林木所有权变更登记
      'N710005', // 林地承包经营权及林木所有权注销登记
      'N810001', // 林地经营权及林木所有权首次登记
      'N810002', // 林地经营权及林木所有权转移登记
      'N810003', // 林地经营权及林木所有权更正登记
      'N810004', // 林地经营权及林木所有权变更登记
      'N810005', // 林地经营权及林木所有权补证换证登记
      'N810006', // 林地经营权及林木所有权注销登记
      'N910001', // 林地使用权/林木所有权首次登记
      'N910002', // 林地使用权/林木所有权转移登记
      'N910003', // 林地使用权/林木所有权更正登记
      'N910004', // 林地使用权/林木所有权变更登记
      'N910005', // 林地使用权/林木所有权注销登记
    ],
    mbanConfig: [
      {
        name: `${provinces_jc}（***）***不动产权第***号`,
        rules: 'estateCertificationNo',
      },
      {
        name: '***国用（***）第***号',
        rules: 'estateCertificationNo',
      },
      {
        name: '***房权证***字第***号',
        rules: 'estateCertificationNo',
      },
      {
        name: '其它',
        rules: '',
      },
    ],
  },
  //不存在核验模板
  // {
  //   mbanName: '合同号',
  //   mbanType: '合同号',
  //   mbanCode: '0',
  //   code: ['N100201', 'N100211'],
  //   mbanConfig: [
  //     {
  //       name: '其它',
  //       rules: '',
  //     },
  //   ],
  // },
  // 不动产单元号
  {
    mbanCode: '4',
    code: ['H999001', 'N100106', 'N100201'],
  },
];
