/**
 * 交换元素
 * @param arr
 * @param index1
 * @param index2
 * @returns {*}
 */
function swapArray(arr, index1, index2) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
  return arr;
}

/**
 * 下移
 * @param arr
 * @param index
 */
export function down(arr, index) {
  if (index < 0) {
    // 索引值 为负值 不处理交换
    return false;
  } else if (index + 1 >= arr.length) {
    // 索引值+1 大于数组长度 导致数组越界 不处理交换
    return false;
  } else {
    swapArray(arr, index, index + 1);
    return true;
  }
}

/**
 * 上移
 * @param arr
 * @param index
 */
export function up(arr, index) {
  if (index === 0) {
    // 索引值 已经为0 左边没有值了 不处理交换
    return false;
  } else if (index >= arr.length) {
    // 索引值大于数组长度 导致数组越界 不处理交换
    return false;
  } else {
    swapArray(arr, index, index - 1);
    return true;
  }
}
