<template>
  <div class="main">
    <!-- 表格标题 -->
    <div class="table-title flex-space-between">
      <!-- <h5>
        {{ commonTableConfig.tableTitle }}
        <span class="color-red" v-show="zwrCategory.indexOf(commonTableConfig.category) > -1"
          >（若债务人与抵押人相同同步抵押人信息即可，若不相同请根据实际情况添加）</span
        >
      </h5> -->
      <div v-show="!(disabled || sameDisable)">
        <el-dropdown
          @command="addFamiltGroup"
          v-if="commonTableConfig.familyTableConfig && commonTableConfig.familyColumnConfig && tableData.length > 0"
        >
          <el-button class="add-button" type="primary">
            添加家庭组<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="item" v-for="(item, index) in tableData" :key="index">{{
              item.qlrmc || item.ywrmc
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          type="primary"
          v-if="tableData && tableData.length > 0 ? tableData[0].gyfs != 0 : true"
          @click="addTableList('add', operator.add, operator.sqrType)"
          >添加</el-button
        >
        <el-button
          type="primary"
          v-if="zwrCategory.indexOf(commonTableConfig.category) > -1"
          @click="addTableListToZwr()"
          >同抵押人</el-button
        >
      </div>
    </div>
    <!-- 表格区域 -->
    <base-table :columns="commonTableConfig.tableColumnConfig" :data="tableData">
      <!-- 操作列 -->
      <template slot="column-end">
        <el-table-column label="操作" fixed="right" width="200px" align="center">
          <template slot-scope="{ row, $index }">
            <el-button
              v-for="button in buttonList"
              v-show="button.show"
              :key="button.type"
              size="small"
              type="text"
              :class="button.type ? `el-button-${button.type}` : ''"
              @click="
                button.name(button.type, operator['search'], operator[button.type], row, operator.sqrType, $index)
              "
              >{{ button.label }}
            </el-button>
          </template>
        </el-table-column>
      </template>
    </base-table>
    <family-group
      ref="familyGroup"
      v-if="
        tableData &&
        tableData.length > 0 &&
        this.commonTableConfig.familyTableConfig &&
        this.commonTableConfig.familyColumnConfig
      "
      :familyTableConfig="commonTableConfig.familyTableConfig"
      :familyColumnConfig="commonTableConfig.familyColumnConfig"
      :tableData="tableData"
      :disabled="disabled || sameDisable"
      @refresh="getData"
    ></family-group>

    <!-- 表单区域 -->
    <dynamic-form-old
      ref="form"
      :keyName="'tzrxxes'"
      @setData="setTzrData"
      v-if="commonTableConfig.category"
      :form-config="formConfig"
      :formModel="tzrData"
      :disabled="disabled"
    />
    <!-- 弹窗 -->
    <table-sqr-dialog
      ref="dialog"
      v-if="commonTableConfig.dialogFormConfig"
      @refresh="getData"
      :formConfig="dialogConfig.length > 0 ? dialogConfig : commonTableConfig.dialogFormConfig"
      :disabled="disabled || sameDisable"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import rules from '@/utils/validate.js';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import tableOperator from '@iebdc/mixin/tableOperator';
import { sameSqr } from '@public/applyConfig/sameSqr';
export default {
  mixins: [tableOperator],
  props: {
    commonTableConfig: {},
    formModel: {},
    disabled: Boolean,
    sameDisable: Boolean,
  },
  data() {
    return {
      qlrCategory: ['4', '0', '2', '7', '9', '12'],
      ywrCategory: ['5', '6', '1', '3', '8', '10', '11'],
      zwrCategory: ['6', '11'],
      // 表单配置
      formConfig: [
        {
          value: [
            {
              title: '联系人',
              key: 'tzrmc',
              type: 'input',
              props: {
                placeholder: '请输入联系人',
                class: 'w80',
              },
              rule: [rules.required],
            },
            {
              title: '联系电话',
              key: 'tzdh',
              type: 'input',
              props: {
                placeholder: '请输入联系电话',
                class: 'w80',
              },
              rule: [rules.required, rules.phone],
            },
          ],
        },
      ],
      //相同申请人配置
      sameConfig: '',
      //当前弹框
      currentStatus: '',
      //是否显示家庭组
      showFamily: false,
      dialogConfig: [],
    };
  },
  created() {
    if (this.commonTableConfig.category) {
      this.buttonList.push({
        label: '设为联系人',
        type: 'setTzr',
        name: this.setTzrList,
        show: !this.disabled,
      });
    }
    if (this.flowInfoIng.netFlowdefCode === 'H100311') {
      this.buttonList[1].show = true;
    }

    //初始化按钮
    if (this.qlrCategory.indexOf(this.commonTableConfig.category) > -1) {
      this.setOperator('qlrOperator');
    } else if (this.ywrCategory.indexOf(this.commonTableConfig.category) > -1) {
      this.setOperator('ywrOperator');
    }
    //初始化相同申请人
    sameSqr.forEach((data) => {
      if (data.code === this.flowInfoIng.code) {
        this.sameConfig = data.same;
      }
    });
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData']),
    /**
     * 表格数据
     */
    tableData() {
      let tableData = [];
      const sqrxx = this.formModel['qlrs'].concat(this.formModel['ywrs']);
      sqrxx.forEach((item) => {
        if (item.category === this.commonTableConfig.category) {
          tableData.push(item);
        }
      });
      return tableData;
    },
    /**
     * 通知人对象
     */
    tzrData() {
      let tzrxxesList = {
        tzrmc: '',
        tzdh: '',
        category: this.commonTableConfig.category,
        tzrfl: this.commonTableConfig.category,
      };
      (this.formModel['tzrxxes'] || []).forEach((item) => {
        if (item.category === this.commonTableConfig.category) {
          tzrxxesList = Object.assign({}, item);
        }
      });
      return tzrxxesList;
    },
  },
  methods: {
    ...mapMutations('wysq-data', ['setFormData']),
    tableInitData(type) {
      this.dialogConfig = $.extend([], this.commonTableConfig.dialogFormConfig);
      this.dialogConfig.map((item) => {
        item.value.map((childrenItem) => {
          if (childrenItem.key === 'gyfs') {
            if (type) {
              childrenItem.props.disabled = true;
            } else {
              childrenItem.props.disabled = false;
            }
          }
        });
      });
    },
    openDialog(descDialog) {
      if (
        (this.tableData.length > 1 && this.tableData[0].gyfs != 0) ||
        (this.tableData.length > 0 && !descDialog.hasOwnProperty('index'))
      ) {
        this.tableInitData(true);
        descDialog.rowData.gyfs = this.tableData[0].gyfs;
      } else {
        this.tableInitData(false);
      }
      this.$refs.dialog.open({ ...descDialog });
    },
    //删除表格参数
    getDeleteParams(rowData) {
      const params = { id: rowData.id };
      return params;
    },
    //删除申请人信息后
    deleteBefore(rowData) {
      //清除通知人
      if (this.tzrData.tzrmc === (rowData.qlrmc || rowData.ywrmc) && this.tzrData.tzdh === rowData.dh) {
        this.tzrData.tzrmc = '';
        this.tzrData.tzdh = '';
        this.$refs.form.setData(this.tzrData);
      }
    },
    //设置为通知人
    setTzrList(type, searchService, buttonService, rowData) {
      this.tzrData.tzrmc = rowData.dlrmc || rowData.qlrmc || rowData.ywrmc;
      this.tzrData.tzdh = rowData.dlrdh || rowData.dh;
      this.$refs.form.setData(this.tzrData);
    },
    //保存值
    setTzrData(data) {
      if (this.processData && this.processData.length > 0) {
        let dataArray = JSON.parse(JSON.stringify(this.processData));
        if (dataArray[this.flowInfoIng.index][data.key] && dataArray[this.flowInfoIng.index][data.key].length > 0) {
          try {
            dataArray[this.flowInfoIng.index][data.key].forEach((item, index) => {
              if (item.category === this.commonTableConfig.category) {
                dataArray[this.flowInfoIng.index][data.key][index] = data.value;
                throw Error();
              }
              if (index === dataArray[this.flowInfoIng.index][data.key].length - 1) {
                dataArray[this.flowInfoIng.index][data.key].push(data.value);
              }
            });
          } catch (e) {}
        } else {
          dataArray[this.flowInfoIng.index][data.key] = [];
          dataArray[this.flowInfoIng.index][data.key].push(data.value);
        }
        this.setFormData(dataArray);
      }
    },
    //添加家庭组
    addFamiltGroup(row) {
      this.$refs.familyGroup.setFamilyData(row);
    },
    //抵押人同步为债务人
    //tableData 当前抵押人表格
    addTableListToZwr() {
      let zwrTable = [];
      const sqrxx = this.formModel['qlrs'].concat(this.formModel['ywrs']);
      sqrxx.forEach((item) => {
        if (Number(item.category) === Number(this.commonTableConfig.category) - 1) {
          zwrTable.push(item);
        }
      });
      if (zwrTable.length <= 0) {
        this.$message.warning('请先添加至少一条抵押人信息');
        return false;
      }
      applicantOperatorService
        .copyDyrToZwr({
          ywh: this.flowInfoIng.ywh,
          ywrList: zwrTable,
          zwrCategory: this.commonTableConfig.category,
        })
        .then((res) => {
          if (res.success) {
            // 提交成功
            this.getData();
            this.$message.success('同步成功');
          } else {
            this.$message.success('同步失败');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';
.table-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  .add-button {
    margin: 0px 10px;
  }
  .color-red {
    color: red;
    font-size: 14px;
  }
  h5 {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      height: 3px;
      width: 108%;
      bottom: -20px;
      left: -7%;
      background-color: $color-primary;
    }
  }
}
/deep/ .el-button-setTzr span {
  color: #7ed321 !important;
}
/deep/ .el-button-delete span {
  color: #ff2c2c !important;
}
</style>
