import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
export default {
  data() {
    return {
      // 表格行内操作
      buttonList: [
        {
          label: '查看',
          type: 'search',
          name: this.searchTableList,
          show: this.disabled || this.sameDisable,
        },
        {
          label: '修改',
          type: 'update',
          name: this.updateTableList,
          show: !(this.disabled || this.sameDisable),
        },
        {
          label: '删除',
          type: 'delete',
          name: this.deleteTableList,
          show: !(this.disabled || this.sameDisable),
        },
      ],
      //当前按钮
      operator: {},
      //对应的表单index
      sameKey: '',
    };
  },
  methods: {
    //初始化当前按钮对应的服务
    setOperator(service) {
      this.operator = applicantOperatorService[service]();
    },
    // 重新获取数据
    getData() {
      this.$emit('getData');
    },
    //判断是否操作有相同的申请人,并取出另一个的category
    hasSameSqr(category) {
      if (!this.sameConfig) return;
      let has = false;
      //判断是否有相同人
      for (let key in this.sameConfig) {
        if ('form' + this.flowInfoIng.index === key && this.sameConfig[key] === category) {
          has = true;
        }
      }
      //取出对应的category 和 列表
      if (has) {
        for (let key in this.sameConfig) {
          if ('form' + this.flowInfoIng.index !== key) {
            this.sameKey = key.substr(4, 5);
            return this.sameConfig[key];
          }
        }
      } else {
        return false;
      }
    },
    //如果有相同的申请人，取到对应的操作服务
    getSameSqrService(sameCategory, opType) {
      let service = '';
      //获取对应的操作按钮
      if (this.qlrCategory.indexOf(sameCategory) > -1) {
        service = applicantOperatorService['qlrOperator']();
      } else if (this.ywrCategory.indexOf(sameCategory) > -1) {
        service = applicantOperatorService['ywrOperator']();
      }
      return service[opType];
    },
    //获取相同服务
    getSameService(category, type) {
      const sameCategory = this.hasSameSqr(category);
      // console.log(sameCategory,'sameCategory')
      let sameService = '';
      if (sameCategory) {
        sameService = this.getSameSqrService(sameCategory, type);
      }
      return { sameCategory: sameCategory, sameService: sameService };
    },
    //添加表格数据 type 操作类型 add
    // same  sameCategory对应的category sameService对应的服务
    addTableList(type, buttonService, sqrType) {
      const same = this.getSameService(this.commonTableConfig.category, type);
      const descDialog = {
        sqrType: sqrType,
        buttonService: buttonService, // 操作服务
        sameService: same.sameService, //同一人的操作服务
        sameYwh: this.sameKey && this.processData[this.sameKey].ywh,
        qlxxList: this.commonTableConfig.category ? [] : this.tableData,
        rowData: {}, //当前表格行数据
        category: this.commonTableConfig.category, // 当前表格人分类
        sameCategory: same.sameCategory, //同一人的category
      };
      // 打开弹窗
      this.openDialog(descDialog);
    },
    //删除表格数据 type 操作类型 detele
    // same  sameCategory对应的category sameService对应的服务
    // sameSqr id 对应申请数据的id data对应数据 ywh对应数据所处子流程的业务号
    deleteTableList(type, searchService, buttonService, rowData, sqrType, index) {
      const same = this.getSameService(this.commonTableConfig.category, type);
      // 删除操作
      this.$confirm('是否删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          let sameSqr = '';
          const params = this.getDeleteParams(rowData, index);
          let promiseAll = [this.deleteService(buttonService, params, rowData)];
          //判断是否有相同数据需要删除
          if (same.sameService) {
            //查找出对应的相同操作数据
            sameSqr = applicantOperatorService.getSameSqrData(
              rowData,
              this.processData[this.sameKey],
              same.sameCategory
            );
          }
          if (sameSqr.id) {
            const sameParams = { id: sameSqr.id };
            promiseAll.push(this.deleteService(same.sameService, sameParams, sameSqr.data));
          }
          //删除开始
          Promise.all(promiseAll)
            .then(() => {
              //删除成功
              this.success('删除成功');
            })
            .catch((message) => {
              this.$message.error(message);
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //删除操作
    deleteService(buttonService, params, rowData) {
      return new Promise((resolve, reject) => {
        applicantOperatorService[buttonService](params).then((res) => {
          if (res.success) {
            // 提交成功
            if (this.deleteBefore) {
              this.deleteBefore(rowData);
            }
            resolve();
          } else {
            reject(res.message);
          }
        });
      });
    },
    //修改表格数据 type 操作类型 update
    updateTableList(type, searchService, buttonService, rowData, sqrType, index) {
      console.log('updateTableList', type, searchService, buttonService, rowData, sqrType, index);
      const same = this.getSameService(this.commonTableConfig.category, type);
      let sameSqr = '';
      //判断是否有相同数据需要修改
      if (same.sameService) {
        //查找出对应的相同操作数据
        sameSqr = applicantOperatorService.getSameSqrData(rowData, this.processData[this.sameKey], same.sameCategory);
      }
      //获取当前用户信息
      const descDialog = {
        searchService,
        buttonService, // 操作类型
        sameService: same.sameService, //同一人的操作服务
        sqrType: sqrType,
        qlxxList: this.commonTableConfig.category ? [] : this.tableData,
        rowData: Object.assign({}, rowData), // 当前表格行数据
        sameData: Object.assign({}, sameSqr.data), // 对应相同操作人的数据
        sameYwh: sameSqr.ywh,
        index: index,
        category: this.commonTableConfig.category, // 当前表格人分类
        sameCategory: same.sameCategory, //同一人的category
        tableData: this.tableData, // 当前表格数据
      };
      console.log('1111descDialog', descDialog);
      // 打开弹窗
      this.openDialog(descDialog);
    },
    //查看表格数据
    searchTableList(type, searchService, buttonService, rowData) {
      const descDialog = {
        searchService,
        qlxxList: this.commonTableConfig.category ? [] : this.tableData,
        rowData: Object.assign({}, rowData), // 当前表格行数据
        category: this.commonTableConfig.category, // 当前表格人分类
        tableData: this.tableData, // 当前表格数据
      };
      // 打开弹窗
      this.openDialog(descDialog);
    },
    // 设置数据
    setData(data) {
      const dataKey = this.currentStatus.dataKey;
      // 设置表格数据
      if (this.commonTableConfig.category) {
        this.tableData = (data[dataKey] || []).filter((data) => data.category === this.commonTableConfig.category);
        this.tzrxxes = this.formModel.tzrxxes.filter((data) => data.category === this.commonTableConfig.category);
      } else {
        // 设置权利信息数据
        this.tableData = data[dataKey] || [];
      }
    },
    // 成功回调
    success(msg) {
      this.$emit('getData');
      this.$message.success(msg);
    },
  },
};
