import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantOperatorService = {
  qlrOperator() {
    return {
      sqrType: 'qlr',
      add: 'addRightHolder',
      delete: 'deleteRightHolder',
      update: 'modifyRightHolder',
      search: 'getRightHolder',
    };
  },
  ywrOperator() {
    return {
      sqrType: 'ywr',
      add: 'addObligor',
      delete: 'deleteObligor',
      update: 'modifyObligor',
      search: 'getObligor',
    };
  },
  qlxxOperator() {
    return {
      add: 'saveQlxxExMh',
      delete: 'saveQlxxExMh',
      update: 'saveQlxxExMh',
      search: 'getHuInfo',
    };
  },
  // 判断权利人义务人是否重复
  judgeDuplicate(data) {
    return request({
      url: REST_SERVICE.registerFlowController.judgeDuplicate,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 申请管理中新增权利人
  addRightHolder(data) {
    return request({
      url: REST_SERVICE.registerFlowController.addRightHolder,
      method: 'post',
      data,
    });
  },
  // 修改、新增权利人信息
  modifyRightHolder(data) {
    return request({
      url: REST_SERVICE.registerFlowController.modifyRightHolder,
      method: 'post',
      data,
    });
  },
  // 删除权利人（申请人）信息
  deleteRightHolder(data) {
    return request({
      url: REST_SERVICE.registerFlowController.deleteRightHolder,
      method: 'post',
      data,
    });
  },
  // 批量添加权利人
  addRightHolderBatch(data) {
    return request({
      url: REST_SERVICE.registerFlowController.addRightHolderBatch,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 申请管理中新增义务人
  addObligor(data) {
    return request({
      url: REST_SERVICE.registerFlowController.addObligor,
      method: 'post',
      data,
    });
  },
  // 申请管理中批量插入义务人
  addObligorBatch(data) {
    return request({
      url: REST_SERVICE.registerFlowController.addObligorBatch,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  // 修改义务人信息
  modifyObligor(data) {
    return request({
      url: REST_SERVICE.registerFlowController.modifyObligor,
      method: 'post',
      data,
    });
  },
  // 删除义务人
  deleteObligor(data) {
    return request({
      url: REST_SERVICE.registerFlowController.deleteObligor,
      method: 'post',
      data,
    });
  },
  // 根据权利人ID获取权利人信息
  getRightHolder(params) {
    return request({
      url: REST_SERVICE.registerFlowController.getRightHolder,
      method: 'get',
      params,
    });
  },
  // 根据ID获取义务人信息
  getObligor(params) {
    return request({
      url: REST_SERVICE.registerFlowController.getObligor,
      method: 'get',
      params,
    });
  },
  //获取户详情
  getHuInfo(params) {
    return request({
      url: REST_SERVICE.registerFlowController.getHuInfo,
      method: 'get',
      params,
    });
  },
  // 新增、修改、编辑、删除权利信息----保存权利扩展信息多户内容
  saveQlxxExMh(data) {
    return request({
      url: REST_SERVICE.registerFlowController.saveQlxxExMh,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  //统一赋值
  unifiedAssignment(data) {
    return request({
      url: REST_SERVICE.registerFlowController.unifiedAssignment,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  //家庭组的修改
  editFamilyList(data) {
    if (data.type === 'qlr') {
      return this.editQlrFamilyList(data.params);
    } else {
      return this.editYwrFamilyList(data.params);
    }
  },
  //同步
  copyDyrToZwr(data) {
    return request({
      url: REST_SERVICE.registerFlowController.copyDyrToZwr,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  editQlrFamilyList(data) {
    return request({
      url: REST_SERVICE.familyList.editQlrFamilyList,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  editYwrFamilyList(data) {
    return request({
      url: REST_SERVICE.familyList.editYwrFamilyList,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  //获取相同操作申请人的id
  //sqr 当前操作的申请人
  //processData 当前申请表数据
  //category 对应相同操作的申请人category
  getSameSqrData(sqr, processData, category) {
    let sameId = '';
    let sameData = '';
    const sameYwh = processData.ywh;
    let sqrList = processData.qlrs.concat(processData.ywrs);
    sqrList.forEach((item) => {
      if (item.qlrmc === sqr.ywrmc && item.zjzl === sqr.zjzl && item.zjh === sqr.zjh && item.category === category) {
        sameId = item.id;
        sameData = item;
      }
    });
    return {
      id: sameId,
      data: sameData,
      ywh: sameYwh,
    };
  },
  // 部委共享接口核验
  getBwgxInfo(data, query) {
    return request({
      url: REST_SERVICE.bwgx.getBwgxInfo,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
      params: query,
    });
  },
  saveTzr(data) {
    return request({
      url: REST_SERVICE.registerFlowController.saveTzr,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
};

export default applicantOperatorService;
