/**
 * 用户接口
 */

import request from '@manager/utils/request';
import { GLOBAL_CONFIG, REST_SERVICE } from '@manager/config';
import { localDataUser, localDataPermission } from '@/utils/local-data';
import { getAppInfo } from '@manager/api/app';
import { updateToken } from '@greatmap/uums-modules';

export function login(username, password) {
  return request({
    url: REST_SERVICE.login.login,
    method: 'post',
    data: {
      username,
      password,
      appKey: GLOBAL_CONFIG.APP_MANAGER_KEY,
    },
  }).then((res) => {
    const token = res.data.gmsso_cli_ec_key;
    return checkToken(token).then((res) => {
      // 登录后给uumstoken赋值
      updateToken.setToken(token);
      localDataUser.set({
        username,
        token,
      });
      return getUserInfo(res.data.userId).then((resp) => {
        localDataUser.update({
          ...resp,
          userId: res.data.userId,
        });
        return getAppInfo();
      });
    });
  });
}

export function changePassword(name, oldPassword, newPassword) {
  return request({
    url: REST_SERVICE.uums.changePassword,
    method: 'post',
    data: {
      loginName: name,
      oriPassword: oldPassword,
      newPassword: newPassword,
    },
  });
}

//根据机构id获取下级机构
export function getOrg(params) {
  return request({
    url: REST_SERVICE.organizations.getOrgsByAreaId,
    method: 'get',
    params: params,
  });
}

//获取所有区域
export function getArea(params) {
  return request({
    url: REST_SERVICE.area.getTree,
    method: 'get',
    params: params,
  });
}

function checkToken(token) {
  return request({
    url: REST_SERVICE.login.checkToken,
    method: 'get',
    params: {
      token,
      appKey: GLOBAL_CONFIG.APP_MANAGER_KEY,
    },
  });
}

function getUserInfo(userId) {
  return request({
    url: REST_SERVICE.uums.getUserInfo,
    method: 'get',
    params: {
      userId,
    },
  }).then((res) => {
    //获取最大的areaCode值
    let areaCodes = res.data.organizations.map((item) => +item.code);
    areaCodes = areaCodes.length > 0 ? areaCodes : [''];
    let areaCode = Math.max(...areaCodes) + '';
    areaCode = areaCode.indexOf('-') > -1 ? areaCode.substring(0, areaCode.indexOf('-')) : areaCode;
    //找出最大的区域id
    let area = res.data.organizations.find((item) => +item.code === +areaCode);
    area = area ? area : {};
    //获取权限数组
    const permissions = res.data.permissions.map((obj) => {
      return obj.permValue;
    });
    localDataPermission.set(permissions);
    return {
      realName: res.data.realName,
      areaCode: areaCode,
      areaId: area.areaId,
    };
  });
}
