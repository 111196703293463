import axios from 'axios';
import { stringify } from 'qs';

import { errorMessage, warnMessage } from '@/pages/iebdc/utils/error-handler';
import { paramObject } from './utils';
import { localDataUser } from '@/pages/manager/utils/local-data';
import { GLOBAL_CONFIG, REST_SERVICE } from '@/pages/manager/config';
import { crypto } from '@/pages/iebdc/utils/crypto';
import { encryptUrlDataByDes, decryptUrlDataByDes, encryptWhiteInfo } from '@/utils/cryptoUtil';
import lodash from 'lodash';

const whiteList = Object.keys(encryptWhiteInfo);

// 这些接口不添加 token
const requestNoToken = [
  ...Object.values(REST_SERVICE.login),
  // ...Object.values(REST_SERVICE.uums),
  ...Object.values(REST_SERVICE.organizations),
  ...Object.values(REST_SERVICE.area),
];

/**
 * 创建 axios 服务实例
 * @description
 * 该方法所需的参数如下：
 * @param {String} method 请求方式 `get/post`
 * @param {Object} data post 请求的参数
 * @param {Object} params get 请求的参数
 */
const service = axios.create({
  // withCredentials: true,
  timeout: 200000,
});

export default service;

// 请求拦截
service.interceptors.request.use(
  (config) => {
    const url = config.url;
    if (config.url.indexOf('wlmq-internet-estate-server') !== -1) {
      const urlPath = config.url.replace(window.CONFIG.unify_ip, '/');
      const randomStr = 'a3f7J5s2T0gHn9q8D4wE';
      const timestamp = Date.parse(new Date()) + Math.floor(Math.random() * 1000);
      const str = urlPath + randomStr + timestamp;
      config.headers.sign = crypto.encryptByMD5(str);
      config.headers.timestamp = timestamp;
    }
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    if (process.env.NODE_ENV === 'development' || location.href.includes('isTest')) {
      console.log(`%c${config.method}`, 'color: #fff; background: orange', url, config.params, config.data);
    }
    if (whiteList.some((item) => url.includes(item))) {
      config.params && (config.params = encryptUrlDataByDes(url, lodash.cloneDeep(config.params)));
      config.data && (config.data = encryptUrlDataByDes(url, lodash.cloneDeep(config.data)));
      // if (config.data) {
      //   config.data = {
      //     param: crypto.encryptByDES(JSON.stringify(config.data)),
      //   };
      // }
      // if (config.params) {
      //   config.params = {
      //     param: crypto.encryptByDES(JSON.stringify(config.params)),
      //   };
      // }
    }

    //文件流格式转文件
    if (config.method === 'buffer') {
      config.responseType = 'arraybuffer';
      config.method = 'get';
    }
    // post 参数转换为 FormData 形式
    if (config.method === 'post' && !config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      config.data = {
        ...config.data,
        _t: Date.parse(new Date()) / 1000,
      };
      config.data = stringify(config.data, { arrayFormat: 'indices', allowDots: true });
    }
    if (config.method === 'get') {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params,
      };
      config.paramsSerializer = function (params) {
        return stringify(params, { arrayFormat: 'repeat' });
      };
    }
    if (!requestNoToken.includes(config.url)) {
      const userData = localDataUser.get();
      if (userData) {
        config.headers.token = userData.token;
      } else {
        warnMessage('请求已取消(未获取到 token)：' + config.url);
        source.cancel('Cancel');
      }
    }
    return config;
  },
  (error) => {
    return promiseError(error, '请求错误');
  }
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    const data = response.data;
    const url = response.config.url;
    if (!data || data.success === false) {
      // 判断 token 是否正确
      if (data.tokenInvalid) {
        errorMessage('登陆已过期，请重新登陆');
        localDataUser.clear();
        // location.href = '/home-login-out'; // 跳转至登陆
      } else {
        return promiseError(data);
      }
    } else {
      const res = decryptUrlDataByDes(data);
      if (process.env.NODE_ENV === 'development' || location.href.includes('isTest')) {
        console.log('%cresponse', 'color: #fff; background: green', response.config.url, lodash.cloneDeep(res));
      }
      return res;
    }
  },
  (error) => {
    return promiseError(error);
  }
);

function promiseError(data, defaultMessage = '服务异常') {
  if (!(data instanceof axios.Cancel)) {
    errorMessage(data || defaultMessage, '', true);
  }
  return Promise.reject(data);
}
