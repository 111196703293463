import { localDataUser } from '@iebdc/utils/local-data';
import dlrService from '@/pages/iebdc/api/apply/applicant-dlr';
import qlxxService from '@/pages/iebdc/api/apply/applicant-qlxx';
import { crypto } from '@iebdc/utils/crypto.js';
const sqrService = {
  //获取当前用户信息
  getUserInfo() {
    let userInfo = localDataUser.get();
    let userData = {};
    userData.xb = userInfo.userCategory === 'NORMAL' ? userInfo.xb : null;
    userData.dh = userInfo.userCategory === 'NORMAL' ? userInfo.tel : userInfo.lxdh;
    userData.zjzl = userInfo.userCategory === 'NORMAL' ? (userInfo.zjzl ? userInfo.zjzl : '1') : '6';
    userData.zjh = userInfo.userCategory === 'NORMAL' ? userInfo.zjh : userInfo.qyzjh;
    userData.qlrlx = userInfo.userCategory === 'NORMAL' ? '1' : '2';
    userData.gyfs = '0';
    userData.bdcdyhs = [];
    userData.qlrmc = userInfo.name;
    // userInfo.userCategory === 'NORMAL' ? crypto.decryptByDES(userInfo.name) : crypto.decryptByDES(userInfo.name);
    userData.ywrmc = userInfo.name;
    // userInfo.userCategory === 'NORMAL' ? crypto.decryptByDES(userInfo.name) : crypto.decryptByDES(userInfo.name);
    userData.frmc = userInfo.frmc || '';
    userData.frdh = userInfo.frdh || '';
    userData.frzjh = userInfo.frzjh || '';
    userData.fileIds = userInfo.fileIds || [];
    userData.yyzzIds = userInfo.yyzzIds || [];
    if (userInfo.agentUser) {
      userData.whetherDlr = '1';
      userData.dlrlx = '0';
      userData.dlrmc = userInfo.agentUser.name;
      userData.dlrdh = userInfo.agentUser.lxdh;
      userData.dlrzjzl = '1';
      userData.dlrzjh = userInfo.agentUser.sfzh;
    }
    return userData;
  },
  //验证申请表格是否填写完整
  //formData申请信息
  //config当前流程配置
  sqrTableValidate(formData, config) {
    let message = '';

    config.forEach((pitem, index) => {
      //将申请人配置的categroy提出
      pitem.config.forEach((p) => {
        if (p.type === 'table-sqr') {
          let allCategory = getCategory(p.data);
          let allSqrData = getAllSqrData(formData.children[index]);
          tableNull(allCategory, allSqrData, p.data);
          isGtsy(allCategory, allSqrData);
          console.log(p.data, allSqrData);
          // familyNumNull(p.data, allSqrData);
          // familyDuplicate(p.data, allSqrData);
        }
        //将申请人配置的categroy提出
        // let allCategory = getCategory(p.data);
        // let allSqrData = getAllSqrData(formData.children[index]);
        // let isNext = false;
        // tableNull(p.data, formData.children[index], allCategory, allSqrData, p.data, function () {
        //   isNext = true;
        // });
        // if (isNext) {
        //   throw new Error(message);
        // }
        // // 宅基地流程不需要校验共有方式
        // if (!['N10010601', 'N100105', 'N20010401', 'N30010601'].includes(pitem.code)) {
        //   isGtsy(allCategory, allSqrData, formData, index);
        // }
      });
    });

    function getAllParams(pconfig, category, sqrData) {
      let array = [];
      pconfig.forEach((pitem) => {
        if (pitem.type === 'table-sqr' && category === pitem.category) {
          pitem.dialogFormConfig.forEach((item) => {
            dlrService.setWhetherDlr(sqrData);
            if (item.showCallback && !item.showCallback(sqrData)) return;
            item.value.forEach((i) => {
              if (!i.ifRender || (i.ifRender && i.ifRender(sqrData))) {
                i.rule &&
                  i.rule.map((rules) => {
                    for (let key in rules) {
                      if (key === 'required') {
                        array.push(i.key);
                      }
                    }
                  });
              }
            });
          });
        }
      });
      return array;
    }

    //获取当前配置的所有申请人category
    function getCategory(configData) {
      let categoryArray = [];
      configData.forEach((item) => {
        console.log(item);
        if (item.category && item.tableTitle.indexOf('债务人') < 0) {
          //债务人不需要必填
          categoryArray.push(item.category);
        }
      });
      return categoryArray;
    }
    //获取所有申请人
    function getAllSqrData(formData) {
      let sqrDataArray = [];
      sqrDataArray = formData.qlrs.concat(formData.ywrs);
      return sqrDataArray;
    }
    //验证表格无内容
    function tableNull(allCategory, allSqrData, pconfig) {
      let data = [];
      allCategory.forEach((category) => {
        allSqrData.map((sqr) => {
          if (sqr.category === category && (sqr.qlrmc || sqr.ywrmc)) {
            data.push(sqr);
            let allParams = getAllParams(pconfig, category, sqr);
            if (sqr.familyGroup && (sqr.familyGroup.houseNumber || sqr.familyGroup)) {
              sqr.houseNumber = sqr.familyGroup.houseNumber;
            }
            allParams.forEach((params) => {
              if ((!sqr[params] && sqr[params] !== 0) || sqr[params] === '') {
                message = `请确认${sqr.qlrmc || sqr.ywrmc}信息都填写完整`;
              }
            });
          }
        });
      });
      // 数组包含判断
      const isContained = (a, b) => {
        if (!(a instanceof Array) || !(b instanceof Array)) return false;
        const len = b.length;
        if (a.length < len) return false;
        for (let i = 0; i < len; i++) {
          if (!a.includes(b[i])) return false;
        }
        return true;
      };
      const dataCategory = [];
      data.forEach((item) => {
        dataCategory.push(item.category);
      });
      if (data.length < allCategory.length || !isContained(dataCategory, allCategory)) {
        message = '请确认所有申请人信息内容填写完整';
      }
      if (qlxxService.qlxxTableValidate(formData, config).indexOf('0') > -1) {
        message = '请确认所有不动产表格信息都填写完整';
      }
    }
    //权利比例100%
    function qlbl100(sqr, data) {
      let qlbl = 0;
      const categoryData = data.filter((i) => {
        return i.category === sqr.category;
      });
      qlbl = categoryData.reduce((qlbl, accumulator) => {
        // b;
        return Number(accumulator.qlbl) + Number(qlbl);
      }, 0);
      if (qlbl !== 100) {
        message = '共有方式为按份共有时，所有申请人共有方式总和等于100';
      }
    }
    //验证共有方式共同所有要填两个
    function isGtsy(allCategory, allSqrData) {
      let data = [];
      allCategory.forEach((category) => {
        allSqrData.map((sqr) => {
          if (sqr.category === category && (sqr.qlrmc || sqr.ywrmc)) {
            data.push(sqr);
          }
        });
      });
      let samegyfs = {
        gyfs: data[0].gyfs,
        category: data[0].category,
      };
      data.forEach((sqr) => {
        if (samegyfs && samegyfs.gyfs !== sqr.gyfs && samegyfs.category === sqr.category) {
          message = '请保持同种申请人共有方式相同';
        }
        samegyfs = {
          gyfs: sqr.gyfs,
          category: sqr.category,
        };
        if (Number(sqr.gyfs) === 1) {
          const categoryData = data.filter((i) => {
            return i.category === sqr.category;
          });
          if (categoryData.length <= 1) {
            message = '共有方式为共同所有、借款方式为共同借款时，请确认至少填写两个申请人';
          }
        } else if (Number(sqr.gyfs) === 0) {
          const categoryData = data.filter((i) => {
            return i.category === sqr.category;
          });
          if (categoryData.length > 1) {
            message = '共有方式为单独所有时，请确认只有一个申请人';
          }
        } else if (Number(sqr.gyfs) === 2) {
          qlbl100(sqr, data);
        }
      });
    }
    //验证家庭组无内容
    function familyNumNull(config, allSqrData) {
      config.forEach((pitem) => {
        console.log(pitem.category && pitem.familyTableConfig);
        if (pitem.category && pitem.familyTableConfig) {
          allSqrData.forEach((sqr) => {
            if (sqr.category === pitem.category && sqr.qlrlx === '1') {
              // if (!sqr.familyGroup || (sqr.familyGroup && sqr.familyGroup.houseNumber === '')) {
              //   message = '请填写' + (sqr.qlrmc || sqr.ywrmc) + '家庭组房屋套数';
              // }
              console.log(sqr.familyGroup);
              if (
                !sqr.familyGroup ||
                (sqr.familyGroup &&
                  (!sqr.familyGroup.familyMemberList || sqr.familyGroup.familyMemberList.length === 0)) ||
                (sqr.familyGroup && sqr.familyGroup.houseNumber === '')
              ) {
                message = '请填写' + (sqr.qlrmc || sqr.ywrmc) + '家庭组房屋套数';
              }
            }
          });
        }
      });
    }
    // 验证家庭组是否重复
    function familyDuplicate(config, allSqrData) {
      // console.log(config);
      // console.log(allSqrData);
      let tempList = [];
      if (allSqrData && allSqrData.familyGroup) {
        allSqrData.forEach((singleItem) => {
          singleItem.familyGroup.familyMemberList.forEach((data) => {
            tempList.push(data.zjh);
          });
        });
        if (Array.from(new Set(tempList)).length < tempList.length) {
          message = '不同申请人家庭组成员不能重复！';
        }
      }
    }
    return message;
  },
};

export default sqrService;
