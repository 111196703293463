<template>
  <div class="main-container flex-column flex-1 full-width">
    <div class="main-breadcrumb">
      <breadcrumb />
    </div>
    <div v-if="$slots.tabs" class="main-tabs flex-none">
      <slot name="tabs"></slot>
    </div>

    <div class="main-check">
      <div v-if="$slots.tools" class="main-tools flex-none">
        <slot name="tools"></slot>
      </div>
      <div v-if="$slots.header" class="main-header flex-none">
        <slot name="header"></slot>
      </div>
    </div>

    <div v-if="$slots.dialog" class="main-dialog flex-none">
      <slot name="dialog"></slot>
    </div>
    <div class="main-wrap flex-1 flex">
      <div v-if="$slots.aside" class="main-aside flex-none flex">
        <slot name="aside"></slot>
      </div>
      <div class="main-body flex-1 flex-column flex">
        <div v-if="$slots.bodyTabs" class="main-tabs flex-none">
          <slot name="bodyTabs"></slot>
        </div>
        <div v-if="$slots.content" class="main-content flex-1 flex">
          <slot name="content"></slot>
        </div>
        <div v-if="$slots.footer" class="main-footer flex-none">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 主容器组件
 *
 * @slot header 头部，一般是列表过滤
 * @slot tools 工具栏，一般是其他的按钮
 * @slot dialog 弹窗
 * @slot aside 主区域的左侧边栏区，一般放菜单
 * @slot content 主区域的右侧内容区，一般放列表
 * @slot footer 底部区域，一般方分页器
 */
export default {};
</script>

<style lang="scss" scoped>
@import "~@/styles/common-variables.scss";

.main-container {
  margin: 0px;
  position: relative;
}

.main-title,
.main-header,
.main-tools,
.main-aside,
.main-footer {
  background-color: #fff;
  padding: 10px;
}
// .main-breadcrumb{
//   .breadcrumb{
//     width: 100%;
//     height: 90px;
//     background: white;
//     padding: 0 30px;
//   }
// }
// /deep/ .breadcrumb > div:first-child{
//   padding: 20px 0px 15px 0px !important;
// }
// /deep/ .breadcrumb > div{
//   height: auto !important;
// }

.main-check {
  background-color: #fff;
  margin: 10px;
}

.main-wrap {
  margin: 0px 10px;
  background-color: #fff;
  overflow: hidden;
}

.main-body {
  overflow: hidden;
}

.main-tools {
  border-bottom: $border-base;
  padding: 10px 0px 0px 0px;
}

.main-header {
  padding: 15px 20px 15px 0px;
}

.main-aside {
  border-right: $border-base;
}

.main-content {
  margin-top: 15px;
  overflow: hidden;
}

.main-footer {
  margin-top: -1px;
  border-top: $border-base;
}
</style>
