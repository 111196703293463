<template>
  <el-popover placement="right" width="500" trigger="click" @show="popShow()" v-if="showTabel">
    <el-table :data="gridData">
      <el-table-column width="300" property="orgName" label="缴费项目"></el-table-column>
      <el-table-column width="100" property="money" label="金额"></el-table-column>
      <el-table-column width="100" property="billNo" label="缴款码"></el-table-column>
    </el-table>
    <el-link slot="reference">查看缴款码</el-link>
  </el-popover>
  <span v-else>-</span>
</template>
<script>
import payOnline from '@iebdc/api/pay-online.js';
export default {
  data() {
    return {
      title: '',
      status: 0,
      loading: false,
      billNo: [],
      payStatus0: 0,
      payStatus1: 0,
      isCheck: 0,
      showTabel: false,
      gridData: [],
    };
  },
  props: {
    row: Object,
    timestamp: {
      type: String,
      require: false,
      default: 'init',
    },
  },
  watch: {
    'row.billNoList': {
      immediate: true,
      handler() {
        this.popShow();
        // this.init();
      },
    },
  },
  methods: {
    popShow() {
      //   console.log('111222');
      this.gridData = [];
      payOnline.selectPayApplyPageList({ orderNO: this.row.wwywh }).then((res) => {
        if (res.success) {
          if (res.data.page.length !== 0) {
            this.billNo = [];
            res.data.page.forEach((ele) => {
              if (ele.billNo !== '' && ele.billNo !== null) {
                this.showTabel = true;
                this.gridData.push({
                  orgName: ele.orgName,
                  billNo: ele.billNo,
                  money: ele.money,
                });
              }
            });
          }
        }
      });
    },
    init() {
      //   this.showTabel = false;
      //   console.log(this.row);
      //   console.log('billNoList', this.row.hasOwnProperty('billNoList'));
      //   this.thisgridData = this.row.billNoList;
      //   console.log('thisgridData', this.thisgridData);
      //   if (this.thisgridData && this.thisgridData.length > 0) {
      //     console.log('1111');
      //     this.showTabel = true;
      //   }
    },
  },
  mounted() {},
};
</script>
