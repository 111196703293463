<template>
  <!-- 新增权利人 -->
  <el-dialog :visible.sync="visible" center :close-on-click-modal="false" width="60%">
    <!-- 申请人信息 -->
    <div slot="title" class="header-title text-left">家庭组信息</div>
    <dynamic-form-old v-if="visible" ref="formRight" :form-config="formConfig" :form-model="person" />

    <!-- 底部操作按钮 -->
    <span slot="footer">
      <el-button size="small" @click="submitValidate()" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    formConfig: Array,
  },
  data() {
    return {
      // 弹窗状态
      visible: false,
      //当前信息
      person: {},
      //当前行
      index: '',
      //当前组
      groupIndex: '',
    };
  },
  methods: {
    // 打开弹窗
    open(data) {
      this.person = data.row;
      this.index = data.index;
      this.groupIndex = data.groupIndex;
      this.visible = true;
      this.$nextTick(() => {
        // 重置申请人表单字段
        this.$refs.formRight.setData(this.person);
        this.$refs.formRight.resetFields();
      });
    },
    // 表单校验,（传入校验完成，需要执行的函数名称)
    submitValidate() {
      // 家庭组校验
      const formRight = new Promise((resolve, reject) => {
        this.$refs.formRight.$refs.form.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject('请完善表单');
          }
        });
      });
      // 如果有代理人，需全部校验
      Promise.all([formRight])
        .then(() => {
          // 校验成功
          const data = this.$refs.formRight.formData;
          const params = { index: this.index, value: data, groupIndex: this.groupIndex };
          this.$emit('updateService', params);
          this.visible = false;
        })
        .catch((errMsg) => {
          this.$message.error(errMsg);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';
.agent-title {
  padding: 20px;
  font-weight: bold;
  font-size: $font-size-medium;
  border-bottom: $border-base;
}
/deep/ .el-dialog__header {
  @extend .agent-title;
  margin: 0 26px;
}
/deep/ .el-dialog--center .el-dialog__body {
  max-height: 450px;
  overflow-y: auto;
  @extend .scrollbar-default;
  padding: 0 26px 30px;
}
.header-title {
  font-weight: bold;
  font-size: $font-size-medium;
  .el-button {
    margin-left: $spacing-medium;
  }
}
</style>
