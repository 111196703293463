/**
 * 应用接口
 */

import request from '@manager/utils/request';
import { GLOBAL_CONFIG, REST_SERVICE } from '@manager/config';
import { localDataApp, localDataDic } from '@/utils/local-data';

export function getAppInfo() {
  return request({
    url: REST_SERVICE.uums.getAppInfo,
    method: 'get',
    params: {
      name: GLOBAL_CONFIG.APP_MANAGER_KEY,
    },
  }).then((res) => {
    localDataApp.set({
      appId: res.data.id,
    });
    getDicInfo(res.data.id);
    return res;
  });
}

function getDicInfo(appId) {
  return request({
    url: REST_SERVICE.uums.getDicInfo,
    // url: REST_SERVICE.hall.getSystemDic,
    method: 'get',
    params: {
      appId,
    },
  }).then((res) => {
    const data = {
      dic: res.data,
    };
    localDataDic.set(data);
  });
}
