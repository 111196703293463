const dlrService = {
  setWhetherDlr(sqrData) {
    if (sqrData.dlrmc) {
      sqrData.whetherDlr = '1';
      if (sqrData.dljg) {
        sqrData.dlrlx = '1';
      } else {
        sqrData.dlrlx = '0';
      }
    } else {
      sqrData.whetherDlr = '2';
    }
  }
};

export default dlrService;
