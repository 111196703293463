import { LocalDataManager } from './local-storage';

// 存储用户信息
export const localDataUser = new LocalDataManager('IEBDC_USER_DATA');

// 存储应用信息
export const localDataApp = new LocalDataManager('IEBDC_APP_DATA');

// 存储行政区数据含办事大厅数据
export const localDataArea = new LocalDataManager('finalAreaDataLocal');

// 存储字典信息
export const localDataDic = new LocalDataManager('IEBDC_DIC_DATA');

// 存储权限信息
export const localDataPermission = new LocalDataManager('IEBDC_PERMISSION_DATA');
