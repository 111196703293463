<template>
  <el-dialog
    title="高拍仪"
    width="80%"
    :visible.sync="visible"
    destroy-on-close
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-row :gutter="16" v-if="visible" v-loading="ready" element-loading-text="加载中......">
      <el-col :span="12">
        <div class="gpy-container">
          <div class="box">
            <OBJECT id="LaCtrl" classid="CLSID:A14B31B4-C560-4DF7-A30C-59A3AE8C598D" style="width:100%;height:300px;"></OBJECT>
          </div>
          <img v-if="bigSrc" :src="bigSrc" id="bigPriDev" />
        </div>
        <div class="operation">
          <div class="gpyBtn">
            <div class="topBtn">
              <el-row :gutter="10" class="gpy-setting-btn">
                <el-col :span="12">
                  <label class="gpy-label">出图设备</label>
                  <el-select v-model="priDev" style="width: 120px" @change="devChange">
                    <el-option v-for="(item, index) in selectPriDev" :key="index" :value="item.value">{{ item.label }}</el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">设备分辨率</label>
                  <el-select
                    v-model="priResolution"
                    style="width: 120px;"
                    @change="resolutionChange"
                    @visible-change="visibleChange"
                  >
                    <el-option v-for="(item, index) in selectPriResolution" :key="index" :value="item.value">{{
                      item.label
                    }}</el-option>
                  </el-select>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="gpy-photo-btns">
            <el-button @click="goPhoto()" class="camera-btn" type="primary" icon="el-icon-camera">拍照</el-button>
            <el-button @click="initGpy()" class="initGpyBtn" type="primary" icon="el-icon-refresh">初始化高拍仪</el-button>
            <el-button @click="visible = false" class="initGpyBtn" type="primary" icon="el-icon-circle-close">完成</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="12" v-show="!singleFile">
        <div>
          <el-button @click.stop="tipHandle(tip)" v-for="(tip, index) in tips" :key="index">{{ tip }}</el-button>
          <!-- <el-button @click.stop="deleteAllPhoto" type="danger" icon="el-icon-delete" style="float: right;">清空</el-button> -->
        </div>
        <DragTable ref="DragTableRef" :tableData="gpyImgList" @delete="deleteHandle" @change="changeHandle" />
      </el-col>
      <el-col :span="12" v-show="singleFile">
        <p class="single-title">拍照预览</p>
        <img v-if="singleTempUrl" :src="singleTempUrl" style="width: 100%; height: 400px; background-color: #cccccc;" />
        <el-image
          v-else
          style="width: 100%; height: 400px; background-color: #cccccc;"
          fit="fill"
          :src="singleImgUrl"
          :preview-src-list="[singleImgUrl]"
        ></el-image>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import DragTable from './drag-table/index.vue';
import utils from '@/utils/utils';
import { base64UrlToBlob } from './oprations.utils';
export default {
  components: { DragTable },
  data() {
    return {
      gpyKey: 0,
      singleTempUrl: null,
      // 是否是单文件
      singleFile: false,
      fileNamePrefix: '合同',
      ready: true,
      visible: false,
      popoverVisible: false,
      jbcb: false,
      //-----------------------
      priDev: '',
      priModel: '',
      priResolution: '',
      priScanSize: '',
      //---
      bigSrc: null,
      //拍摄图片
      gpyImgList: [],
      //设备数据
      selectPriDev: [],
      //设备出图
      selectPriModel: [
        { label: '彩色', value: '彩色' },
        { label: '灰度', value: '灰度' },
        { label: '黑白', value: '黑白' }
      ],
      //设备分辨率
      selectPriResolution: [],
      //设备尺寸
      selectScanSize: ['原始尺寸', 'A5', 'A4'],
      idx: 0, //选中的设备
      selectResIdx: 0, //选中的分辨率
      num: 1,
      len: 5,
      myBrowser: utils.myBrowser(),
      isTime: false,
      selectVisible: false
    };
  },
  props: {
    initData: [Array, Object]
  },
  computed: {
    singleImgUrl() {
      if (this.gpyImgList.length > 0) {
        return this.gpyImgList[this.gpyImgList.length - 1].uploadUrl;
      } else {
        return '';
      }
    }
  },
  watch: {
    gpyImgList: {
      deep: true,
      immediate: false,
      handler() {
        if (this.$refs.DragTableRef) {
          this.$refs.DragTableRef.updateTable();
        }
        //this.gpyKey = new Date().getTime();
      }
    },
    initData: {
      deep: true,
      immediate: false,
      handler(val) {
        this.gpyImgList = val;
      }
    },
    //当前弹框是否可见
    visible: {
      immediate: false,
      handler(val) {
        if (val) {
          this.ready = false;
          //执行高拍仪
          this.gpyImgList = [];
          this.$nextTick(() => {
            // this.isTime = true;
            this.init(); //初始化设备
          });
        } else {
        }
      }
    }
  },
  methods: {
    getCouponSelected() {},
    init() {
      try {
        if (this.myBrowser != 'IE') {
          this.$message.error('暂不支持该浏览器，请用ie浏览器打开！');
          return;
          //this.init();
        }
        //初始化摄像头
        LaCtrl.CameraStop();
        LaCtrl.Init();
        //2，获取当前设备（默认设备）
        this.getGzequipment();
        //3，默认当前选中设备
        if (this.selectPriDev.length === 0) {
          this.$message.error('未获取到摄像设备,请检查驱动和usb线连接，插入后请刷新浏览器页面！');
          return;
        } else {
          this.setMrPriDev('LiteArray USB2.0 5M Camera');
        }
        //打开摄像头
        this.start_preview();
      } catch (e) {
        this.$message.error('高拍仪服务连接错误，可能服务未启动，请检查驱动和usb线连接，插入后请刷新浏览器页面！');
        return;
      }
    },
    // 初始化数据获取设备分辨率
    getResolving() {
      this.selectPriResolution = [];
      this.priResolution = '';
      this.selectResIdx = '';
      let strResolutions = LaCtrl.getVideoResolutions();
      if (strResolutions == null) {
        return null;
      }
      var arrayResolutions = strResolutions.split(';');
      for (let ii = 0; ii < arrayResolutions.length; ii++) {
        this.selectPriResolution.push({
          label: arrayResolutions[ii],
          value: arrayResolutions[ii]
        });
      }
      console.log(this.selectPriResolution);
    },
    //设置默认的分辨路
    setMrResolvDev(resolv) {
      this.priResolution = this.selectPriResolution[0].label;
      this.selectResIdx = 0;
      this.selectPriResolution.forEach((pri, index) => {
        if (pri.label === resolv) {
          this.priResolution = pri.label;
          this.selectResIdx = index;
        }
      });
      console.log(this.selectResIdx);
    },
    // 初始化数据获取当前设备
    getGzequipment() {
      //获取设备
      let dev = LaCtrl.getDeviceNames();
      console.log(dev);
      console.log('获取设备成功');
      if (dev === null) {
        return null;
      }
      let arrayCameras = dev.split(';');
      arrayCameras = arrayCameras.filter((value) => value !== null);
      this.selectPriDev = [];
      for (let ii = 0; ii < arrayCameras.length; ii++) {
        if (arrayCameras[ii] && arrayCameras[ii] !== '') {
          this.selectPriDev.push({
            label: arrayCameras[ii],
            value: arrayCameras[ii]
          });
        }
      }
      console.log(this.selectPriDev);
    },
    //设置默认摄像设备
    setMrPriDev(devName) {
      console.log('默认设备');
      this.priDev = this.selectPriDev[0].label;
      this.idx = 0;
      this.selectPriDev.forEach((pri, index) => {
        if (pri.label === devName) {
          this.priDev = pri.label;
          this.idx = index;
        }
      });
    },
    // 设置是否是单文件上传
    setSingleFile(val) {
      this.singleFile = val;
    },
    // table上侧按钮操作区
    setFileNamePrefix(name) {
      this.fileNamePrefix = name;
    },
    //打开高拍仪
    start_preview() {
      try {
        LaCtrl.CameraConn(this.idx, 0);
        console.log('打开摄像头');
        //4, 获取当前选中设备分辨率
        this.getResolving();
        //5，设置默认的分辨路
        if (this.selectPriResolution.length === 0) {
          this.$message.error('未获取到分辨率,请检查驱动和usb线连接，插入后请刷新浏览器页面！');
          return;
        } else {
          this.setMrResolvDev('1200*1600');
        }
        //打开设备
        LaCtrl.CameraConn(this.idx, this.selectResIdx);
        console.log('打开成功');
      } catch (e) {
        this.$message.error('高拍仪服务连接错误，可能服务未启动，请检查驱动和usb线连接，插入后请刷新浏览器页面！');
      }
    },
    // 初始化
    initGpy() {
      this.init();
    },
    //关闭弹出框
    close() {
      try {
        LaCtrl.CameraStop();
        this.visible = false;
      } catch (e) {
        this.visible = false;
        return;
      }
    },
    //关闭弹框前的回调
    handleClose(done) {
      try {
        LaCtrl.CameraStop();
        done();
      } catch (e) {
        done();
      }
    },
    //返回删除后的图片数组
    deleteArrayByIndex(i, data) {
      data.splice(i, 1);
      return data;
    },
    //删除图片
    deleteHandle(index) {
      this.gpyImgList = this.deleteArrayByIndex(index, JSON.parse(JSON.stringify(this.gpyImgList)));
      // 更新序号
      this.updateGpyImgList(this.gpyImgList);
      this.$emit('update', this.gpyImgList);
    },
    // 表格数据发生变化
    changeHandle(list) {
      this.updateGpyImgList(list);
      this.$emit('update', this.gpyImgList);
    },
    //更新高拍仪图片列表
    updateGpyImgList() {
      let temp = [];
      let itemTemp = {};
      JSON.parse(JSON.stringify(this.gpyImgList)).forEach((c, i) => {
        itemTemp = Object.assign(c, {
          sxh: i + 1,
          clmc: c.clfl + (i + 1) + '.jpg'
        });
        temp.push(JSON.parse(JSON.stringify(itemTemp)));
      });
      this.gpyImgList = temp;
    },
    //---------------
    //选择设备数据
    devChange(value) {
      this.priDev = value;
      let index = this.selectPriDev.findIndex((item) => item.value === value);
      //打开选择的设备
      this.idx = index;
      LaCtrl.CameraConn(this.idx, 0);
      //重新初始化设备列表
      this.getResolving();
      //5，设置默认的分辨路
      if (this.selectPriResolution.length === 0) {
        this.$message.error('未获取到分辨率,请检查驱动和usb线连接，插入后请刷新浏览器页面！');
        return;
      } else {
        this.setMrResolvDev('1200*1600');
      }
      LaCtrl.CameraConn(this.idx, this.selectResIdx);
    },
    // 选择设备分辨率
    resolutionChange(value) {
      this.priResolution = value;
      let index = this.selectPriResolution.findIndex((item) => item.value === value);
      this.selectResIdx = index;
      LaCtrl.CameraConn(this.idx, this.index);
    },
    //
    visibleChange(val) {
      this.docSelectVisible = val;
    },
    //拍照
    goPhoto() {
      // this.ready = true;
      let val = this.gpyImgList.length + 1;
      let num = this.getFormatNum(val);
      let base64 = LaCtrl.MakeCapture();
      if (base64) {
        this.singleTempUrl = null;
        this.uploadNow({
          id: num,
          url: 'data:image/jpg;base64,' + base64,
          name: this.fileNamePrefix + val
        });
        this.singleTempUrl = 'data:image/jpg;base64,' + base64;
      }
      this.initGpy();
    },
    uploadNow(file) {
      let temp = [];
      let f = base64UrlToBlob(file.url);
      f.name = file.name + '.jpg';
      f['pageType'] = file.pre || '';
      temp.push(f);
      this.$emit('upload', temp);
    },
    // 获取图片文件名
    getFormatNum(source) {
      let strTemp = '';
      let souNum = source;
      if (souNum < 10) {
        this.len = 4;
      } else if (souNum < 100) {
        this.len = 3;
      } else if (souNum < 1000) {
        this.len = 2;
      }
      for (let i = 0; i < this.len; i++) {
        souNum += '0';
      }
      return strTemp + souNum;
    },
    deleteAllPhoto() {
      this.gpyImgList = [];
      this.$emit('update', this.gpyImgList);
    },
    setTips(tips) {
      this.tips = tips;
    },
    // table上侧按钮操作区
    tipHandle(tip) {
      if (this.$refs.DragTableRef.getSelected().length <= 0) {
        this.$message.info('请先选择要操作的数据！');
      }
      let setPre = (pre) => {
        this.$refs.DragTableRef.getSelected().forEach((c) => {
          this.gpyImgList.forEach((c1, i1) => {
            if (c1.id === c.id) {
              let preTemp = this.gpyImgList[i1].clmc.substring(0, this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl));
              let endTemp = this.gpyImgList[i1].clmc.substring(
                this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl),
                this.gpyImgList[i1].clmc.length
              );
              if (preTemp === pre) {
                this.gpyImgList[i1].clmc = endTemp;
              } else {
                this.gpyImgList[i1].clmc = pre + endTemp;
              }
            }
          });
        });
      };
      setPre(tip);
      this.$refs.DragTableRef.updateTable();
      this.$emit('update', this.gpyImgList);
    }
  }
  // beforeDestroy () {
  //   this.webSocket.close();
  // }
};
</script>

<style lang="scss" scoped>
/deep/ #Capture {
  height: 300px !important;
}

.gpy-container {
  height: 300px;
  border: 1px solid #ccc;
  img {
    height: 100%;
    width: 100%;
  }
}
/deep/.el-select-dropdown__wrap {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-select-dropdown__list {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-scrollbar__wrap {
  height: 100px !important;
  min-height: 100px !important;
}
.gpy-label {
  display: inline-block;
  width: 80px;
}

.gpy-setting-btn {
  .el-col {
    margin: 10px 0 0 0;
  }
}

.bottom-btn {
  margin-top: 10px;
  .el-button:nth-child(3) {
    margin-right: 5px;
  }
}

.gpy-photo-btns {
  margin-top: 10px;
  .el-button {
  }
  .el-button:last-child {
    margin-bottom: 0px;
  }
}

.jbcb {
  display: inline-block;
  width: 120px;
  margin-left: 20px;
}

.single-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
.opt {
  height: 100px !important;
}
/deep/.el-option {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-scrollbar {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-scrollbar__view {
  height: 100px !important;
  min-height: 100px !important;
}
</style>
