// 格式化时间
export function formatDate(date, fmt) {
  if (!date) {
    return '暂无时间';
  }
  date = new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
// 格式化数字
export function formatNum(num, index = 3) {
  if (!num) {
    return 0;
  }
  const strNum = String(num);
  let numLen = strNum.length;
  if (numLen <= index) {
    return strNum;
  }
  const arrNum = strNum.split('').reverse();
  const count = Math.floor(arrNum.length / index);
  for (let i = 0; i < count; i++) {
    insert(arrNum, index * (i + 1) + i, ',');
  }
  arrNum.reverse();
  return arrNum.join('');
}
function insert(arr, index, item) {
  arr.splice(index, 0, item);
}

export function formatMessageDic(category) {

  if (category === '0') {
    return '其他';
  } else if (category === '1') {
    return '业务咨询';
  } else if (category === '2') {
    return '投诉建议';
  }
}

//根据code值获取区域名称
export const filterAreaByCode = (code, data) => {
  let obj = getAllNodes(data).filter(item => item.code === code)[0] || { title: '' };
  return obj.title;
}

function getAllNodes(arrayNodes, attChildren = 'childrens') {
  if (!arrayNodes) {
    return [];
  }
  var result = [];
  for (var ii = 0; ii < arrayNodes.length; ii++) {
    var pNode = arrayNodes[ii];
    result.push(pNode);
    if (pNode && pNode[attChildren]) {
      var arrayTempNodes = getAllNodes(pNode[attChildren], attChildren);
      result = result.concat(arrayTempNodes);
    }
  }
  return result;
}