import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantUploadService = {
  // 根据三级流程查询材料列表
  getFileList(params) {
    return request({
      url: REST_SERVICE.hall.getFileList,
      method: 'get',
      params,
    });
  },
  // 获取已上传文件
  getUploadFilesCurrent(data) {
    return request({
      url: REST_SERVICE.files.getUploadFilesCurrent,
      method: 'post',
      data,
    });
  },
  // 获取合同
  getNoSignFileList(data) {
    return request({
      url: REST_SERVICE.files.getNoSignFileList,
      method: 'post',
      data,
    });
  },
  // 获取流程信息
  getProcessInfo(params) {
    return request({
      url: REST_SERVICE.uploadApply.getProcessInfo,
      method: 'get',
      params,
    });
  },
  // 保存上传文件信息
  saveFileUploadInfo(data) {
    return request({
      url: REST_SERVICE.files.saveFileUploadInfo,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
  },
  //获取系统时间
  getSystemTime(params) {
    return request({
      url: REST_SERVICE.hall.getSystemTime,
      method: 'get',
      params,
    });
  },
  // 删除单个文件
  delImage(params) {
    return request({
      url: REST_SERVICE.files.deleteFile,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      params,
    });
  },
  // 删除多个文件
  deleteFileByIdList(data) {
    return request({
      url: REST_SERVICE.files.deleteFileByIdList,
      method: 'post',
      data,
    });
  },
  //上传图片
  upLoadPicture(params) {
    return request({
      url: REST_SERVICE.files.mulFileSave,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary' + new Date().getTime(),
      },
      data: params,
    });
  },
  //获取手机上传文件二维码
  getEwm(params) {
    return request({
      url: REST_SERVICE.uploadApply.getEwm,
      method: 'get',
      params,
    });
  },
};

export default applicantUploadService;
