<template>
  <div class="main">
    <div v-for="(group, groupIndex) in tableData" :key="groupIndex">
      <div
        v-if="group.familyGroup && group.familyGroup.familyMemberList && group.familyGroup.familyMemberList.length > 0"
      >
        <!-- 表格标题 -->
        <div class="table-title flex-space-between">
          <h5>{{ group.qlrmc || group.ywrmc }}的家庭组123</h5>
          <div class="family-title" v-show="!disabled">
            <!-- 表单区域 -->
            <el-form ref="famliyform" :model="group.familyGroup" label-width="130px">
              <el-form-item label="家庭房屋套数" prop="houseNumber" :rules="[rules.required, rules.number]">
                <el-input
                  v-model="group.familyGroup.houseNumber"
                  @input="changeHouseNumber(group)"
                  placeholder="请填写家庭为单位拥有的所有房屋套数"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-dropdown @command="addFamily">
              <el-button type="primary" class="add-button"
                >添加家庭成员<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="addFamilyHandle(item, groupIndex)"
                  v-for="(item, index) in tableData"
                  :key="index"
                  >{{ item.qlrmc || item.ywrmc }}</el-dropdown-item
                >
                <el-dropdown-item :command="addFamilyHandle({}, groupIndex)">其它家庭成员</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" @click="deleteFamilyGroup(groupIndex)">删除家庭组</el-button>
          </div>
        </div>
        <!-- 表格区域 -->
        <base-table :columns="familyTableConfig" :data="group.familyGroup.familyMemberList">
          <!-- 操作列 -->
          <template slot="column-end" v-if="!disabled">
            <el-table-column label="操作" fixed="right" width="200px">
              <template slot-scope="{ row, $index }">
                <el-button
                  v-for="(button, index) in buttonList"
                  :key="index"
                  size="small"
                  type="text"
                  @click="button['name'](row, $index, groupIndex)"
                  >{{ button.label }}
                </el-button>
              </template>
            </el-table-column>
          </template>
        </base-table>
        <!-- 弹窗 -->
        <add-family
          ref="dialog"
          v-if="familyColumnConfig"
          @updateService="updateService"
          :formConfig="familyColumnConfig"
        />
      </div>
    </div>
  </div>
</template>

<script>
import rules from '@/utils/validate.js';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import addFamily from '@iebdc/views/apply/flow/applicantTable/dialog/addFamily.vue';
export default {
  components: { addFamily },
  props: {
    familyTableConfig: {},
    familyColumnConfig: {},
    tableData: Array,
    disabled: Boolean,
  },
  data() {
    return {
      rules: rules,
      buttonList: [
        {
          label: '修改',
          name: this.update,
        },
        {
          label: '删除',
          name: this.delete,
        },
      ],
      //当前增加修改的家庭组
      familyGroupModel: {},
    };
  },
  methods: {
    initFamilyGroup() {
      return {
        familyMemberList: [],
        houseNumber: '0',
      };
    },
    openDialog(descDialog) {
      this.$refs.dialog[descDialog.groupIndex].open({ ...descDialog });
    },
    // 重新获取数据
    getData() {
      this.$emit('getData');
    },
    //更新数据
    setFamilyData(sqrRow) {
      this.addFamilyGroup(sqrRow);
    },
    initFamilyPerson(sqr) {
      let person = {
        name: '',
        familyRelationshipEnum: '',
        zjzl: '1',
        zjh: '',
      };
      //如果menter(成员)有值 则赋值
      if (sqr) {
        //新建成员户主 成员关系为本人
        person.name = sqr.ywrmc || sqr.qlrmc;
        person.familyRelationshipEnum = '';
        person.zjzl = sqr.zjzl;
        person.zjh = sqr.zjh;
      }
      return person;
    },
    initFamilyParams(row, familyGroup) {
      let data = {
        type: row && row.qlrmc ? 'qlr' : 'ywr',
        params: {},
      };
      data.params = {
        sqrId: row.id,
        familyGroup: {
          familyMemberList: familyGroup.familyMemberList || [],
          houseNumber: familyGroup.houseNumber,
        },
      };
      return data;
    },
    //增加
    addFamilyGroup(sqrData) {
      let person = this.initFamilyPerson(sqrData);
      person.familyRelationshipEnum = 'SELF';
      this.familyGroupModel = Object.assign({}, sqrData);
      //如果没有家庭组
      if (!this.familyGroupModel.familyGroup) {
        this.familyGroupModel.familyGroup = this.initFamilyGroup();
      }
      if (!this.familyGroupModel.familyGroup.familyMemberList) {
        this.familyGroupModel.familyGroup.familyMemberList = [];
      }
      this.familyGroupModel.familyGroup.familyMemberList.push(person);
      const params = this.initFamilyParams(this.familyGroupModel, this.familyGroupModel.familyGroup);
      this.saveFamilyGroup(params);
    },
    //删除家庭组
    deleteFamilyGroup(groupIndex) {
      // 删除操作
      this.$confirm('是否删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.tableData[groupIndex].familyGroup.familyMemberList = [];
          const params = this.initFamilyParams(this.tableData[groupIndex], this.tableData[groupIndex].familyGroup);
          this.saveFamilyGroup(params);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //addFamilyhandle 添加家庭成员的数据
    addFamilyHandle(sqr, groupIndex) {
      return {
        sqr: sqr,
        groupIndex: groupIndex,
      };
    },
    //增加新的家庭成员  data.sqr 当前选中的家庭成员 groupIndex增加到的组
    addFamily(data) {
      //家庭组参数
      let person = this.initFamilyPerson(data.sqr);
      this.familyGroupModel = this.tableData[data.groupIndex];
      this.openDialog({
        row: person,
        index: this.familyGroupModel.familyGroup.familyMemberList.length,
        groupIndex: data.groupIndex,
      });
    },
    //修改
    update(row, index, groupIndex) {
      this.openDialog({ row: row, index: index, groupIndex: groupIndex });
    },
    updateService(data) {
      this.familyGroupModel = this.tableData[data.groupIndex];
      let params = Object.assign({}, this.familyGroupModel);
      params.familyGroup.familyMemberList[data.index] = data.value;
      params = this.initFamilyParams(this.familyGroupModel, this.familyGroupModel.familyGroup);
      this.saveFamilyGroup(params);
    },
    delete(row, index, groupIndex) {
      // 删除操作
      this.$confirm('是否删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.familyGroupModel = this.tableData[groupIndex];
          let params = Object.assign({}, this.familyGroupModel);
          params.familyGroup.familyMemberList.splice(index, 1);
          params = this.initFamilyParams(this.familyGroupModel, this.familyGroupModel.familyGroup);
          this.saveFamilyGroup(params);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //修改家庭组套数
    changeHouseNumber(group) {
      const params = this.initFamilyParams(group, group.familyGroup);
      this.saveFamilyGroup(params);
    },
    //保存家庭组
    saveFamilyGroup(params) {
      applicantOperatorService.editFamilyList(params).then((res) => {
        if (res.success) {
          // 提交成功
          this.$message.success('操作成功');
          this.$emit('refresh');
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';
.table-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  h5 {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      height: 3px;
      width: 108%;
      bottom: -20px;
      left: -7%;
      background-color: $color-primary;
    }
  }
  .family-title {
    display: flex;
    /deep/.el-form-item {
      margin-bottom: 0px;
    }
    .add-button {
      margin: 0px 10px;
    }
  }
}
</style>
