<template>
  <h3>
    Hello world.
  </h3>
</template>

<script>
export default {
  name: 'example'
};
</script>

<style lang="scss" scoped></style>
