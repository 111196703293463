<template>
  <div class="main">
    <!-- 表格标题 -->
    <div class="type-title">
      <h5>
        <span class="span">{{ commonTableConfig.tableTitle }}</span>
        <div class="groupbutton" v-show="!(disabled || sameDisable)">
          <el-button
            type="primary"
            size="mini" 
            v-if="tableData && tableData.length > 0 ? tableData[0].gyfs != 0 : true"
            @click="addTableList('add', operator.add, operator.sqrType)"
            ><i class="icon el-icon-circle-plus-outline"></i>添加</el-button
          >
          <el-button
            type="primary"
            size="mini"
            plain
            v-if="
              zwrCategory.indexOf(commonTableConfig.category) > -1 &&
              flowInfoIng.code !== 'N200111' &&
              flowInfoIng.code !== 'N200112' &&
              flowInfoIng.code !== 'N100203' &&
              flowInfoIng.code !== 'N300126'
            "
            @click="addTableListToZwr()"
            ><i class="el-icon-document-copy"></i>同抵押人</el-button
          >
          <el-dropdown
            @command="addFamiltGroup"
            v-show="
              commonTableConfig.familyTableConfig &&
              commonTableConfig.familyColumnConfig &&
              tableData.length > 0 &&
              showFamily
            "
          >
            <el-button class="add-button" size="mini" type="primary" plain>
              <i class="el-icon-circle-plus-outline"></i>添加家庭组
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item" v-for="(item, index) in tableData" :key="index">{{
                item.qlrmc || item.ywrmc
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <span class="color-red" v-show="zwrCategory.indexOf(commonTableConfig.category) > -1"
          >（若债务人与抵押人相同同步抵押人信息即可，若不相同请根据实际情况添加）</span
        > -->
      </h5>
    </div>
    <!-- 表格区域 -->
    <div v-for="(data, i) in tableData" :key="i">
      <div class="operator" v-show="!disabled">
        <span class="sqr-name-title"><i class="el-icon-user-solid colorblue"></i>{{ data.qlrmc || data.ywrmc }}</span>
        <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
          <el-link
            class="update-button"
            type="primary"
            icon="el-icon-edit"
            size="mini"
            @click="updateTableList('update', operator['search'], operator['update'], data, operator.sqrType, i)"
            >修改</el-link
          >
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
          <el-link
            class="delete-button"
            type="primary"
            icon="el-icon-delete"
            size="mini"
            @click="deleteTableList('delete', operator['search'], operator['delete'], data, operator.sqrType, i)"
            >删除</el-link
          >
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="设为联系人" placement="top-start">
          <el-link
            class="set-button"
            type="primary"
            size="mini"
            icon="el-icon-setting"
            @click="setTzrList('setTzr', operator['search'], operator['setTzr'], data, operator.sqrType, i)"
            >设为联系人</el-link
          >
        </el-tooltip>
      </div>
      <dynamic-form-old
        ref="sqr"
        v-if="commonTableConfig.dialogFormConfig"
        :form-config="dialogConfig.length > 0 ? dialogConfig : commonTableConfig.dialogFormConfig"
        :formModel="data"
        :disabled="true"
      >
      </dynamic-form-old>
    </div>
    <div v-if="tableData.length === 0" class="zwgdiv">
      <span>暂无登记申请人</span>
    </div>
    <!-- 动态表单区域 -->
    <dynamic-form-old
      ref="tzrform"
      :keyName="'tzrxxes'"
      @setData="setTzrData"
      v-if="commonTableConfig.category && tableData && tableData.length > 0"
      :form-config="formConfig"
      :formModel="tzrData"
      :disabled="disabled"
    />
    <!-- 核验区域 -->
    <!-- <common-check
      v-if="tableData && tableData.length > 0"
      :checkTitle="commonTableConfig.tableTitle"
      :checkData="tableData"
    /> -->
    <common-family
      ref="familyGroup"
      v-if="
        tableData &&
        tableData.length > 0 &&
        this.commonTableConfig.familyTableConfig &&
        this.commonTableConfig.familyColumnConfig
      "
      :familyTableConfig="commonTableConfig.familyTableConfig"
      :familyColumnConfig="commonTableConfig.familyColumnConfig"
      :tableData="tableData"
      :disabled="disabled || sameDisable"
      @refresh="getData"
    ></common-family>

    <!-- 弹窗 -->
    <table-sqr-dialog
      ref="dialog"
      v-if="commonTableConfig.dialogFormConfig"
      @refresh="getData"
      :formConfig="dialogConfig.length > 0 ? dialogConfig : commonTableConfig.dialogFormConfig"
      :disabled="disabled || sameDisable"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import rules from '@/utils/validate.js';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import tableOperator from '@iebdc/mixin/tableOperator';
import { sameSqr } from '@public/applyConfig/sameSqr';
import dlrService from '@/pages/iebdc/api/apply/applicant-dlr';
export default {
  mixins: [tableOperator],
  props: {
    commonTableConfig: {},
    formModel: {
      type: Object,
      default: () => {},
    },
    disabled: Boolean,
    sameDisable: Boolean,
  },
  data() {
    return {
      qlrCategory: ['4', '0', '2', '7', '9', '12'],
      ywrCategory: ['5', '6', '1', '3', '8', '10', '11'],
      zwrCategory: ['6', '11'],
      zwgData: [],
      // 表单配置
      formConfig: [
        {
          value: [
            {
              title: '联系人',
              span: 12,
              key: 'tzrmc',
              type: 'input',
              props: {
                placeholder: '请输入联系人',
              },
              rule: [rules.required],
            },
            {
              title: '联系电话',
              span: 12,
              key: 'tzdh',
              type: 'input',
              props: {
                placeholder: '请输入联系电话',
              },
              rule: [rules.required, rules.phone],
            },
          ],
        },
      ],
      //相同申请人配置
      sameConfig: '',
      //当前弹框
      currentStatus: '',
      //是否显示家庭组
      showFamily: false,
      dialogConfig: [],
      // tableData: []
    };
  },
  created() {
    if (this.commonTableConfig.category) {
      this.buttonList.push({
        label: '设为联系人',
        type: 'setTzr',
        name: this.setTzrList,
        show: !this.disabled,
      });
    }

    //初始化按钮
    if (this.qlrCategory.indexOf(this.commonTableConfig.category) > -1) {
      this.setOperator('qlrOperator');
    } else if (this.ywrCategory.indexOf(this.commonTableConfig.category) > -1) {
      this.setOperator('ywrOperator');
    }
    //初始化相同申请人
    sameSqr.forEach((data) => {
      if (data.code === this.flowInfoIng.code) {
        this.sameConfig = data.same;
      }
    });
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData']),

    /**
     * 表格数据
     */
    tableData() {
      let tableData = [];
      const sqrxx = this.formModel['qlrs'].concat(this.formModel['ywrs']);
      sqrxx.forEach((item) => {
        console.log(item);
        console.log(item.category);
        console.log(this.commonTableConfig);
        if (item.category === this.commonTableConfig.category) {
          dlrService.setWhetherDlr(item);
          tableData.push(item);
        }
      });
      let isShowFamilyArr = [];
      //初始化是否显示家庭组
      tableData.map((item) => {
        isShowFamilyArr.push(item.qlrlx);
      });
      this.showFamily = ~isShowFamilyArr.indexOf('1') ? true : false;
      return tableData;
    },
    /**
     * 通知人对象
     */
    tzrData() {
      let tzrxxesList = {
        tzrmc: '',
        tzdh: '',
        category: this.commonTableConfig.category,
        tzrfl: this.commonTableConfig.category,
      };

      (this.formModel['tzrxxes'] || []).forEach((item) => {
        if (item.category === this.commonTableConfig.category) {
          tzrxxesList = Object.assign({}, item);
        }
      });
      return tzrxxesList;
    },
  },
  // watch: {
  //   processData(newVal){
  //     this.formModel = newVal[0];
  //     console.log('this.formModel', this.formModel)
  //   }
  // },
  methods: {
    ...mapMutations('wysq-data', ['setFormData']),
    tableInitData(type) {
      this.dialogConfig = $.extend([], this.commonTableConfig.dialogFormConfig);
      this.dialogConfig.map((item) => {
        item.value.map((childrenItem) => {
          if (childrenItem.key === 'gyfs') {
            if (type) {
              childrenItem.props.disabled = true;
            } else {
              childrenItem.props.disabled = false;
            }
          }
        });
      });
    },
    openDialog(descDialog) {
      if (
        (this.tableData.length > 1 && this.tableData[0].gyfs != 0 && this.tableData[0].gyfs) ||
        (this.tableData.length > 0 && this.tableData[0].gyfs && !descDialog.hasOwnProperty('index'))
      ) {
        this.tableInitData(true);
        descDialog.rowData.gyfs = this.tableData[0].gyfs;
      } else {
        this.tableInitData(false);
      }
      this.$refs.dialog.open({ ...descDialog });
    },
    //删除表格参数
    getDeleteParams(rowData) {
      const params = { id: rowData.id };
      return params;
    },
    //删除申请人信息后
    deleteBefore(rowData) {
      //清除通知人
      if (this.tzrData.tzrmc === (rowData.qlrmc || rowData.ywrmc) && this.tzrData.tzdh === rowData.dh) {
        this.tzrData.tzrmc = '';
        this.tzrData.tzdh = '';
        this.$refs.tzrform.setData(this.tzrData);
      }
    },
    //设置为通知人
    setTzrList(type, searchService, buttonService, rowData) {
      this.tzrData.tzrmc = rowData.dlrmc || rowData.qlrmc || rowData.ywrmc;
      this.tzrData.tzdh = rowData.dlrdh || rowData.dh;
      this.$refs.tzrform.setData(this.tzrData);
    },
    //保存值
    setTzrData(data) {
      if (this.processData && this.processData.length > 0) {
        let dataArray = JSON.parse(JSON.stringify(this.processData));
        if (dataArray[this.flowInfoIng.index][data.key] && dataArray[this.flowInfoIng.index][data.key].length > 0) {
          try {
            dataArray[this.flowInfoIng.index][data.key].forEach((item, index) => {
              if (item.category === this.commonTableConfig.category) {
                dataArray[this.flowInfoIng.index][data.key][index] = data.value;
                throw Error();
              }
              if (index === dataArray[this.flowInfoIng.index][data.key].length - 1) {
                dataArray[this.flowInfoIng.index][data.key].push(data.value);
              }
            });
          } catch (e) {}
        } else {
          dataArray[this.flowInfoIng.index][data.key] = [];
          dataArray[this.flowInfoIng.index][data.key].push(data.value);
        }
        this.setFormData(dataArray);
      }
    },
    //添加家庭组
    addFamiltGroup(row) {
      this.$refs.familyGroup.setFamilyData(row);
    },
    //抵押人同步为债务人
    //tableData 当前抵押人表格
    addTableListToZwr() {
      let zwrTable = [];
      const sqrxx = this.formModel['qlrs'].concat(this.formModel['ywrs']);
      sqrxx.forEach((item) => {
        if (Number(item.category) === Number(this.commonTableConfig.category) - 1) {
          zwrTable.push(item);
        }
      });
      if (zwrTable.length <= 0) {
        this.$message.warning('请先添加至少一条抵押人信息');
        return false;
      }
      applicantOperatorService
        .copyDyrToZwr({
          ywh: this.flowInfoIng.ywh,
          ywrList: zwrTable,
          zwrCategory: this.commonTableConfig.category,
        })
        .then((res) => {
          if (res.success) {
            // 提交成功
            this.getData();
            this.$message.success('同步成功');
          } else {
            this.$message.success('同步失败');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';
.operator {
  text-align: right;
  height: 40px;
  line-height: 40px;
  //border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  position: relative;
  .sqr-name-title {
    position: absolute;
    left: 10px;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    .colorblue {
      color: #2c8bff;
      font-size: 18px;
      margin-right: 6px;
      vertical-align: text-bottom;
    }
  }
  a {
    margin: 0px 10px;
  }
}
.table-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  .add-button {
    margin: 0px 10px;
  }
  .color-red {
    color: red;
    font-size: 14px;
  }
  h5 {
    position: relative;
  }
}
.color-red {
  color: #ff2c2c;
  font-size: 12px;
  margin-left: 8px;
}
.table-row {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  .zwg {
    width: calc(100% - 100px);
  }
}
.table-row-title {
  border-top: 1px solid #c6d5ea;
  border-left: 1px solid #c6d5ea;
  background-color: #f4f4f4;
  width: 150px;
  padding: 0px 10px;
  > div {
    min-height: 32px;
  }
}
/deep/ .el-button-setTzr span {
  color: #7ed321 !important;
}
/deep/ .el-button-delete span {
  color: #ff2c2c !important;
}
.type-title {
  display: block;
  text-align: left;
  background-color: #f2f6fc;
  border-left: 1px solid #c6d5ea;
  .groupbutton {
    display: inline-block;
  }
  h5 {
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    .span {
      padding-left: 10px;
      border-left: 4px solid #2c8bff;
    }
  }
  .el-button {
    margin-left: 10px;
    i {
      padding-right: 5px;
      font-size: 14px;
      vertical-align: text-bottom;
    }
  }
}
.zwgdiv {
  width: 100%;
  height: 65px;
  text-align: center;
  line-height: 65px;
}
.el-button--primary.is-plain {
  color: #2c8bff;
  background: rgba($color: #000000, $alpha: 0);
  border-color: #2c8bff;
}
</style>
