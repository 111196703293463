<template>
  <el-dialog title="光阵高拍仪" width="80%" :visible.sync="visible" destroy-on-close :close-on-click-modal="false">
    <el-row :gutter="16" v-if="visible" v-loading="ready" element-loading-text="加载中......">
      <el-col :span="12">
        <div class="gpy-container">
          <div class="box">
            <object
              classid="clsid:88638732-1B2B-4BD8-AA32-8159092E9F51"
              id="LaCtrl"
              codebase="*.cab#version=0,0,0,0"
              style="width: 100%; height: 300px"
            ></object>
          </div>
          <img v-if="bigSrc" :src="bigSrc" id="bigPriDev" />
        </div>
        <div class="operation">
          <div class="gpyBtn">
            <div class="topBtn">
              <el-row :gutter="10" class="gpy-setting-btn">
                <el-col :span="12">
                  <label class="gpy-label">出图设备</label>
                  <el-select v-model="priDev" style="width: 120px" @change="devChange">
                    <el-option v-for="(item, index) in selectPriDev" :key="index" :value="item.value">{{
                      item.label
                    }}</el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">颜色</label>
                  <el-select v-model="priModel" style="width: 120px" @change="modelChange">
                    <el-option v-for="(item, index) in selectPriModel" :key="index" :value="item.value">{{
                      item.label
                    }}</el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">设备分辨率</label>
                  <el-select
                    v-model="priResolution"
                    style="width: 120px"
                    @change="resolutionChange"
                    @visible-change="visibleChange"
                  >
                    <el-option v-for="(item, index) in selectPriResolution" :key="index" :value="item.value">{{
                      item.label
                    }}</el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">尺寸</label>
                  <el-select v-model="priScanSize" style="width: 120px" @change="scanSize">
                    <el-option v-for="(item, index) in selectScanSize" :key="index" :value="item">{{ item }}</el-option>
                  </el-select>
                </el-col>
              </el-row>
            </div>
            <div class="bottom-btn">
              <el-button @click="rotateLeft()" icon="undo">图片左转</el-button>
              <el-button @click="rotateRight()" icon="redo">图片右转</el-button>
              <!-- <el-button @click="showProperty()" icon="setting">打开图片设置</el-button> -->
              <div class="jbcb">
                纠偏裁边：
                <el-switch v-model="jbcb" @change="setdeskew" />
              </div>
            </div>
          </div>
          <div class="gpy-photo-btns">
            <el-button @click="goPhoto()" class="camera-btn" type="primary" icon="el-icon-camera">拍照</el-button>
            <el-button @click="initGpy()" class="initGpyBtn" type="primary" icon="el-icon-refresh"
              >初始化高拍仪</el-button
            >
            <el-button @click="visible = false" class="initGpyBtn" type="primary" icon="el-icon-circle-close"
              >完成</el-button
            >
          </div>
        </div>
      </el-col>
      <el-col :span="12" v-if="singleFile">
        <p class="single-title">拍照预览</p>
        <img v-if="singleTempUrl" :src="singleTempUrl" style="width: 100%; height: 400px; background-color: #cccccc" />
        <el-image
          v-else
          style="width: 100%; height: 400px; background-color: #cccccc"
          fit="fill"
          :src="singleImgUrl"
          :preview-src-list="[singleImgUrl]"
        ></el-image>
      </el-col>
      <el-col :span="12" v-else>
        <div>
          <el-button @click.stop="tipHandle(tip)" v-for="(tip, index) in tips" :key="index">{{ tip }}</el-button>
          <el-button @click.stop="deleteAllPhoto" type="danger" icon="el-icon-delete" style="float: right"
            >清空</el-button
          >
        </div>
        <DragTable
          ref="DragTableRef"
          :key="gpyKey"
          :tableData="gpyImgList"
          @delete="deleteHandle"
          @change="changeHandle"
        />
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import DragTable from './drag-table/index.vue';
import QWebChannel from '@/plugins/gpyMain.js';
import utils from '@/utils/utils';
import { base64UrlToBlob } from './oprations.utils';

export default {
  components: { DragTable },
  data() {
    return {
      gpyKey: 0,
      singleTempUrl: null,
      // 是否是单文件
      singleFile: false,
      fileNamePrefix: '合同',
      ready: true,
      visible: false,
      popoverVisible: false,
      jbcb: false,
      //-----------------------
      priDev: '',
      priModel: '',
      priResolution: '',
      priScanSize: '',
      //---
      bigSrc: null,
      //拍摄图片
      gpyImgList: [],
      //设备数据
      selectPriDev: [],
      //设备出图
      selectPriModel: [
        { label: '彩色', value: '彩色' },
        { label: '灰度', value: '灰度' },
        { label: '黑白', value: '黑白' },
      ],
      //设备分辨率
      selectPriResolution: [],
      //设备尺寸
      selectScanSize: ['原始尺寸', 'A5', 'A4'],
      idx: 0,
      num: 1,
      len: 5,
      myBrowser: utils.myBrowser(),
      isTime: false,
      selectVisible: false,
    };
  },
  props: {
    initData: [Array, Object],
  },
  computed: {
    singleImgUrl() {
      if (this.gpyImgList.length > 0) {
        return this.gpyImgList[this.gpyImgList.length - 1].uploadUrl;
      } else {
        return '';
      }
    },
  },
  watch: {
    gpyImgList: {
      deep: true,
      immediate: false,
      handler() {
        this.gpyKey = new Date().getTime();
      },
    },
    initData: {
      deep: true,
      immediate: false,
      handler(val) {
        console.log(val, 'val');
        this.gpyImgList = val;
      },
    },
    visible: {
      immediate: false,
      handler(val) {
        if (val) {
          // this.ready = true;
          //执行高拍仪
          this.gpyImgList = [];
          this.$nextTick(() => {
            // this.isTime = true;
            this.open();
            this.openGpyWebSocket();
          });
          this.ready = false;
        } else {
        }
      },
    },
  },
  methods: {
    getCouponSelected() {},
    open() {
      this.visible = true;
      this.selectPriDev = [];
      this.selectPriResolution = [];
    },
    // 初始化数据获取设备分辨率
    getResolving() {
      let iResIndex = 0;
      if (iResIndex != -1) {
        let count = LaCtrl.GetResCount(this.idx);
        for (let i = 0; i < count; i++) {
          let w = LaCtrl.GetResWidth(this.idx, i);
          let h = LaCtrl.GetResHeight(this.idx, i);
          let str = w.toString() + 'x' + h.toString();
          console.log(str, 'str');
          this.selectPriResolution.push({
            label: str,
            value: str,
          });
          // this.selectPriResolution = [
          //   { label: '2592x1944', value: '2592x1944 ' },
          //   { label: '1280x1024 ', value: '1280x1024  ' },
          //   // { label: '1024x768 ', value: '1024x768  ' },
          //   { label: '176x144 ', value: '176x144  ' }
          // ];
          this.priResolution = this.selectPriResolution[0].label;
        }
      }
    },
    // 初始化数据获取当前设备
    getGzequipment() {
      let count = LaCtrl.GetCameraCount();
      console.log(count, 'count');
      for (let i = 0; i < count; i++) {
        let s = LaCtrl.GetDevName(i);
        this.selectPriDev.push({
          label: s,
          value: s,
        });
        this.priDev = this.selectPriDev[0].label;
      }
    },
    // 设置是否是单文件上传
    setSingleFile(val) {
      this.singleFile = val;
    },

    close() {
      this.visible = false;
    },
    deleteArrayByIndex(i, data) {
      data.splice(i, 1);
      return data;
    },
    deleteHandle(index) {
      this.gpyImgList = this.deleteArrayByIndex(index, JSON.parse(JSON.stringify(this.gpyImgList)));
      // 更新序号
      this.updateGpyImgList(this.gpyImgList);
      this.$emit('update', this.gpyImgList);
    },
    // 表格数据发生变化
    changeHandle(list) {
      this.updateGpyImgList(list);
      this.$emit('update', this.gpyImgList);
    },
    updateGpyImgList() {
      let temp = [];
      let itemTemp = {};
      JSON.parse(JSON.stringify(this.gpyImgList)).forEach((c, i) => {
        itemTemp = Object.assign(c, {
          sxh: i + 1,
          clmc: c.clfl + (i + 1) + '.jpg',
        });
        temp.push(JSON.parse(JSON.stringify(itemTemp)));
      });
      this.gpyImgList = temp;
    },
    openGpyWebSocket() {
      setTimeout(() => {
        if (this.myBrowser != 'IE') {
          this.$message.error('暂不支持该浏览器，请用ie浏览器打开！');
          this.open();
        }
        if (LaCtrl.StartPreview(this.idx) === 0) {
          LaCtrl.SetWelcomeStr(this.idx, ' ');
          LaCtrl.ShowZoomRate(this.idx, 0);
          LaCtrl.StartPreview(this.idx);
          this.getGzequipment();
          this.getResolving();
        } else {
          this.$message.error('高拍仪服务连接错误，可能服务未启动，请检查驱动和usb线连接，插入后请刷新浏览器页面！');
        }
      }, 2000);
    },
    // 初始化
    initGpy() {
      console.log(LaCtrl.StartPreview(this.idx), '5555666');
      this.open();
      this.openGpyWebSocket();
    },
    //左转
    rotateLeft() {
      LaCtrl.RotateLeft(this.idx);
    },
    //右转
    rotateRight() {
      LaCtrl.RotateRight(this.idx);
    },
    //获取Windows临时目录全路径
    showProperty() {
      LaCtrl.GetWinTempPath();
    },
    //纠偏裁边
    setdeskew(value) {
      console.log(value, typeof value, 'value');
      LaCtrl.SetupAutoRotate(this.idx, value);
    },
    //---------------
    //选择设备数据
    devChange(value) {
      this.priDev = value;
      let index = this.selectPriDev.findIndex((item) => item.value === value);
      LaCtrl.SetVisible(this.idx, false);
      LaCtrl.StopPreview(this.idx);
      this.idx = index;
      LaCtrl.StartPreview(this.idx);
      LaCtrl.SetVisible(this.idx, true);
    },
    modelChange(value) {
      this.priModel = value;
      let index = this.selectPriModel.findIndex((item) => item.value === value);
      LaCtrl.SetDevColor(this.idx, index);
    },
    // 选择设备分辨率
    resolutionChange(value) {
      this.priResolution = value;
      let index = this.selectPriResolution.findIndex((item) => item.value === value);
      LaCtrl.SetDevRes(this.idx, index);
    },
    visibleChange(val) {
      console.log(val, 'val');
      this.docSelectVisible = val;
    },
    scanSize(value) {
      switch (value) {
        case '原始尺寸':
          window.gpy.get_actionType('setScanSize_ori');
          break;
        case 'A5':
          window.gpy.get_actionType('setScanSize_A5');
          break;
        case 'A4':
          window.gpy.get_actionType('setScanSize_A4');
          break;
        case '卡片':
          window.gpy.get_actionType('setScanSize_card');
          break;
      }
    },
    //拍照
    goPhoto() {
      // this.ready = true;
      let val = this.num;
      let imgCount = 0;
      let filename = this.getFormatNum(val);
      let ext = 'jpg';
      let path = 'C:\\Img' + filename + '.' + ext;
      this.num++;
      LaCtrl.Capture(this.idx, path);
      imgCount += 1;
      this.singleTempUrl = null;
      this.uploadNow({
        id: imgCount,
        url: 'data:image/jpg;base64,' + LaCtrl.GetBase64(path),
        name: this.fileNamePrefix + imgCount,
      });
      this.singleTempUrl = 'data:image/jpg;base64,' + LaCtrl.GetBase64(path);
      console.log('this.gpyImgList', this.gpyImgList);
    },
    uploadNow(file) {
      console.log(file, 'file');
      let temp = [];
      let f = base64UrlToBlob(file.url);
      f.name = file.name + '.jpg';
      f['pageType'] = file.pre || '';
      temp.push(f);
      this.$emit('upload', temp);
    },
    // 获取图片文件名
    getFormatNum(source) {
      let strTemp = '';
      let souNum = source;
      console.log(souNum, 'source');
      if (souNum < 10) {
        this.len = 4;
      } else if (souNum < 100) {
        this.len = 3;
      } else if (souNum < 1000) {
        this.len = 2;
      }
      for (let i = 0; i < this.len; i++) {
        souNum += '0';
      }
      return strTemp + souNum;
    },
    deleteAllPhoto() {
      this.gpyImgList = [];
      this.$emit('update', this.gpyImgList);
    },
    // table上侧按钮操作区
    setFileNamePrefix() {
      this.fileNamePrefix = name;
    },
    setTips(tips) {
      this.tips = tips;
    },
    // table上侧按钮操作区
    tipHandle(tip) {
      if (this.$refs.DragTableRef.getSelected().length <= 0) {
        this.$message.info('请先选择要操作的数据！');
      }
      let setPre = (pre) => {
        this.$refs.DragTableRef.getSelected().forEach((c) => {
          this.gpyImgList.forEach((c1, i1) => {
            if (c1.id === c.id) {
              let preTemp = this.gpyImgList[i1].clmc.substring(
                0,
                this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl)
              );
              let endTemp = this.gpyImgList[i1].clmc.substring(
                this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl),
                this.gpyImgList[i1].clmc.length
              );
              if (preTemp === pre) {
                this.gpyImgList[i1].clmc = endTemp;
              } else {
                this.gpyImgList[i1].clmc = pre + endTemp;
              }
            }
          });
        });
      };
      setPre(tip);
      this.$refs.DragTableRef.updateTable();
      this.$emit('update', this.gpyImgList);
    },
  },
  beforeDestroy() {
    this.webSocket.close();
  },
};
</script>

<style lang="scss" scoped>
/deep/ #Capture {
  height: 300px !important;
}

.gpy-container {
  height: 300px;
  border: 1px solid #ccc;
  img {
    height: 100%;
    width: 100%;
  }
}
/deep/.el-select-dropdown__wrap {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-select-dropdown__list {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-scrollbar__wrap {
  height: 100px !important;
  min-height: 100px !important;
}
.gpy-label {
  display: inline-block;
  width: 80px;
}

.gpy-setting-btn {
  .el-col {
    margin: 10px 0 0 0;
  }
}

.bottom-btn {
  margin-top: 10px;
  .el-button:nth-child(3) {
    margin-right: 5px;
  }
}

.gpy-photo-btns {
  margin-top: 10px;
  .el-button {
  }
  .el-button:last-child {
    margin-bottom: 0px;
  }
}

.jbcb {
  display: inline-block;
  width: 120px;
  margin-left: 20px;
}

.single-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
.opt {
  height: 100px !important;
}
/deep/.el-option {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-scrollbar {
  height: 100px !important;
  min-height: 100px !important;
}
/deep/.el-scrollbar__view {
  height: 100px !important;
  min-height: 100px !important;
}
</style>
