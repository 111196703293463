// export const GLOBAL_CONFIG = window.CONFIG; // 来自 public/config.js 中
import { crypto } from '@manager/utils/crypto';
const DES = (config) => {
  const config_key = ['code', 'title', 'APP_INFO'];
  for (let key in config) {
    if (config_key.indexOf(key) < 0 && typeof config[key] === 'string') {
      config[key] = crypto.decryptByDES(config[key]) || config[key];
    }
  }
  return config;
};
let config = window.CONFIG; // 来自 public/config.js中
if (window.CONFIG && window.CONFIG.unify_ip.indexOf('http') < 0) {
  config = DES(Object.assign({}, window.CONFIG));
}
window.CONFIG = config;
export const GLOBAL_CONFIG = window.CONFIG; // 来自 public/config. js 中
export const REST_SERVICE = {
  login: {
    checkToken: GLOBAL_CONFIG.login + 'checkTokenByAppKey', // 获取token
    login: GLOBAL_CONFIG.login + 'login', // 登录
    logout: GLOBAL_CONFIG.login + 'logout', // 登出
  },
  uums: {
    getAppInfo: GLOBAL_CONFIG.uums + 'app/get', // app 详情
    getAppList: GLOBAL_CONFIG.uums + 'app/list', // 获取子系统列表
    save: GLOBAL_CONFIG.uums + 'app/save', // 保存子系统信息
    delete: GLOBAL_CONFIG.uums + 'app/delete', // 删除子系统信息
    changePassword: GLOBAL_CONFIG.uums + 'user/updatePassword', // 修改密码
    getUserInfo: GLOBAL_CONFIG.uums + 'user/get', // 用户详情
    getDicInfo: GLOBAL_CONFIG.uums + 'dict/tree', // 获取字典
    saveDicInfo: GLOBAL_CONFIG.uums + 'dict/save', // 保存字典
    saveUserInfo: GLOBAL_CONFIG.uums + 'user/save', // 保存字典
    delDicInfo: GLOBAL_CONFIG.uums + 'dict/delete', // 删除字典
    putDicInfo: GLOBAL_CONFIG.uums + 'dict/publishDictToApp', // 同步字典
    getRoleList: GLOBAL_CONFIG.uums + 'role/list', // 获取角色列表
    saveHoliday: GLOBAL_CONFIG.uums + 'holiday/save', // 新增节假日
    getByLoginName: GLOBAL_CONFIG.uums + 'user/getByLoginName', // 获取已分配角色类表
    updateUserRole: GLOBAL_CONFIG.uums + 'user/updateUserRole', // 修改用户角色
    holidayList: GLOBAL_CONFIG.uums + 'holiday/list', // 节假日列表
    deleteHoliday: GLOBAL_CONFIG.uums + 'holiday/delete', //删除节假日
    queryDiction: GLOBAL_CONFIG.uums + 'permission/tree', //查询权限
    saveDiction: GLOBAL_CONFIG.uums + 'permission/save', //保存权限
    removeDiction: GLOBAL_CONFIG.uums + 'permission/delete', //删除权限
    organization: GLOBAL_CONFIG.uums + 'organization/tree',
  },
  //用户
  agencyUser: {
    findPublic: GLOBAL_CONFIG.internet + 'userInfoController/findUserinfo', //查询个人用户
    findInstitutionalUser: GLOBAL_CONFIG.internet + 'userInfoController/findInstitutionalUser', //获取企业用户
    findUser: GLOBAL_CONFIG.internet + 'userInfoController/findUser', //通过loginme 获取信息
    deleteUserinfoByLoginname: GLOBAL_CONFIG.internet + 'userInfoController/deleteUserinfoByLoginname', // 通过loginName删除用户
    getBlackReasonByName: GLOBAL_CONFIG.internet + 'userInfoController/getBlackReasonByName',
    getBlackRecordsByName: GLOBAL_CONFIG.internet + 'userInfoController/getBlackRecordsByName', //根据用户名获取黑名单
    deleteInstitutionalUser: GLOBAL_CONFIG.internet + 'userInfoController/deleteInstitutionalUser', //删除企业用户
    addToBlackList: GLOBAL_CONFIG.internet + 'userInfoController/addToBlackList', // 用户加入黑名单操作
    removeFromBlackList: GLOBAL_CONFIG.internet + 'userInfoController/removeFromBlackList', // 用户移除黑名单操作
    saveCompany: GLOBAL_CONFIG.internet + 'userInfoController/updateInstitutionalUser', //保存修改后企业用户信息
  },
  bind: {
    getFlowDefConfig: GLOBAL_CONFIG.internet + 'flowDefController/getManagerFlowDefConfig', //查询流程定义分类列表
    getThirdFlowByDjjg: GLOBAL_CONFIG.internet + 'flowDefController/getThirdFlowByDjjg', //根据二级流程定义id和流程类型查询三级流程，不分页
    bindYxxThirdConfig: GLOBAL_CONFIG.internet + 'api/appointment/bindYxxThirdConfig', //获取第三级
  },
  // 流程定义配置
  apply: {
    addRootFlowDef: GLOBAL_CONFIG.internet + 'flowDefController/addRootFlowDef', // 添加一级定义
    addSubLevelFlowDef: GLOBAL_CONFIG.internet + 'flowDefController/addSubLevelFlowDef', // 添加二级定义
    addSubFlowConfig: GLOBAL_CONFIG.internet + 'api/process/addSubFlowConfig', // 添加三级定义
    modifySubFlowConfig: GLOBAL_CONFIG.internet + 'api/process/modifySubFlowConfig', // 修改三级定义
    getSubFlowConfigInfo: GLOBAL_CONFIG.internet + 'api/process/getSubFlowConfigInfo', // 获取二级定义配置
    copyFlowSubConfig: GLOBAL_CONFIG.internet + 'flowDefController/copyFlowSubConfig', // copy子级流程到新的登记机构
    updatFlowDefConfig: GLOBAL_CONFIG.internet + 'flowDefController/updatFlowDefConfig', // 编辑二级流程
    getByOptions: GLOBAL_CONFIG.internet + 'uploadfileConfigController/getByOptions', // 条件查询材料分类配置信息列表
    addFlowGuidConfig: GLOBAL_CONFIG.internet + 'api/process/addFlowGuidConfig', // 申请流程指南配置
    uploadFileConfigByCode: GLOBAL_CONFIG.internet + 'flowDefController/uploadFileConfigByCode', // 三级流程配置收件材料信息
    updateUploadFileConfigById: GLOBAL_CONFIG.internet + 'flowDefController/updateUploadFileConfigById', // 修改三级流程已绑定的收件材料基本信息
    deleteUploadFileConfigById: GLOBAL_CONFIG.internet + 'flowDefController/deleteUploadFileConfigById', // 根据id删除三级流程已绑定的收件材料基本信息
    delSubLevelFlowDef: GLOBAL_CONFIG.internet + 'flowDefController/delSubLevelFlowDef', // 删除二级目录
    deleteFlowDefConfig: GLOBAL_CONFIG.internet + 'flowDefController/deleteFlowDefConfig', // 删除一级目录
    deleteSubFlowConfig: GLOBAL_CONFIG.internet + 'api/process/deleteSubFlowConfig', // 根据流程id删除三级流程信息
  },
  //申请，预约流程配置
  define: {
    getList: GLOBAL_CONFIG.internet + 'flowDefController/getFlowDefConfig', //获取列表
    getSubFlowDefConfig: GLOBAL_CONFIG.internet + 'api/process/getSubFlowList', //获取第三级
    getYyFlowDefList: GLOBAL_CONFIG.internet + 'api/appointment/getYyxxConfigByDjjg', //获取预约级别列表
    getThirdConfigById: GLOBAL_CONFIG.internet + 'api/appointment/getThirdConfigById', //根据二级流程id获取预约三级流程
    yyxxConfig: GLOBAL_CONFIG.internet + 'api/appointment/yyxxConfig', //添加和修改预约一级到第二级
    deleteYyConfigById: GLOBAL_CONFIG.internet + 'api/appointment/deleteYyConfigById', //根据id删除一级和二级流程
    deleteThirdYyConfigById: GLOBAL_CONFIG.internet + 'api/appointment/deleteThirdYyConfigById', //根据id删除一级和二级流程
    customizeYyxxThirdConfig: GLOBAL_CONFIG.internet + 'api/appointment/customizeYyxxThirdConfig', //自定义预约第三级
    getThirdConfigInfoById: GLOBAL_CONFIG.internet + 'api/appointment/getThirdConfigInfoById', //根据第三级id找到第三级的配置的详细信息（包括指南
    addNewFlowGuidConfig: GLOBAL_CONFIG.internet + 'api/appointment/addNewFlowGuidConfig', //预约自定义添加办事指南
    findByDjjg: GLOBAL_CONFIG.internet + 'api/workOfficeController/findByDjjg', //通过登记机构查询办事大厅列表
  },
  // 邮寄管理
  express: {
    getPageList: GLOBAL_CONFIG.internet + 'api/express/getPageList', // 条件查询快递信息列表
    saveExpressInfo: GLOBAL_CONFIG.internet + 'api/express/saveExpressInfo', // 保存快递订单信息
    findByOfficeCode: GLOBAL_CONFIG.internet + 'workOfficeController/findByOfficeCode', //通过办事大厅代码查询办事大厅
  },
  //集体件查询
  groupBook: {
    query: GLOBAL_CONFIG.internet + 'checkCertifyController/getJtjcxByQuery', //集体件预约查询
    exportData: GLOBAL_CONFIG.internet + 'checkCertifyController/exportExcell', //导出集体件查询
    getDetailsByCxbh: GLOBAL_CONFIG.internet + 'checkCertifyController/getDetailsByCxbh', //根据查询编号查详情
    changeStatus: GLOBAL_CONFIG.internet + 'checkCertifyController/changeStatusByCxbh', //改变集体房查状态
    fileDetail: GLOBAL_CONFIG.internet + 'fileUploadController/viewUploadFile', //下载文件
  },
  //日志查询
  log: {
    querySystem: GLOBAL_CONFIG.uums + 'app/list', //子系统列表
    query: GLOBAL_CONFIG.uums + 'log/list', //操作日志列表
    exportlog: GLOBAL_CONFIG.uums + 'log/export', //导出日志列表
  },
  //楼盘表
  property: {
    query: GLOBAL_CONFIG.internet + 'buildingController/getDcbZs', //查询栋列表
    queryHuList: GLOBAL_CONFIG.internet + 'buildingController/getDcbHsByLjzh', //查询户列表
    getDcbHById: GLOBAL_CONFIG.internet + 'buildingController/getDcbHById', //根据ID获取户信息
  },
  //权属查询
  ownership: {
    query: GLOBAL_CONFIG.internet + 'checkCertifyController/getCheckCertifiesByQuery', //权属证明查询
  },
  //登记申请
  registerApply: {
    query: GLOBAL_CONFIG.internet + 'registerController/getPassedApplies', //登记申请数据列表
    exportData: GLOBAL_CONFIG.internet + 'api/registerflow/getPassAppliesZipExport', //导出
  },
  //办事大厅
  hall: {
    query: GLOBAL_CONFIG.internet + 'workOfficeController/getWorkOffice', //查询办事大厅列表
    queryById: GLOBAL_CONFIG.internet + 'workOfficeController/getWorkOfficeById', //通过办事大厅id查询办事大厅列表
    addHall: GLOBAL_CONFIG.internet + 'workOfficeController/saveOrUpdateWorkOffice', //增加办事大厅
    deleteHall: GLOBAL_CONFIG.internet + 'workOfficeController/deleteWorkOffice', //删除办事大厅
    getTimeList: GLOBAL_CONFIG.internet + 'workOfficeController/getSjdByOfficeCode', //根据办事大厅获取时间段列表
    addTimeList: GLOBAL_CONFIG.internet + 'workOfficeController/addWorkOfficeSjd', //保存时间段
    deleteTimeList: GLOBAL_CONFIG.internet + 'workOfficeController/deleteSjdById', //根据时间段id删除时间段
    windowConfig: GLOBAL_CONFIG.internet + 'api/workOffice/windowConfig', //增加窗口
    deleteWindow: GLOBAL_CONFIG.internet + 'api/workOffice/deleteWindow', //删除窗口管理数据
    configSubFlowList: GLOBAL_CONFIG.internet + 'api/workOffice/configSubFlowList', //绑定流程
    updateWorkOfficeYyconfig: GLOBAL_CONFIG.internet + 'workOfficeController/updateWorkOfficeYyconfig', //在大厅根据预约事项设置每个预约大类对应的数量
    waitTimeConfig: GLOBAL_CONFIG.internet + 'api/workOffice/waitTimeConfig', //更新内外网数据推送时间
    getSystemDic: GLOBAL_CONFIG.internet + 'uumsController/getDictTree',
  },
  //注册审核
  approve: {
    query: GLOBAL_CONFIG.internet + 'userInfoController/findCheckingIntitutionalUser', //查询审核中的企业用户
    showDetail: GLOBAL_CONFIG.internet + 'userRegister/getById',
    approveUser: GLOBAL_CONFIG.internet + 'userInfoController/checkInstitutionalUser',
    doorapprove: GLOBAL_CONFIG.internet + 'api/doorToDoor/listByOptions', // 上门预约审核待审核
    lookThrough: GLOBAL_CONFIG.internet + 'api/doorToDoor/lookThrough', //上门预约预审
    doorfinish: GLOBAL_CONFIG.internet + 'api/doorToDoor/finish', //履约
    doorPrintDTD: GLOBAL_CONFIG.internet + 'estatePrintController/printDTD', //上门预约审核打印
    appointmentnumber: GLOBAL_CONFIG.internet + 'userOperatorController/getBackstageDisplay', // 预约排号审核未受理
    exportAppointmentInfo: GLOBAL_CONFIG.internet + 'api/appointment/exportAppointmentInfo', // 预约排号审核导出
    detailsById: GLOBAL_CONFIG.internet + 'api/doorToDoor/detailsById', //
    findFileById: GLOBAL_CONFIG.fms + 'file/findFileById',
    getAppointmentInfoByCode: GLOBAL_CONFIG.internet + 'api/appointment/getAppointmentInfoByCode',
    setFileIds: GLOBAL_CONFIG.internet + 'userInfoController/setFileIds', //企业用户保存证明材料
    getSystemTime: GLOBAL_CONFIG.internet + 'uumsController/getSystemTime', //获取系统时间
    changeStatus: GLOBAL_CONFIG.internet + 'api/appointment/changeStatus', //修改受理状态
  },
  //机构
  organizations: {
    getOrgsByAreaId: GLOBAL_CONFIG.uums + 'organization/getOrgsByAreaId',
  },
  //区县
  area: {
    getTree: GLOBAL_CONFIG.uums + 'area/tree',
  },
  //app管理
  appmanagement: {
    appPageList: GLOBAL_CONFIG.internet + 'fileUploadController/appPageList', //分页获取app管理列表
    appTreeAll: GLOBAL_CONFIG.internet + 'api/appMenu/treeAll', //获取app管理树
    matchAppMenu: GLOBAL_CONFIG.internet + 'api/appMenu/matchAppMenu', //获取选中的id
    appDelete: GLOBAL_CONFIG.internet + 'api/appMenu/deleteById', //删除app菜单
    appAdd: GLOBAL_CONFIG.internet + 'api/appMenu/add', //新增app菜单
    appupdate: GLOBAL_CONFIG.internet + 'api/appMenu/updateById', //修改app菜单
    appSav: GLOBAL_CONFIG.internet + 'fileUploadController/appSaveOrUpdate', //添加app版本
    getAppPicture: GLOBAL_CONFIG.internet + 'fileUploadController/getAppPicture', //获取轮播管理列表
    saveAppPicture: GLOBAL_CONFIG.internet + 'fileUploadController/saveAppPicture', //保存轮播图
    deleteAppPicture: GLOBAL_CONFIG.internet + 'fileUploadController/deleteAppPicture', //删除轮播图
    bingding: GLOBAL_CONFIG.internet + 'api/appMenu/bingding', //绑定三级
    unBingding: GLOBAL_CONFIG.internet + 'api/appMenu/unBingding', //三级流程解绑APP菜单
  },
  //留言
  messageReplay: {
    messageNote: GLOBAL_CONFIG.internet + 'userOperatorController/getGuestNoteByCoupleQuery', //获取留言回复列表
    deleteMessage: GLOBAL_CONFIG.internet + 'userOperatorController/deleteGuestNote', //删除留言
    exportMessage: GLOBAL_CONFIG.internet + 'userOperatorController/exportDocNotes', //导出留言
    saveMessage: GLOBAL_CONFIG.internet + 'userOperatorController/saveGuestNote', //保存、回复用户留言
    singleQuery: GLOBAL_CONFIG.internet + 'userOperatorController/getGuestNoteBySingleQuery', //根据条件获取用户留言
    coupleQuery: GLOBAL_CONFIG.internet + 'userOperatorController/getGuestNoteByCoupleQuery', //根据多个条件获取用户留言
  },
  //文章发布
  articleRelease: {
    wzlxArticle: GLOBAL_CONFIG.internet + 'articleController/getAllWzlx', //查询所有的文章类型
    allArticle: GLOBAL_CONFIG.internet + 'articleController/getAllArticleByQuery', //获取所有的文章列表
    deleteArticle: GLOBAL_CONFIG.internet + 'articleController/modifyArticle', //删除文章(修改文章，文章内容，是否可见还有删除)
    viewArticle: GLOBAL_CONFIG.internet + 'articleController/viewArticle', //查看文章
    inserArticle: GLOBAL_CONFIG.internet + 'articleController/insertArticle', //添加文章
  },
  // 个人首页
  dashboardService: {
    getAppointmentCountTotal: GLOBAL_CONFIG.internet + 'api/statistics/getAppointmentCountTotal', //查询 今日线上预约
    getApplyCountTotal: GLOBAL_CONFIG.internet + 'api/statistics/getApplyCountTotal', //查询 今日登记申请
    getVerificationCountByPeriodTime: GLOBAL_CONFIG.internet + 'api/statistics/getVerificationCountByPeriodTime', //查询 某段时间 证书核验
    getCertificateCountByPeriodTime: GLOBAL_CONFIG.internet + 'api/statistics/getCertificateCountByPeriodTime', //查询 某段时间 证明申请
    getAppointmentCountGroupByWorkOffice:
      GLOBAL_CONFIG.internet + 'api/statistics/getAppointmentCountGroupByWorkOffice', //查询 某段时间 线上预约
    getApplyCountGroupByWorkOffice: GLOBAL_CONFIG.internet + 'api/statistics/getApplyCountGroupByWorkOffice', //查询 某段时间 登记申请
    calAppointmentEvaluateByAreaAndStars:
      GLOBAL_CONFIG.internet + 'api/statistics/calAppointmentEvaluateByAreaAndStars', //查询 预约评价统计
    calApplyEvaluateByAreaAndStars: GLOBAL_CONFIG.internet + 'api/statistics/calApplyEvaluateByAreaAndStars', //查询 申请评价统计
    getTrafficVolumeByArea: GLOBAL_CONFIG.internet + 'api/statistics/getTrafficVolumeByArea', //查询 累计建档人数排行
    getAppointmentSortList: GLOBAL_CONFIG.internet + 'api/appointment/getAppointmentSortList', //线上大厅预约排名
    getTimeSuervision: GLOBAL_CONFIG.internet + 'api/statistics/getTimeSuervision', //时效监管
  },
  //评价统计
  statistics: {
    staticsByStarsByAll: GLOBAL_CONFIG.internet + 'api/statistics/calEvaluateByAreaAndStars', //统计各时间段评价的
    findEvaluatePageList: GLOBAL_CONFIG.internet + 'evaluateController/findEvaluatePageList', //条件查询评价信息列表（分页）
  },

  //文件服务
  files: {
    mulFileSave: GLOBAL_CONFIG.fms + 'file/uploadFiles', //文件上传
    getFileList: GLOBAL_CONFIG.internet + 'flowDefController/getUploadFilesBySubcfgCode', //获取配置的文件列表
    getUploadFile: GLOBAL_CONFIG.internet + 'fileUploadController/getUploadFiles', //获取已上传文件
    viewUploadFile: GLOBAL_CONFIG.internet + 'fileUploadController/viewUploadFile', //下载文件
  },
  //预审服务
  business: {
    queryYsList: GLOBAL_CONFIG.internet + 'registerController/getPreliminaries', //获取预审列表
    printApply: GLOBAL_CONFIG.internet + 'estatePrintController/printApply', // 打印申请表
    estatePrintApply: GLOBAL_CONFIG.internet + 'estatePrintController/estatePrintApply', // 打印受理凭证
    getProcessInfo: GLOBAL_CONFIG.internet + 'api/registerflow/getProcessInfo', //根据id获取申请详情
    getRightHolder: GLOBAL_CONFIG.internet + 'api/registerflow/getRightHolder', //获取权利人详情
    getObligor: GLOBAL_CONFIG.internet + 'api/registerflow/getObligor', //获取义务人详情
    getApplyZipExport: GLOBAL_CONFIG.internet + 'api/registerflow/getApplyZipExport', // 单个导出
    checkApply: GLOBAL_CONFIG.internet + 'registerController/checkApply', //预审
    setDelay: GLOBAL_CONFIG.internet + 'api/registerflow/setDelay', //备注
  },
  // 邮寄相关
  ems: {
    createOrder: GLOBAL_CONFIG.internet + 'ems/create_order', //下单
    pickUp: GLOBAL_CONFIG.internet + 'ems/pick_up', // 上门揽件
    getOrders: GLOBAL_CONFIG.internet + 'ems/getOrdersByUserId', //邮寄列表
    getEmsResult: GLOBAL_CONFIG.internet + 'ems/result_query', //获取结果
    cancelOrder: GLOBAL_CONFIG.internet + 'ems/cancle_order', //取消订单
    cancelPickUp: GLOBAL_CONFIG.internet + 'ems/cancle_pick_up', //取消上门揽件
    changeTime: GLOBAL_CONFIG.internet + 'ems/change_appointment_time', //修改预约时间
    getTrace: GLOBAL_CONFIG.internet + 'ems/jdpt_trace', //获取轨迹
    saveOrderInfo: GLOBAL_CONFIG.internet + 'ems/saveOrderInfo', //保存参数
    querySaveOrderInfo: GLOBAL_CONFIG.internet + 'ems/querySaveOrderInfo', //获取保存的参数
    createOrderBySaveInfoId: GLOBAL_CONFIG.internet + 'ems/createOrderBySaveInfoId', // 证书邮寄下单
    getOrdersById: GLOBAL_CONFIG.internet + 'ems/getOrdersById', // 获取指定邮寄业务信息
    iwaybillBatch: GLOBAL_CONFIG.internet + 'ems/iwaybillBatch', // 批量取号接口
  },
  //材料配置服务
  fileDefinition: {
    queryList: GLOBAL_CONFIG.internet + 'uploadfileConfigController/getByOptions', //获取材料配置列表
    deleteFilesById: GLOBAL_CONFIG.internet + 'uploadfileConfigController/deleteFilesById', //删除材料分类
    saveFileUploadConfig: GLOBAL_CONFIG.internet + 'uploadfileConfigController/saveFileUploadConfig', //添加/修改材料分类配置
  },
  //场景
  scene: {
    sceneAdd: GLOBAL_CONFIG.internet + 'api/drools/sceneAdd', //添加场景
    sceneUpdateById: GLOBAL_CONFIG.internet + 'api/drools/sceneUpdateById', //根据id修改场景
    sceneDeleteById: GLOBAL_CONFIG.internet + 'api/drools/sceneDeleteById', //根据id删除场景
    sceneGetByPage: GLOBAL_CONFIG.internet + 'api/drools/sceneGetByPage', //分页查询场景
    sceneBindRuleList: GLOBAL_CONFIG.internet + 'api/drools/sceneBindRuleList', //批量绑定规则的功能
    sceneBindRuleByPage: GLOBAL_CONFIG.internet + 'api/drools/sceneBindRuleByPage', //场景中显示已绑定规则和未绑定规则分页的功能
    sceneUnBindRuleList: GLOBAL_CONFIG.internet + 'api/drools/sceneUnBindRuleList', //批量取消绑定的功能
    sceneCheckDrl: GLOBAL_CONFIG.internet + 'api/drools/sceneCheckDrl', //通过场景id校验场景的语句
  },
  // 规则
  rule: {
    addRule: GLOBAL_CONFIG.internet + 'api/drools/addRule', // 添加规则
    deleteRuleById: GLOBAL_CONFIG.internet + 'api/drools/deleteRuleById', // 删除规则
    getRulePage: GLOBAL_CONFIG.internet + 'api/drools/getRulePage', // 获取规则分页列表
    updateRuleById: GLOBAL_CONFIG.internet + 'api/drools/updateRuleById', //根据id修改规则
  },
  // 智能审批结果
  znsp: {
    droolsCheckGetByYwh: GLOBAL_CONFIG.internet + 'api/drools/droolsCheckGetByYwh', // 根据业务号获取审批记录的结果
  },
  // 楼盘申请
  applybuild: {
    getPageList: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyCheckByPage', // 条件查询
    getApplyById: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyGetById', // 详情查看
    addApply: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyByKfs', // 新增申请
    applyCheck: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyCheck', // 审核
    delApply: GLOBAL_CONFIG.internet + 'userInfoController/zddmApplyDeleteById', // 删除
  },
  //业务统计
  serviceStatistics: {
    registerTypeStatistic: GLOBAL_CONFIG.internet + 'api/statisticsNew/getQlxxCountByYwlxNew', //登记类型业务统计 表
    getQlxxCountForYh: GLOBAL_CONFIG.internet + 'api/statisticsNew/getQlxxCountForYh', //登记类型业务统计 表
    registerTypeStatisticTree: GLOBAL_CONFIG.UUMS_SERVER + 'area/tree', //登记类型业务统计 树表
    pushState: GLOBAL_CONFIG.internet + 'api/statisticsNew/getQlxxPushStatusCount', //推送状态 业务统计 表
    pushStateTree: GLOBAL_CONFIG.UUMS_SERVER + 'organization/tree', //推送状态 业务统计 树表
    servicesState: GLOBAL_CONFIG.internet + 'api/statisticsNew/getQlxxStepCount', //业务状态 业务统计
    servicesStateTree: GLOBAL_CONFIG.UUMS_SERVER + 'organization/tree', //业务状态 业务统计 树表
    getKstbQlxxCountByYwlx: GLOBAL_CONFIG.internet + 'api/statisticsNew/getKstbQlxxCountByYwlx',
    registerTypeStatisticwlmqs: GLOBAL_CONFIG.internetwlmqs + 'api/statisticsNew/getQlxxCountByYwlxNew', //登记类型业务统计 表
    pushStatewlmqs: GLOBAL_CONFIG.internetwlmqs + 'api/statisticsNew/getQlxxPushStatusCount', //推送状态 业务统计 表
    servicesStatewlmqs: GLOBAL_CONFIG.internetwlmqs + 'api/statisticsNew/getQlxxStepCount', //业务状态 业务统计
    getKstbQlxxCountByYwlxwlmqs: GLOBAL_CONFIG.internetwlmqs + 'api/statisticsNew/getKstbQlxxCountByYwlx',
    getKstbQlxxCountBySqrlx: GLOBAL_CONFIG.internet + 'api/statisticsNew/getKstbQlxxCountBySqrlx', // 按申请人类型统计
  },
  //非税网银
  payVerify: {
    selectPayApplyPageList: GLOBAL_CONFIG.internet + 'payMutualController/selectPayApplyPageList', //登记类型业务统计 表
    selectByParams: GLOBAL_CONFIG.internet + 'paymentAreaController/selectByParams', //登记类型业务统计 表
    payApply: GLOBAL_CONFIG.internet + 'payMutualController/payApply', //发起缴费申请
    savePayApply: GLOBAL_CONFIG.internet + 'payMutualController/savePayApply', //发起缴费申请
    checkApply: GLOBAL_CONFIG.internet + 'payMutualController/checkApply', //发起缴费申请
    saveDuplicatePayApply: GLOBAL_CONFIG.internet + 'payMutualController/saveDuplicatePayApply', //发起多个缴费申请
  },
  //房查审核
  realEstateQuery: {
    getUncheckedQszmList: GLOBAL_CONFIG.internet + 'realEstateQueryController/getUncheckedQszmList', //房查待审核列表
    checkQszm: GLOBAL_CONFIG.internet + 'realEstateQueryController/checkQszm', //房查审核
    printQszm: GLOBAL_CONFIG.internet + 'estatePrintController/printQszm', // 打印权属证明
    printQszmWithSignature: GLOBAL_CONFIG.internet + 'estatePrintController/printQszmWithSignature', //打印权属证明带签章
    needSign: GLOBAL_CONFIG.internet + 'realEstateQueryController/needSign', //修改是否签章接口
  },
  ca: {
    addInstitutionalUser: GLOBAL_CONFIG.internet + 'ca/addInstitutionalUser',
    findAgentsByCompanyId: GLOBAL_CONFIG.internet + 'ca/findAgentsByCompanyId',
    agentUserBindCompany: GLOBAL_CONFIG.internet + 'ca/agentUserBindCompany',
    delAgent: GLOBAL_CONFIG.internet + 'ca/delAgent',
  },
  yx: {
    pushTaxFinish: GLOBAL_CONFIG.internet + 'api/taxService/pushTaxFinish', //税务主动推送完税信息至一窗平台
    getTaxInfoList: GLOBAL_CONFIG.internet + 'api/taxService/getTaxInfoList', //完税列表
    queryTaxFinish: GLOBAL_CONFIG.internet + 'api/taxService/queryTaxFinish', //查询税务完税信息
    quryJkxxOrBhxx: GLOBAL_CONFIG.internet + 'integration/api/quryJkxxOrBhxx', //查询存量房税务信息
  },
};
