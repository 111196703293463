export const sameSqr = [
  {
    name: '所有权变更登记+所有权转移登记',
    code: 'H999003',
    same: {
      form0: '0', //表单1的权利人 category 0
      form1: '1', //变淡2的义务人 category 1
    },
  },
  {
    name: '预告登记+抵押预告登记',
    code: 'H999001',
    same: {
      form0: '7', //表单1的权利人 category 7
      form1: '10', //变淡2的义务人 category 10
    },
  },
  {
    name: '所有权转移登记+抵押权首次登记',
    code: 'H999002',
    same: {
      form0: '0', //表单1的权利人 category 0
      form1: '5', //变淡2的义务人 category 5
    },
  },
  {
    name: '组合贷',
    code: 'H100311',
    same: {
      form0: '5', //表单1的权利人 category 0
      form1: '5', //表单2的义务人 category 5
    },
  },
];
