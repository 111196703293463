<template>
  <el-dialog
    :visible.sync="visible"
    center
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :before-close="close"
    width="70%"
  >
    <!-- 标题 -->
    <div slot="title" class="header-title text-left">
      <span>申请人信息</span>
      <el-button v-show="!disabled" type="primary" plain size="small" @click="getUserInfo">获取当前用户信息</el-button>
      <span class="redPoint" v-show="!disabled">温馨小提示：点击左边按钮可直接获取您的用户信息</span>
    </div>
    <!-- 表单区域 -->
    <dynamic-form-old v-if="formvisible" ref="form" :form-config="formConfig" :formModel="sqrData" :disabled="disabled">
      <template slot-scope="props">
        <div class="idcardSS" v-if="$hasPermission('IEBDC:SSYTJ') && props.title !== '代理人信息'">
          <el-button class="read-id-card-button" type="primary" :disabled="disabled" @click="getIdCardInfo"
            >读取身份证信息</el-button
          >
          <div class="lc-name">
            <span
              >所选一体机编号为:
              {{ chooseDeviceInfo.deviceBh ? chooseDeviceInfo.deviceBh : chooseDeviceInfo.deviceIp }}</span
            >
          </div>
          <div class="rz_img">
            <img class="rz_img_id" :src="sqrData.idImage" style="width: 164px" />
            <img class="rz_img_face" :src="sqrData.faceImage" style="width: 164px" />
            <div class="re_img_gayz" v-show="sqrData.gayz">
              <img :src="sqrData.gayz_img" />
              <span>{{ sqrData.gayz }}</span>
            </div>
          </div>
        </div>
      </template>
    </dynamic-form-old>
    <!-- 底部操作按钮 -->
    <span slot="footer" v-show="this.receiveParams.buttonService && !disabled">
      <el-button @click="submit" size="small" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { localDataUser } from '@iebdc/utils/local-data';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import dlrService from '@/pages/iebdc/api/apply/applicant-dlr';
import sqrService from '@/pages/iebdc/api/apply/applicant-sqr';
import { getInfoByDeviceBh } from '@iebdc/api/user-center/device.js';
import { getDeviceList } from '@iebdc/api/user-center/device.js';
export default {
  props: {
    formConfig: Array,
    disabled: Boolean,
  },
  data() {
    return {
      // 弹窗状态
      visible: false,
      //表单显示
      formvisible: false,
      // 当前弹窗操作类型(保存、编辑)
      currentOperateType: '',
      //表单数据
      sqrData: {},
      chooseDeviceInfo: {},
      //传入的数据
      receiveParams: {},
      user: localDataUser.get(),
    };
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
  },
  methods: {
    getDevice() {
      getDeviceList({ deviceUser: this.user.id }).then((res) => {
        if (res.success && res.data) {
          res.data.forEach((item, index) => {
            if (item.defaultStatus === 0) {
              this.$set(this.chooseDeviceInfo, 'deviceBh', res.data[index].deviceBh);
              this.$set(this.chooseDeviceInfo, 'deviceIp', res.data[index].deviceIp);
              this.$set(this.chooseDeviceInfo, 'deviceId', res.data[index].id);
              localStorage.setItem('chooseDeviceInfo', JSON.stringify(this.chooseDeviceInfo));
            }
          });
        }
      });
    },
    //初始化申请数据
    initSqrData(params) {
      this.sqrData = {
        bdcdyhs: [],
        category: params.category,
        gyfs: params.rowData && params.rowData.gyfs ? params.rowData.gyfs : '0',
        sqrType: params.sqrType,
        idImage: require('@iebdc/assets/images/qszm/idimg.png'),
        faceImage: require('@iebdc/assets/images/qszm/faceimg.png'),
        houseNumber: params.rowData && params.rowData.familyGroup ? params.rowData.familyGroup.houseNumber : '',
      };
      // todo 默认设置是否持证人数据
      let need = ['4', '9'];
      if (need.includes(params.category)) {
        this.sqrData.sfczr = 1;
      }
      // 有代表人情况
      if (params.rowData) {
        dlrService.setWhetherDlr(params.rowData);
      }
      if (params.rowData.idImage) {
        params.rowData.idImage = params.rowData.idImage.includes('http')
          ? params.rowData.idImage
          : this.sqrData.idImage;
      } else {
        params.rowData.idImage = this.sqrData.idImage;
      }
      if (params.rowData.faceImage) {
        params.rowData.faceImage = params.rowData.faceImage.includes('http')
          ? params.rowData.faceImage
          : this.sqrData.faceImage;
      } else {
        params.rowData.idImage = this.sqrData.idImage;
      }
      this.sqrData = Object.assign(this.sqrData, params.rowData);
    },
    getSqrInfo(params) {
      if (params.rowData.id) {
        this.openDialog(params);
        // applicantOperatorService[params.searchService]({ id: params.rowData.id }).then((res) => {
        //   if (res.success && res.data) {
        //     params.rowData = Object.assign(params.rowData, res.data);
        //     this.openDialog(params);
        //   } else {
        //     this.$message.error(res.message);
        //   }
        // });
      } else {
        this.openDialog(params);
      }
    },
    openDialog(data) {
      // console.log(data, 'receiveParams');
      //当前传入参数数据
      this.receiveParams = data;
      //没有就初始化数据
      this.initSqrData(data);
      //显示弹框
      this.visible = true;
      this.formvisible = true;
      this.$nextTick(() => {
        // 清空表单验证
        this.$refs.form.resetFields();
      });
    },
    // 打开弹窗
    open(params) {
      console.log(params, 'params');
      if (!JSON.parse(localStorage.getItem('chooseDeviceInfo'))) {
        //缓存中没有值的时候执行接口获取之前选择的设备
        this.getDevice();
      } else {
        //有值直接获取存储中的值
        this.chooseDeviceInfo = JSON.parse(localStorage.getItem('chooseDeviceInfo'));
      }
      this.getSqrInfo(params);
    },
    // 接口提交成功
    success(msg) {
      this.$emit('refresh');
      this.visible = false;
      this.formvisible = false;
      this.$message.success(msg);
    },
    //获取当前用户
    getUserInfo() {
      let sqr = sqrService.getUserInfo();
      if (this.sqrData.gyfs) {
        sqr.gyfs = this.sqrData.gyfs;
      }
      if (this.user.hasOwnProperty('jbrData')) {
        this.sqrData.whetherDlr = '1';
        this.sqrData.dlrlx = '0';
        this.sqrData.dlrdh = this.user.jbrData.lxdh;
        this.sqrData.dlrzjh = this.user.jbrData.sfzh;
        this.sqrData.dlrmc = this.user.jbrData.name;
        this.sqrData.dlrzjzl = this.user.jbrData.sfzhzl;
      }
      this.sqrData = Object.assign(this.sqrData, sqr);
      this.$refs.form.setData(this.sqrData);
    },
    // 新增修改权利人
    submit() {
      const otherParams = {
        ywh: this.flowInfoIng.ywh, // 业务号
        category: this.receiveParams.category, // 人的数据分类
      };
      let params = Object.assign(this.$refs.form.getData(), otherParams);
      if (params.houseNumber || params.houseNumber === 0) {
        params.familyGroup = params.familyGroup || {};
        params.familyGroup.houseNumber = params.houseNumber;
        params.familyGroup.familyMemberList = [];
      }
      for (var k in params) {
        if (params[k] === null) {
          delete params[k];
        }
      }
      this.$refs.form.$refs.form.validate((valid) => {
        if (valid) {
          let judgeParams = this.getParmas(params);
          if (judgeParams.ywrCategory) {
            delete judgeParams.ywrCategory;
          } else {
            delete judgeParams.qlrCategory;
          }
          // let copyParams = judgeParams;
          // if (copyParams.ywr) {
          //   copyParams.ywr.dh = copyParams.ywr.dh ? this.$encryptByDES(copyParams.ywr.dh) : '';
          //   copyParams.ywr.ywrmc = copyParams.ywr.ywrmc ? this.$encryptByDES(copyParams.ywr.ywrmc) : '';
          //   copyParams.ywr.dljg = copyParams.ywr.dljg ? this.$encryptByDES(copyParams.ywr.dljg) : '';
          //   copyParams.ywr.dlrmc = copyParams.ywr.dlrmc ? this.$encryptByDES(copyParams.ywr.dlrmc) : '';
          //   copyParams.ywr.dlrdh = copyParams.ywr.dlrdh ? this.$encryptByDES(copyParams.ywr.dlrdh) : '';
          //   copyParams.ywr.dlrzjh = copyParams.ywr.dlrzjh ? this.$encryptByDES(copyParams.ywr.dlrzjh) : '';
          //   copyParams.ywr.dljgdh = copyParams.ywr.dljgdh ? this.$encryptByDES(copyParams.ywr.dljgdh) : '';
          //   copyParams.ywr.frmc = copyParams.ywr.frmc ? this.$encryptByDES(copyParams.ywr.frmc) : '';
          //   copyParams.ywr.frdh = copyParams.ywr.frdh ? this.$encryptByDES(copyParams.ywr.frdh) : '';
          //   copyParams.ywr.frzjh = copyParams.ywr.frzjh ? this.$encryptByDES(copyParams.ywr.frzjh) : '';
          //   copyParams.ywr.qlrmc = copyParams.ywr.qlrmc ? this.$encryptByDES(copyParams.ywr.qlrmc) : '';
          //   copyParams.ywr.zjh = copyParams.ywr.zjh ? this.$encryptByDES(copyParams.ywr.zjh) : '';
          // } else if (copyParams.qlr) {
          //   copyParams.qlr.dh = copyParams.qlr.dh ? this.$encryptByDES(copyParams.qlr.dh) : '';
          //   copyParams.qlr.ywrmc = copyParams.qlr.ywrmc ? this.$encryptByDES(copyParams.qlr.ywrmc) : '';
          //   copyParams.qlr.zjh = copyParams.qlr.zjh ? this.$encryptByDES(copyParams.qlr.zjh) : '';
          //   copyParams.qlr.qlrmc = copyParams.qlr.qlrmc ? this.$encryptByDES(copyParams.qlr.qlrmc) : '';
          //   copyParams.qlr.dljg = copyParams.qlr.dljg ? this.$encryptByDES(copyParams.qlr.dljg) : '';
          //   copyParams.qlr.dlrmc = copyParams.qlr.dlrmc ? this.$encryptByDES(copyParams.qlr.dlrmc) : '';
          //   copyParams.qlr.dlrdh = copyParams.qlr.dlrdh ? this.$encryptByDES(copyParams.qlr.dlrdh) : '';
          //   copyParams.qlr.dlrzjh = copyParams.qlr.dlrzjh ? this.$encryptByDES(copyParams.qlr.dlrzjh) : '';
          //   copyParams.qlr.dljgdh = copyParams.qlr.dljgdh ? this.$encryptByDES(copyParams.qlr.dljgdh) : '';
          //   copyParams.qlr.frmc = copyParams.qlr.frmc ? this.$encryptByDES(copyParams.qlr.frmc) : '';
          //   copyParams.qlr.frdh = copyParams.qlr.frdh ? this.$encryptByDES(copyParams.qlr.frdh) : '';
          //   copyParams.qlr.frzjh = copyParams.qlr.frzjh ? this.$encryptByDES(copyParams.qlr.frzjh) : '';
          // }
          applicantOperatorService.judgeDuplicate(judgeParams).then((res) => {
            if (res.success && res.data) {
              let promiseAll = [this.operatorService(this.receiveParams.buttonService, params)];
              if (this.receiveParams.sameService) {
                const sameParams = this.sameSqr(params);
                promiseAll.push(this.operatorService(this.receiveParams.sameService, sameParams));
              }
              //开始操作

              Promise.all(promiseAll)
                .then(() => {
                  this.success('保存成功');
                })
                .catch((message) => {
                  this.$message.error(message);
                });
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error('请完善表单');
        }
      });
    },
    getParmas(params) {
      let data = {
        qlxxId: this.flowInfoIng.id,
        ywh: params.ywh,
      };
      if (params.sqrType === 'qlr') {
        // data.ywrCategory = [this.receiveParams.sameCategory];
        // data.ywrCategory = [];
        data.qlr = params;
      } else {
        data.qlrCategory = [this.receiveParams.sameCategory];
        data.ywr = params;
      }
      return data;
    },
    //相同流程
    sameSqr(params) {
      let same = Object.assign({}, params);
      if (this.receiveParams.sameData) {
        same.id = this.receiveParams.sameData.id;
      }
      same.ywh = this.receiveParams.sameYwh;
      same.sqrType = same.sqrType === 'qlr' ? 'ywr' : 'qlr';
      same.category = this.receiveParams.sameCategory;
      same.ywrmc = same.qlrmc || same.ywrmc;
      same.qlrmc = same.ywrmc || same.qlrmc;
      return same;
    },
    //操作
    operatorService(buttonService, params) {
      return new Promise((resolve, reject) => {
        applicantOperatorService[buttonService](params).then((res) => {
          if (res.success) {
            // 提交成功
            resolve();
          } else {
            reject(res.message);
          }
        });
      });
    },
    close() {
      this.visible = false;
      this.formvisible = false;
    },
    //身份证信息读取 一体机
    getIdCardInfo() {
      //判断是否已经选择一体机机器型号
      if (this.chooseDeviceInfo.deviceId) {
        let deviceBh = this.chooseDeviceInfo.deviceBh ? this.chooseDeviceInfo.deviceBh : this.chooseDeviceInfo.deviceId;
        //直接获取身份证
        getInfoByDeviceBh({ deviceBh: deviceBh }).then((res) => {
          if (res.success) {
            const result = res.data;
            if (!result) {
              this.$message.error('请确认选择机型编码是否正确,或信息失效');
              return false;
            }
            if (result.dataStatus === '0') {
              this.$message.error('信息失效');
              return false;
            }
            if (result.verifyResult === 0 || result.verifyResult === '0') {
              this.sqrData.qlrmc = result.name;
              this.sqrData.ywrmc = result.name;
              this.sqrData.zjzl = '1';
              this.sqrData.zjh = result.cardNo;
              this.sqrData.idImage = result.idImage;
              this.sqrData.faceImage = result.faceImage;
              if (result.mopsGmsfhmResult === '一致' && result.mopsXmResult === '一致') {
                this.sqrData.gayz_img = require('@iebdc/assets/images/gayz-success.png');
                this.sqrData.gayz = '实名认证成功';
              } else {
                this.sqrData.gayz_img = require('@iebdc/assets/images/gayz-fail.png');
                this.sqrData.gayz = '实名认证失败';
              }
              this.$refs.form.setData(this.sqrData);
            } else {
              this.$message.error('实名认证失败');
            }
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$message.error('请前往用户中心选择一体机编号！');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';
/deep/ .el-dialog__header {
  margin: 0 26px;
  padding: 20px;
  font-size: $font-size-medium;
  font-weight: bold;
  border-bottom: $border-base;
}
/deep/ .el-dialog--center .el-dialog__body {
  @extend .scrollbar-default;
  margin: 0 26px 30px;
  padding: 0px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.header-title {
  font-weight: bold;
  font-size: $font-size-medium;
  .el-button {
    margin-left: $spacing-medium;
  }
}
.lc-name {
  color: orange;
  margin-top: 20px;
}

.rz_img {
  margin-top: 10px;

  .rz_img_face {
    margin-left: 20px;
  }
}
.idcardSS {
  width: 50%;
  float: left;
  height: 296px;
  border-bottom: 1px solid #ddd;
}
.redPoint {
  color: red;
  font-size: 14px;
  margin-left: 10px;
}
</style>
