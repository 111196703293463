import CryptoJS from 'crypto-js';
/**
 * DES 操作
 * @description
 * 加密解密
 */
export const crypto = {
  encryptByMD5(strMessage) {
    if (!strMessage) return;
    return CryptoJS.MD5(strMessage).toString();
  },
  encryptByDES(strMessage, key) {
    key = key || 'greatmap';
    if (!strMessage) {
      return '';
    }
    var keyHex = CryptoJS.enc.Utf8.parse(key);
    var encrypted = CryptoJS.DES.encrypt(strMessage, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  },
  decryptByDES(strMessage, key) {
    key = key || 'greatmap';
    if (!strMessage) {
      return '';
    }
    let keyHex = CryptoJS.enc.Utf8.parse(key);
    let decrypted = CryptoJS.DES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(strMessage),
      },
      keyHex,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  },
};
