/*
 * FileName: e-render.vue
*  desc: 自定义渲染时，涉及到判断逻辑复杂时，使用render
 */
<script>
export default {
  name: 'eRender',
  functional: true,
  data() {
    return {
      flag: false,
      name: 'test',
    };
  },
  props: {
    scope: Object,
    render: Function,
  },
  render: (h, ctx) => {
    return ctx.props.render ? ctx.props.render(h, ctx.props.scope) : '';
  },
};
</script>
