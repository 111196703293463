<template>
  <div class="main">
    <!-- 表格标题 -->
    <div class="type-title">
      <h5>
        <span class="span">{{ commonTableConfig.tableTitle }}</span>
        <span v-show="!disabled">
          <!-- <el-button type="primary" @click="tfQlxx()">统赋</el-button> -->
          <el-button
            type="primary"
            @click="addTableList('add', operator.add, operator.sqrType)"
            v-if="(isShowBtn && this.flowInfoIng.code !== 'N200111') || this.formModel['qlxxExMhs'].length === 0"
            ><i class="icon el-icon-circle-plus"></i>添加</el-button
          >
        </span>
      </h5>
    </div>

    <!-- 表格区域 -->
    <div v-for="(data, i) in tableData" :key="i">
      <div class="operator" v-show="!disabled">
        <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
          <el-link
            class="update-button"
            type="primary"
            icon="el-icon-edit"
            size="mini"
            @click="updateTableList('update', operator['search'], operator['update'], data, operator.sqrType, i)"
            >修改
          </el-link>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
          <el-link
            class="delete-button"
            type="primary"
            icon="el-icon-delete"
            size="mini"
            @click="deleteTableList('delete', operator['search'], operator['delete'], data, operator.sqrType, i)"
            >删除
          </el-link>
        </el-tooltip>
      </div>
      <dynamic-form-old
        ref="qlxx"
        :keyName="'qlxxExMhs' + i"
        @setData="setData"
        v-if="commonTableConfig.dialogFormConfig && visible"
        :formConfig="commonTableConfig.dialogFormConfig"
        :formModel="data"
        :disabled="true"
      >
      </dynamic-form-old>
    </div>

    <!-- 权利信息详情弹窗 -->
    <table-qlxx-dialog
      ref="dialog"
      v-if="commonTableConfig.dialogFormConfig"
      @refresh="getData"
      :formConfig="commonTableConfig.dialogFormConfig"
      :disabled="disabled"
    />
    <!-- 统一赋值 -->
    <qlxxTfDialog ref="dialogTf" @refresh="getData"></qlxxTfDialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import tableOperator from '@iebdc/mixin/tableOperator';
import qlxxTfDialog from '@iebdc/views/apply/flow/applicantTable/dialog/qlxxTfDialog';
import { localDataUser } from 'iebdc/utils/local-data';

export default {
  mixins: [tableOperator],
  components: { qlxxTfDialog },
  props: {
    commonTableConfig: {},
    formModel: {},
    disabled: Boolean,
    formIndex: Number,
  },
  data() {
    return {
      //当前弹框
      currentStatus: '',
      visible: true,
      isShowBtn: true,
    };
  },
  created() {
    this.setOperator('qlxxOperator');
    if (localDataUser.get().userType === 'jg') {
      this.isShowBtn = true;
    } else {
      this.isShowBtn = false;
    }
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData']),
    /**
     * 表格数据
     */
    tableData() {
      return this.formModel['qlxxExMhs'] || [];
    },
  },
  watch: {
    formModel: {
      handler(newValue) {
        this.visible = true;
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations('wysq-data', ['setFormData']),
    ...mapMutations('wysq-data', ['setFormDataKey']),
    // 重新获取数据
    openDialog(descDialog) {
      this.$refs.dialog.open({ ...descDialog });
    },
    getData() {
      this.$emit('getData');
    },
    getDeleteParams(row, index) {
      this.tableData.splice(index, 1);
      const params = {
        qlxxExMhList: this.tableData,
        wwywh: this.flowInfoIng.ywh,
        qlxxId: this.flowInfoIng.id,
      };
      return params;
    },
    //保存值
    setData(data) {
      data.formIndex = this.formIndex;
      this.setFormDataKey(data);
    },
    tfQlxx() {
      this.$refs.dialogTf.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/pages/iebdc/styles/common-variables';

.operator {
  text-align: right;
  height: 40px;
  line-height: 40px;
  //border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  a {
    margin: 0px 10px;
  }
}

.table-title {
  padding: $spacing-base 0 $spacing-base $spacing-base;
  font-size: 16px;
  border-bottom: $border-base;
  margin-bottom: 20px;
  .add-button {
    margin: 0px 10px;
  }
  .color-red {
    color: red;
    font-size: 14px;
  }
  h5 {
    position: relative;
  }
}

.color-red {
  color: #ff2c2c;
  font-size: 12px;
}

.table-row {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  .zwg {
    width: calc(100% - 100px);
  }
}

.table-row-title {
  border-top: 1px solid #c6d5ea;
  border-left: 1px solid #c6d5ea;
  background-color: #f4f4f4;
  width: 150px;
  padding: 0px 10px;
  > div {
    min-height: 32px;
  }
}

/deep/ .el-button-delete span {
  color: #ff2c2c !important;
}

.type-title {
  display: block;
  text-align: left;
  background-color: #f2f6fc;
  border-left: 1px solid #c6d5ea;
  h5 {
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    .span {
      padding-left: 10px;
      border-left: 4px solid #2c8bff;
    }
  }
  .el-button {
    margin: 10px;
    i {
      padding-right: 5px;
    }
  }
}
</style>
