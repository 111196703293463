import request from '../utils/request';
import { REST_SERVICE } from '../config';

//发起缴费申请

// export const payApply = (data) =>
//   request({
//     url: REST_SERVICE.payOnline.payApply,
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     data
//   });
// export const selectPayApplyPageList = (params) =>
//   request({
//     url: REST_SERVICE.payOnline.selectPayApplyPageList,
//     method: 'get',
//     params
//   });
// export const selectByParams = (params) =>
//   request({
//     url: REST_SERVICE.payOnline.selectByParams,
//     method: 'post',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     params
//   });
const payOnline = {
  //查询缴费结果列表
  selectPayApplyPageList(params) {
    return request({
      url: REST_SERVICE.payOnline.selectPayApplyPageList,
      method: 'get',
      params
    });
  },
  selectByParams(data) {
    return request({
      url: REST_SERVICE.payOnline.selectByParams,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    });
  },
  payApply(params) {
    return request({
      url: REST_SERVICE.payOnline.payApply,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      params
    });
  }
};
export default payOnline;
