<template>
  <div class="e-table flex-column" v-loading="loading">
    <div class="flex-space-between" v-if="optionsComputed.title">
      <div class="table-title-text">{{ optionsComputed.title }}</div>
      <slot name="button"></slot>
    </div>
    <el-row class="flex-1">
      <el-col class="table-outer" :span="24" :style="{ height: optionsComputed.height }">
        <el-table
          ref="elTable"
          class="public-sp-el-table table-self"
          :style="{ marginTop: optionsComputed.marginTop }"
          size="middle"
          row-key="id"
          :expand-row-keys="expands"
          @row-click="rowClick"
          :border="false"
          :tree-props="treeProps"
          :max-height="optionsComputed.maxHeight"
          :height="optionsComputed.height"
          :data="tableListCopy"
          :stripe="optionsComputed.stripe"
          @selection-change="selectionChangeHandle"
          @select="select"
          @current-change="currentChangeHandle"
        >
          <el-table-column v-if="optionsComputed.expand" type="expand">
            <template slot-scope="props">
              <slot name="configForm" :rowData="props.row"></slot>
            </template>
          </el-table-column>
          <el-table-column v-if="optionsComputed.mutiSelect" type="selection"></el-table-column>
          <!-- 房查页面多选变单选 -->
          <el-table-column v-if="optionsComputed.mutiSelectAndSinglechoice" width="50" align="center">
            <template slot-scope="scope" v-if="scope.row.bdcqzh">
              <el-checkbox v-model="scope.row.checked" @change="selectChecked(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
            v-if="optionsComputed.hasIndex"
            :fixed="optionsComputed.indexFixed"
          >
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * optionsComputed.pageSize }}
            </template>
          </el-table-column>

          <template v-for="(column, index) in columnsCopy">
            <el-table-column
              :prop="column.prop"
              :key="column.label"
              :label="column.label"
              :align="column.align ? column.align : 'center'"
              :min-width="column.width"
              :fixed="column.fixed ? column.fixed : false"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <template v-if="!column.render && !column.method && !scope.row.editable">
                  <template v-if="column.formatter">
                    <span v-html="column.formatter(scope.row, column, scope.row[column.prop])"></span>
                  </template>

                  <template v-else-if="column.type === 'date'">
                    {{ formatTime(column, scope.row) }}
                  </template>

                  <template v-else-if="column.type === 'dic'">
                    {{ formatDic(column, scope.row) }}
                  </template>

                  <template v-else-if="column.type === 'org'">
                    {{ formatOrg(column, scope.row) }}
                  </template>

                  <template v-else>
                    <span>{{ scope.row[column.prop] }}</span>
                  </template>
                </template>

                <template v-else-if="!column.render && !scope.row.editable">
                  <template v-if="column.formatter">
                    <span
                      @click="column.method"
                      style="cursor: pointer"
                      v-html="column.formatter(scope.row, column, scope.row[column.prop])"
                    ></span>
                  </template>

                  <template v-else-if="column.type === 'date'">
                    {{ formatTime(column, scope.row) }}
                  </template>

                  <template v-else-if="column.type === 'dic'">
                    {{ formatDic(column, scope.row) }}
                  </template>

                  <template v-else-if="column.type === 'org'">
                    {{ formatOrg(column, scope.row) }}
                  </template>

                  <template v-else>
                    <span>{{ scope.row[column.prop] }}</span>
                  </template>
                </template>
                <!-- 编辑状态的switch -->

                <template v-else-if="column.render && column.type === 'switch' && scope.row.editable">
                  <expand-dom :column="column" :row="scope.row" :render="column.render" :index="index"></expand-dom>
                  <el-switch
                    v-model="scope.row[column.prop]"
                    active-color="#0e90fe"
                    inactive-color="#dcdfe6"
                    :disabled="false"
                    :active-value="column.statusObj ? column.statusObj.activeValue : true"
                    :inactive-value="column.statusObj ? column.statusObj.inactiveValue : false"
                    @change="column.method(scope.row, column)"
                  ></el-switch>
                </template>
                <template v-else-if="column.type === 'select' && column.options.length > 0 && scope.row.editable">
                  <el-select v-model="scope.row[column.prop]">
                    <el-option
                      v-for="(item, i) in column.options"
                      :key="i"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
                <template v-else-if="column.type === 'select' && column.options.length > 0">
                  <el-select v-model="scope.row[column.prop]" :disabled="true">
                    <el-option
                      v-for="(item, i) in column.options"
                      :key="i"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </template>
                <!-- 表格内的不可编辑switch -->

                <template v-else-if="column.render && column.type === 'switch'">
                  <expand-dom :column="column" :row="scope.row" :render="column.render" :index="index"></expand-dom>
                  <el-switch
                    v-model="scope.row[column.prop]"
                    active-color="#0e90fe"
                    inactive-color="#dcdfe6"
                    :disabled="true"
                    :active-value="column.statusObj ? column.statusObj.activeValue : true"
                    :inactive-value="column.statusObj ? column.statusObj.inactiveValue : false"
                    @change="column.method(scope.row, column)"
                  ></el-switch>
                </template>

                <!-- 自定义渲染模板, 使用jsx语法 -->
                <e-render v-else-if="column.renderJsx" :scope="scope" :render="column.renderJsx" />

                <!-- 自定义渲染模板, 使用插槽形式 -->
                <slot v-else-if="column.slot" :name="column.prop" :row="scope.row" :$index="scope.$index" />

                <!-- 表格行内编辑 -->
                <template v-else-if="scope.row.editable">
                  <el-input v-model="scope.row[column.prop]"></el-input>
                </template>

                <template v-else-if="column.method">
                  <el-link :underline="false" @click.stop.native.prevent="column.method(scope.row)">{{
                    scope.row[column.prop]
                  }}</el-link>
                </template>

                <template v-else>
                  <expand-dom :column="column" :row="scope.row" :render="column.render" :index="index"></expand-dom>
                </template>
              </template>
            </el-table-column>
          </template>

          <template v-if="operates">
            <el-table-column
              label="操作"
              :align="operates.align ? operates.align : 'center'"
              :min-width="operates.width"
              :fixed="operates.fixed"
              v-if="operates.list.filter((_x) => _x.show === true).length > 0"
            >
              <template slot-scope="scope">
                <span class="operate-group flex-center-justify flex-wrap">
                  <template v-for="btn in operates.list">
                    <span class="item" v-if="btn.showCallback && btn.showCallback(scope.row)" :key="btn.id">
                      <e-render
                        v-if="btn.spanType === 'render' && btn.render"
                        :scope="scope"
                        :render="btn.render"
                      ></e-render>
                      <el-dropdown placement="right" trigger="click" v-if="btn.spanType === 'dropdown'">
                        <el-button
                          :type="btn.type || 'primary'"
                          :plain="btn.plain"
                          :class="`font-color-${btn.color}`"
                          class="public-sp-el-btn pd5-10"
                          :size="btn.size"
                          @click.stop.native.prevent="btn.method(scope.$index, scope.row)"
                          >{{ btn.label }}</el-button
                        >
                        <el-dropdown-menu slot="dropdown" v-if="btn.dropdownList">
                          <template v-for="(item, index) in btn.dropdownList">
                            <el-dropdown-item
                              v-if="item.show(scope.row)"
                              :key="index"
                              @click.native="item.dropdownItemclick(scope.row, item, index)"
                              >{{ item.label }}</el-dropdown-item
                            >
                          </template>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-button
                        v-if="btn.spanType === 'button'"
                        :plain="btn.plain"
                        class="public-sp-el-btn pd5-10"
                        :type="btn.type || 'primary'"
                        :size="btn.size"
                        :class="btn.disabled ? `text-disabled` : `font-color-${btn.color}`"
                        :icon="btn.leftIcon ? btn.leftIcon : ''"
                        :disabled="typeof btn.disabled === 'undefined' ? false : btn.disabled(scope.row)"
                        @click.stop.native.prevent="btn.method(scope.$index, scope.row)"
                      >
                        {{ btn.label }}
                        <i v-if="btn.rightIcon" class="el-icon--right" :class="btn.rightIcon"></i>
                      </el-button>
                      <el-link
                        v-if="btn.spanType === 'link' || !btn.spanType"
                        :type="btn.type || 'primary'"
                        class="public-sp-el-link"
                        :class="`font-color-${btn.color}`"
                        :disabled="typeof btn.disabled === 'undefined' ? false : btn.disabled"
                        :underline="typeof btn.underline === 'undefined' ? false : btn.underline"
                        @click.stop.native.prevent="btn.method(scope.$index, scope.row)"
                      >
                        <i class="iconfont" v-if="btn.icon" v-html="btn.icon"></i>
                        <EImg class="iconfont" v-else-if="btn.img" size="14px" :icon="btn" />
                        {{ btn.label }}&nbsp;
                      </el-link>
                    </span>
                  </template>
                </span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </el-col>
    </el-row>
    <el-row v-if="optionsComputed.hasPagination">
      <el-col :span="24" class="pagination-col">
        <EPagination
          class="pagination"
          :total="optionsComputed.total"
          :pageSize="optionsComputed.pageSize"
          :current="currentPage"
          @currentPageClick="currentPageClickHandle"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EImg from '@/components/e-img/index';
import EPagination from './../e-pagination/index';
import eRender from './e-render';
import { format, isDate, parseJSON } from 'date-fns';
/**
 * @param {Array} tableList - 表格数据.
 * @param {Object} columns - 表格列数据.
 * @param {Object} columns.formatter:method - 格式化:文字链接
 * @param {Object} columns.type date时间转换 dic字典转换(转换类型label) org机构转换名字
 * @param {Object} operates - 表格操作按钮配置.
 * @param {Object} operates.fixed - 表格整体配置(`true`,`left`,`right`).
 * @param {Object} options - 表格整体配置.
 * @param {Boolean} options.hasIndex:expand:mutiSelect - 是否包含序号:展开内容:选择框
 * @param {Boolean} options.indexFixed:expand:mutiSelect -序号是否fixed布局
 * @param {Boolean} options.stripe - 是否为斑马纹.
 * @param {Boolean} options.highlightCurrentRow - 是否开启高亮当前行.
 * @param {Boolean} options marginTop:maxHeight:height - 表格距离上距离的高度:最大高度:高度.
 * @param {Boolean} options.hasPagination hasPagination:total:pageSize - 表格是否有分页:数据条数总和:每页条数
 * @author ddcome.
 * @description 云监管平台的表格组件.
 */
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // 表格数据
    tableList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectable: {
      type: Function,
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    operates: {
      type: Object,
      default: () => {
        return {
          minWidth: '0px',
          fixed: null,
          list: [],
        };
      },
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    treeProps: {
      type: Object,
      default() {
        return { children: 'children' };
      },
    },
  },
  computed: {
    optionsComputed: {
      get: function () {
        let temp = Object.assign({}, this.optionsDefault);
        for (let index in this.options) {
          temp[index] = this.options[index];
        }
        return temp;
      },
      set: function (obj) {
        this.optionsDefault = Object.assign(this.optionsDefault, obj);
      },
    },
  },
  //组件
  components: {
    eRender,
    EImg,
    EPagination,
    expandDom: {
      functional: false,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: function (h) {
        return this.render(h, this.row);
      },
    },
  },
  data() {
    return {
      expands: [],
      tableListCopy: this.tableList,
      columnsCopy: this.columns,
      // 当前页码
      currentPage: 1,
      multipleSelection: [], // 多行选中
      optionsDefault: {
        // 数据总数
        total: 0,
        // 每页数据数
        pageSize: 10,
        hasIndex: true,
        indexFixed: false,
        stripe: true,
        highlightCurrentRow: true,
        hasPagination: true,
        maxHeight: '550px',
        height: '100%',
        marginTop: '0px',
        showHeader: true,
        showTip: true,
      },
    };
  },
  watch: {
    tableList(value) {
      this.tableListCopy = value;
    },
  },
  methods: {
    //时间格式化
    formatTime(column, row) {
      if (row[column.prop] !== null) {
        if (isDate(row[column.prop])) {
          return format(row[column.prop], column.dateFormat || 'yyyy-MM-dd HH:mm:ss');
        } else {
          return format(parseJSON(row[column.prop]), column.dateFormat || 'yyyy-MM-dd HH:mm:ss');
        }
      } else {
        return null;
      }
    },
    //字典格式化
    formatDic(column, row) {
      if (!row[column.prop]) return '';
      const dicLabel = this.$store.getters.getLabelDicByTypeAndValue(column.dicType || column.label, row[column.prop]);
      return dicLabel;
    },
    //机构code转name
    formatOrg(column, row) {
      const orgName = this.$store.getters.getOrgNameByCode(row[column.prop]);
      return orgName;
    },
    setCurrentPage(val) {
      this.currentPage = val;
    },
    setTableList(data) {
      if (!data) {
        data = [];
      }
      this.tableListCopy = data;
    },
    setColumns(data) {
      this.columnsCopy = data;
    },
    setTotal(val) {
      this.optionsComputed = {
        total: val,
      };
    },
    setPageSize(val) {
      this.optionsComputed = {
        pageSize: val,
      };
    },
    setPageInfo(current, size, total, tableList) {
      this.setCurrentPage(current);
      this.setPageSize(size);
      this.setTotal(total);
      this.setTableList(tableList);
    },
    setPageInfoWithoutPagination(tableList) {
      this.setTableList(tableList);
    },
    select(selected, row) {
      this.$emit('select', selected, row);
    },
    selectionChangeHandle(val) {
      this.multipleSelection = val;
      this.$emit('selectionChangeHandle', val);
    },
    //取消勾选
    toggleRowSelection(row, state) {
      if (row) {
        this.$refs.elTable.toggleRowSelection(row, state);
      } else {
        this.$refs.elTable.clearSelection();
      }
    },
    selectionChangeSingleSelect(val) {
      if (val.length > 1) {
        this.$refs.elTable.clearSelection();
        this.$refs.elTable.toggleRowSelection(val.pop());
      }
    },
    showActionTableDialog() {
      this.$emit('handelAction');
    },
    currentPageClickHandle(val) {
      const $this = this;
      $this.setCurrentPage(val);
      $this.$emit('afterCurrentPageClick', val, function () {});
    },
    currentChangeHandle() {
      this.$emit('currentChange');
    },
    setSelectedData(val) {
      this.$refs.elTable.clearSelection();
      if (val && val.length > 0) {
        for (let i in val) {
          this.$refs.elTable.toggleRowSelection(val[i], true);
        }
      }
    },
    setAllSelection() {
      this.$refs.elTable.toggleAllSelection();
    },
    rowClick(row) {
      // console.log(event, column);
      if (this.expands.indexOf(row.id) < 0) {
        this.expands.push(row.id);
      } else {
        let index = this.expands.indexOf(row.id);
        if (index > -1) {
          this.expands.splice(index, 1);
        }
      }
    },
    //房查页面,当选了一项时,其他项选择状态清除
    clearSelectedOther(row) {
      this.tableListCopy.map((item) => {
        if(row.id === item.id) {
          this.$set(item, 'checked', row.checked);
        } else { 
          this.$set(item, 'checked', false);
        }
      });
    },
    selectChecked(row) {
      if (row.checked) {
        // row.checked = false;
        this.$emit('currentChange', row);
      } else {
        // row.checked = true;
        this.$emit('currentChange', null);
      }
      this.clearSelectedOther(row);
    },
  },
  mounted() {
    this.$emit('afterReady');
  },
};
</script>
<style lang="scss" scoped>
@mixin text-color {
  .font-color-primary {
    color: #2c8bff;
  }
  .font-color-danger {
    color: #ff2c2c;
  }
  .font-color-warning {
    color: #ffad2c;
  }
  .font-color-info {
    color: #909399;
  }
  .font-color-success {
    color: #67c23a;
  }
}
.e-table {
  width: 100%;
  .table-title-text {
    font-size: 20px;
    padding: 5px 20px 20px 20px;
    font-weight: 400;
    color: #333;
  }
  .el-row {
    .pagination-col {
      margin-top: 20px;
      margin-bottom: 20px;

      .pagination {
        text-align: center;
      }
    }

    .operate-group {
      .item {
        padding: 0 3px;
        font-size: 14px;
        @include text-color;
        &:hover {
          opacity: 0.7;
        }
        // .text-disabled
        //   //opacity: 0.7;
        //   //对应的不同颜色
        //
      }
    }

    .table-outer {
      height: 100%;
    }
  }
}

/deep/ .el-table {
  border-top: 1px solid rgba(241, 241, 241, 1);
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  //   height: inherit !important;
  //   .cell.el-tooltip
  //     white-space: normal;

  th {
    height: 53px !important;
    font-size: 14px !important;
    font-family: Microsoft YaHei !important;
    font-weight: 400 !important;
    color: rgba(51, 51, 51, 1) !important;
  }

  .el-table__row--striped td {
    background-color: rgba(247, 250, 255, 1) !important;
  }

  .el-table__fixed-right-patch {
    height: 53px !important;
  }

  .el-table__fixed-right {
    .el-table__fixed-body-wrapper {
      top: 53px !important;
    }
  }
}

/*/deep/ .el-table__header-wrapper {*/
/*  /deep/ .el-table__header {*/
/*    /deep/ .el-checkbox {*/
/*      display: none;*/
/*    }*/
/*  }*/
/*}*/

.pd5-10 {
  padding: 1px;
}
</style>
