<template>
  <el-image :style="{ width: size, height: size }" :src="require('' + icon)" fit="fill"></el-image>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    size: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
};
</script>
