<template>
  <span>{{ title }}</span>
</template>
<script>
import payOnline from '@iebdc/api/pay-online.js';
import payVerify from '@manager/api/verify/payVerify.js';
export default {
  data() {
    return {
      title: '',
      status: 0,
      loading: false,
      billNo: [],
      payStatus0: 0,
      payStatus1: 0,
      isCheck: 0,
    };
  },
  props: {
    row: Object,
    timestamp: {
      type: String,
      require: false,
      default: 'init',
    },
  },
  watch: {
    timestamp: {
      immediate: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      //后台管理还是网上大厅
      if (window.location.href.indexOf('manager') > 0) {
        payVerify.selectPayApplyPageList({ orderNO: this.row.wwywh }).then((res) => {
          if (res.success) {
            if (res.data.page.length !== 0) {
              this.payStatus0 = 0; //缴费状态为0的项目
              this.payStatus1 = 0; //缴费状态为1的项目
              this.isCheck = 0;
              res.data.page.forEach((ele) => {
                if (ele.status === '1') {
                  this.payStatus1++;
                }
                if (ele.status === '0') {
                  this.payStatus0++;
                }
                if (ele.isCheck === '1') {
                  this.isCheck++;
                }
              });
              if (this.isCheck === 0) {
                this.row.isShowCheck = true;
              }
              this.title = '完成缴费' + this.payStatus1 + '/' + res.data.page.length;
            } else {
              this.row.isShowCheck = true;
              this.title = '未审核';
              // this.row.isShowPay = true;
            }
            this.$forceUpdate();
          }
        });
      } else {
        payOnline.selectPayApplyPageList({ orderNO: this.row.wwywh }).then((res) => {
          if (res.success) {
            if (res.data.page.length !== 0) {
              this.payStatus0 = 0; //缴费状态为0的项目
              this.payStatus1 = 0; //缴费状态为1的项目
              this.billNo = [];
              res.data.page.forEach((ele) => {
                if (ele.status === '1') {
                  this.payStatus1++;
                }
                if (ele.status === '0') {
                  this.payStatus0++;
                }
                if (ele.billNo !== '' && ele.billNo !== null) {
                  this.billNo.push({
                    orgName: ele.orgName,
                    billNo: ele.billNo,
                    money: ele.money,
                  });
                }
              });
              this.row.billNoList = this.billNo;
              console.log(this.row.hasOwnProperty('billNoList'));
              if (this.payStatus1 === res.data.page.length) {
                this.title = '已缴费' + this.payStatus1 + '/' + res.data.page.length;
              } else {
                this.row.isShowPay = true;
                this.title = '已缴费' + this.payStatus1 + '/' + res.data.page.length;
              }
              //   if (res.data.page[0].status === '1') {
              //     //已缴费
              //     this.title = '已缴费';
              //     this.row.billNo = res.data.page[0].billNo;
              //   } else if (res.data.page[0].status === '0') {
              //     //未缴费
              //     //未审核
              //     if (res.data.page[0].isCheck === '0') {
              //       this.row.isShowCheck = true;
              //     } else {
              //       //已审核
              //       this.title = '未缴费';
              //       this.row.isShowPay = true;
              //     }
              //   }
              // } else {
              //   this.row.isShowCheck = true;
              //   this.title = '未缴费';
              //   // this.row.isShowPay = true;
              // }
              // this.$forceUpdate();
            } else {
              this.title = '未申请缴费';
            }
          }
        });
      }
    },
  },
  mounted() {
    // this.init();
  },
  //   created() {
  //     this.init();
  //   },
};
</script>
