import { LocalDataManager } from './local-storage';

/**
 * 存储用户信息
 * @type {LocalDataManager}
 * @example
 * const userData = localDataUser.get();
 * console.log(userData)
 * { areaCode, realName, token, userId, username }
 */
export const localDataUser = new LocalDataManager('USER_DATA');

/**
 * 存储应用信息
 * @type {LocalDataManager}
 * @example
 * const appData = localDataApp.get();
 * console.log(appData)
 * { appId, dic }
 */
export const localDataApp = new LocalDataManager('APP_DATA');

/**
 * 存储字典值
 * @type {LocalDataManager}
 * @example
 * const userData = localDataUser.get();
 * console.log(userData)
 * { areaCode, realName, token, userId, username }
 */
export const localDataDic = new LocalDataManager('DIC_DATA');

/**
 * 存储用户相关信息
 * @type {LocalDataManager}
 * @example
 * const userData = localDataUser.get();
 * console.log(userData)
 * { areaCode, realName, token, userId, username }
 */
export const localDataUserInfo = new LocalDataManager('USERINFO_DATA');

/**
 * 存储权限相关信息
 * @type {LocalDataManager}
 * @example
 * const userData = localDataUser.get();
 * console.log(userData)
 * { areaCode, realName, token, userId, username }
 */
export const localDataPermission = new LocalDataManager('PERMISSION_DATA');
