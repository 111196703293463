<template>
  <el-dialog title="良田高拍仪"
             width="80%"
             :visible.sync="visible"
             destroy-on-close
             :close-on-click-modal="false">
    <el-row :gutter="16"
            v-if="visible"
            v-loading="ready"
            element-loading-text="加载中......">
      <el-col :span="12">
        <div class="gpy-container">
          <img :src="bigSrc"
               id="bigPriDev" />
        </div>
        <div class="operation">
          <div class="gpyBtn">
            <div class="topBtn">
              <el-row :gutter="10"
                      class="gpy-setting-btn">
                <el-col :span="12">
                  <label class="gpy-label">设备数据</label>
                  <el-select v-model="priDev"
                             style="width: 120px"
                             @change="devChange">
                    <el-option v-for="(item, index) in selectPriDev"
                               :key="index"
                               :value="item">
                      {{ item }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">出图设备</label>
                  <el-select v-model="priModel"
                             style="width: 120px"
                             @change="modelChange">
                    <el-option v-for="(item, index) in selectPriModel"
                               :key="index"
                               :value="item">
                      {{ item }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">设备分辨率</label>
                  <el-select v-model="priResolution"
                             style="width: 120px"
                             @change="resolutionChange">
                    <el-option v-for="(item, index) in selectPriResolution"
                               :key="index"
                               :value="item">
                      {{ item }}
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="12">
                  <label class="gpy-label">尺寸</label>
                  <el-select v-model="priScanSize"
                             style="width: 120px"
                             @change="scanSize">
                    <el-option v-for="(item, index) in selectScanSize"
                               :key="index"
                               :value="item">{{ item }} </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </div>
            <div class="bottom-btn">
              <el-button @click="rotateLeft()"
                         icon="undo">图片左转</el-button>
              <el-button @click="rotateRight()"
                         icon="redo">图片右转</el-button>
              <el-button @click="showProperty()"
                         icon="setting">打开图片设置</el-button>
              <div class="jbcb">
                纠偏裁边：
                <el-switch v-model="jbcb"
                           @change="setdeskew" />
              </div>
            </div>
          </div>
          <div class="gpy-photo-btns">
            <el-button @click="goPhoto()"
                       class="camera-btn"
                       type="primary"
                       icon="el-icon-camera">拍照 </el-button>
            <el-button @click="initGpy()"
                       class="initGpyBtn"
                       type="primary"
                       icon="el-icon-refresh">初始化高拍仪 </el-button>
            <el-button @click="visible = false"
                       class="initGpyBtn"
                       type="primary"
                       icon="el-icon-circle-close">完成 </el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="12"
              v-if="singleFile">
        <p class="single-title">拍照预览</p>
        <img v-if="singleTempUrl"
             :src="singleTempUrl"
             style="width: 100%; height: 400px; background-color: #cccccc;" />
        <el-image v-else
                  style="width: 100%; height: 400px; background-color: #cccccc;"
                  fit="fill"
                  :src="singleImgUrl"
                  :preview-src-list="[singleImgUrl]">
        </el-image>
      </el-col>
      <el-col :span="12"
              v-else>
        <div>
          <el-button @click.stop="tipHandle(tip)"
                     v-for="(tip, index) in tips"
                     :key="index">{{ tip }}</el-button>
          <el-button @click.stop="deleteAllPhoto"
                     type="danger"
                     icon="el-icon-delete"
                     style="float: right;">
            清空
          </el-button>
        </div>
        <DragTable ref="DragTableRef"
                   :key="gpyKey"
                   :tableData="gpyImgList"
                   @delete="deleteHandle"
                   @change="changeHandle" />
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import DragTable from './drag-table/index.vue';
import QWebChannel from '@/plugins/gpyMain.js';
import { base64UrlToBlob } from './oprations.utils';

export default {
  components: { DragTable },
  data () {
    return {
      gpyKey: 0,
      singleTempUrl: null,
      // 是否是单文件
      singleFile: false,
      fileNamePrefix: '',
      ready: true,
      visible: false,
      popoverVisible: false,
      jbcb: false,
      //-----------------------
      gpyBaseUrl: 'ws://127.0.0.1:12345',
      webSocket: null,
      errorMessage: '',
      //-----------------------
      priDev: '',
      priModel: '',
      priResolution: '',
      priScanSize: '',
      //---
      bigSrc: null,
      //拍摄图片
      gpyImgList: [],
      //设备数据
      selectPriDev: [],
      //设备出图
      selectPriModel: [],
      //设备分辨率
      selectPriResolution: [],
      //设备尺寸
      selectScanSize: ['原始尺寸', 'A5', 'A4', '卡片']
    };
  },
  props: {
    initData: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  computed: {
    singleImgUrl () {
      if (this.gpyImgList.length > 0) {
        return this.gpyImgList[this.gpyImgList.length - 1].uploadUrl;
      } else {
        return '';
      }
    }
  },
  watch: {
    gpyImgList: {
      deep: true,
      immediate: false,
      handler () {
        this.gpyKey = new Date().getTime();
      }
    },
    initData: {
      deep: true,
      immediate: false,
      handler (val) {
        this.gpyImgList = val;
      }
    },
    visible: {
      immediate: false,
      handler (val) {
        if (val) {
          this.ready = true;
          //执行高拍仪
          this.gpyImgList = [];
          this.$nextTick(() => {
            this.openGpyWebSocket();
          });
          this.ready = false;
        } else {
          this.webSocket.close();
        }
      }
    }
  },
  methods: {
    // 设置是否是单文件上传
    setSingleFile (val) {
      this.singleFile = val;
    },
    open () {
      this.visible = true;
      this.selectPriDev = [];
      this.selectPriModel = [];
      this.selectPriResolution = [];
    },
    close () {
      this.visible = false;
    },
    deleteArrayByIndex (i, data) {
      data.splice(i, 1);
      return data;
    },
    deleteHandle (index) {
      this.gpyImgList = this.deleteArrayByIndex(index, JSON.parse(JSON.stringify(this.gpyImgList)));
      // 更新序号
      this.updateGpyImgList(this.gpyImgList);
      this.$emit('update', this.gpyImgList);
    },
    // 表格数据发生变化
    changeHandle (list) {
      this.updateGpyImgList(list);
      this.$emit('update', this.gpyImgList);
    },
    updateGpyImgList () {
      let temp = [];
      let itemTemp = {};
      JSON.parse(JSON.stringify(this.gpyImgList)).forEach((c, i) => {
        itemTemp = Object.assign(c, {
          sxh: i + 1,
          clmc: c.clmc.substring(0, c.clmc.indexOf(c.clfl) + c.clfl.length) + (i + 1) + '.jpg'
        });
        temp.push(JSON.parse(JSON.stringify(itemTemp)));
      });
      this.gpyImgList = temp;
    },
    openGpyWebSocket () {
      this.webSocket = null;
      this.webSocket = new WebSocket(this.gpyBaseUrl);
      this.webSocket.onclose = () => { };
      this.webSocket.onerror = () => {
        this.$message.error('高拍仪服务连接错误，可能服务未启动，请检查驱动和usb线连接，插入后请刷新浏览器页面！');
      };
      this.webSocket.onopen = () => {
        new QWebChannel(this.webSocket, (channel) => {
          console.log('通信成功')
          setTimeout(() => {
            console.log($('#bigPriDev')[0].currentSrc === '', 'currentSrc')
            if ($('#bigPriDev')[0].currentSrc === '') {
              this.$message.error('高拍仪服务连接错误，可能服务未启动，请检查驱动和usb线连接，插入后请刷新浏览器页面！');
            } else {
              this.$message.success('高拍仪服务连接正常！');
              this.ready = false;
            }
          }, 3500);
          window.gpy = channel.objects.dialog;
          //网页关闭函数
          window.onbeforeunload = () => {
            window.gpy.get_actionType('closeSignal');
          };
          window.onunload = () => {
            window.gpy.get_actionType('closeSignal');
          };
          this.initGpy();
          //从服务器返回消息
          window.gpy.sendPrintInfo.connect((message) => {
            //设备信息 priModel
            if (message.indexOf('priDevName:') >= 0) {
              message = message.substr(11);
              this.selectPriDev.push(message);
              this.priDev = this.selectPriDev[0];
            }
            //设备出图格式
            if (message.indexOf('priModel:') >= 0) {
              message = message.substr(9);
              this.selectPriModel.push(message);
              this.priModel = this.selectPriModel[0];
            }
            //设备分辨率
            if (message.indexOf('priResolution:') >= 0) {
              message = message.substr(14);
              this.selectPriResolution.push(message);
              this.priResolution = this.selectPriResolution[1];
            } else if (message.indexOf('savePhoto_success:') >= 0) {
              //返回图片路径
              //图片保存后返回路径关键字savePhoto_success:
              console.log('高拍仪图片保存路径：' + message.substr(18));
            }
          });
          //接收图片流用来展示，多个，较小的base64数据
          window.gpy.send_priImgData.connect((message) => {
            this.bigSrc = 'data:image/jpg;base64,' + message;
          });
          //接收拍照base64 并放在img展示
          window.gpy.send_priPhotoData.connect((message) => {
            this.singleTempUrl = null;
            let num = this.gpyImgList.length + 1;
            this.uploadNow({
              id: num,
              url: 'data:image/jpg;base64,' + message,
              name: this.fileNamePrefix + num
            });
            this.singleTempUrl = 'data:image/jpg;base64,' + message;
          });
        });
      };
    },
    // 初始化
    initGpy () {
      window.gpy.get_actionType('closeSignal');
      this.bigSrc = '';
      window.gpy.html_loaded('one');
      this.priScanSize = this.selectScanSize[0];
    },
    //拍照
    photoPic () {
      window.gpy.photoBtnClicked('primaryDev_');
    },
    //左转
    rotateLeft () {
      window.gpy.get_actionType('rotateLeft');
    },
    //右转
    rotateRight () {
      window.gpy.get_actionType('rotateRight');
    },
    //属性
    showProperty () {
      window.gpy.get_actionType('showProperty');
    },
    //纠偏裁边
    setdeskew () {
      window.gpy.get_actionType('setdeskew');
    },
    //---------------
    //选择更新
    devChange (value) {
      this.selectPriResolution = [];
      this.selectPriModel = [];
      window.gpy.devChanged('primaryDev_:' + value);
    },
    modelChange (value) {
      this.selectPriResolution = [];
      window.gpy.devChanged('primaryDev_:' + value);
    },
    resolutionChange (value) {
      window.gpy.devChanged('primaryDev_:' + value);
    },
    scanSize (value) {
      switch (value) {
        case '原始尺寸':
          window.gpy.get_actionType('setScanSize_ori');
          break;
        case 'A5':
          window.gpy.get_actionType('setScanSize_A5');
          break;
        case 'A4':
          window.gpy.get_actionType('setScanSize_A4');
          break;
        case '卡片':
          window.gpy.get_actionType('setScanSize_card');
          break;
      }
    },
    //-----------------------
    //拍照
    goPhoto () {
      this.ready = true;
      window.gpy.photoBtnClicked('primaryDev_');
    },
    deleteAllPhoto () {
      this.gpyImgList = [];
      this.$refs.DragTableRef.removeAllHandle();
      this.$emit('update', this.gpyImgList);
    },
    uploadNow (file) {
      let temp = [];
      let f = base64UrlToBlob(file.url);
      f.name = file.name + '.jpg';
      f['pageType'] = file.pre || '';
      temp.push(f);
      this.$emit('upload', temp);
    },
    //上传
    uploadPhotoHandle () {
      let temp = [];
      this.gpyImgList.forEach((c) => {
        let file = base64UrlToBlob(c.url);
        file.name = c.name + '.jpg';
        file['pageType'] = c.pre || '';
        temp.push(file);
      });
      this.$emit('upload', temp);
    },
    setFileNamePrefix () {
      this.fileNamePrefix = name;
    },
    setTips (tips) {
      this.tips = tips;
    },
    // table上侧按钮操作区
    tipHandle (tip) {
      if (this.$refs.DragTableRef.getSelected().length <= 0) {
        this.$message.info('请先选择要操作的数据！');
      }
      let setPre = (pre) => {
        this.$refs.DragTableRef.getSelected().forEach((c) => {
          this.gpyImgList.forEach((c1, i1) => {
            if (c1.id === c.id) {
              let preTemp = this.gpyImgList[i1].clmc.substring(0, this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl));
              let endTemp = this.gpyImgList[i1].clmc.substring(
                this.gpyImgList[i1].clmc.indexOf(this.gpyImgList[i1].clfl),
                this.gpyImgList[i1].clmc.length
              );
              if (preTemp === pre) {
                this.gpyImgList[i1].clmc = endTemp;
              } else {
                this.gpyImgList[i1].clmc = pre + endTemp;
              }
            }
          });
        });
      };
      setPre(tip);
      this.$refs.DragTableRef.updateTable();
      this.$emit('update', this.gpyImgList);
    }
  },
  beforeDestroy () {
    this.webSocket.close();
  }
};
</script>

<style lang="scss" scoped>
/deep/ #Capture {
  height: 300px !important;
}

.gpy-container {
  height: 300px;
  border: 1px solid #ccc;
  img {
    height: 100%;
    width: 100%;
  }
}

.gpy-label {
  display: inline-block;
  width: 80px;
}

.gpy-setting-btn {
  .el-col {
    margin: 10px 0 0 0;
  }
}

.bottom-btn {
  margin-top: 10px;
  .el-button:nth-child(3) {
    margin-right: 5px;
  }
}

.gpy-photo-btns {
  margin-top: 10px;
  // .el-button {
  // }
  .el-button:last-child {
    margin-bottom: 0px;
  }
}

.jbcb {
  display: inline-block;
  width: 120px;
}

.single-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
