<template>
  <el-select
    v-model="bindData"
    :clearable="clearable"
    :filterable="filterable"
    :placeholder="placeholder"
    :allow-create="allowCreate"
    :disabled="disabled"
  >
    <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
/**
 * 字典下拉选项组件
 *
 * config 基本配置
 * placeholder
 * clearable 是否可清除
 * filterable 是否可查询
 * type 字典类型 传了type则不用传data
 * data 下拉数据
 * binddata 选中的值
 */
//import { localDataDic } from '@/utils/local-data';
export default {
  model: {
    // 使用model， 这儿2个属性，prop属性说，我要将msg作为该组件被使用时（此处为aa组件被父组件调用）v-model能取到的值，event说，我emit ‘cc’ 的时候，参数的值就是父组件v-model收到的值。
    prop: 'binddata',
    event: 'update',
  },
  props: {
    // 接收父级参数
    placeholder: String,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    type: String,
    binddata: [String, Number],
    disabled: Boolean,
  },
  data() {
    return {
      bindData: '',
    };
  },
  created() {
    if (this.binddata === '0' || this.binddata === 0 || this.binddata) {
      this.bindData = String(this.binddata);
      console.log('=======1===========', this.binddata);
    }
  },
  watch: {
    binddata(value) {
      this.bindData = String(value);
    },
    bindData() {
      this.$emit('update', this.bindData);
    },
  },
  computed: {
    // 如果有字典类型 则获取到字典值
    dataList: function () {
      if (this.type) {
        let url = window.location.href;
        const dic = url.indexOf('manager') < 0 ? localStorage.IEBDC_DIC_DATA : localStorage.DIC_DATA;
        const allDic = JSON.parse(dic).dic;
        const dicArr = allDic.find((item) => {
          return item.label === this.type;
        });
        return dicArr ? dicArr.childrens : [];
      }
      return [];
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}
</style>
