const qlxxService = {
  //获取当前用户信息
  getQlxxByHousetable(huInfo) {
    let qlxxExMhList = {};
    qlxxExMhList.xmmc = huInfo.dcbz.xmmc;
    qlxxExMhList.zh = huInfo.dcbz.zh;
    qlxxExMhList.shbw = huInfo.dcbh.shbw;
    if (huInfo.dcbh.scjzmj && huInfo.dcbh.scjzmj > 0) {
      qlxxExMhList.scjzmj = huInfo.dcbh.scjzmj;
      qlxxExMhList.fwcqmj = huInfo.dcbh.scjzmj;
    } else {
      qlxxExMhList.scjzmj = huInfo.dcbh.ycjzmj;
      qlxxExMhList.fwcqmj = huInfo.dcbh.ycjzmj;
    }
    if (huInfo.dcbh.sctnjzmj && huInfo.dcbh.sctnjzmj > 0) {
      qlxxExMhList.sctnjzmj = huInfo.dcbh.sctnjzmj;
    } else {
      qlxxExMhList.sctnjzmj = huInfo.dcbh.yctnjzmj;
    }
    if (huInfo.dcbh.scftjzmj && huInfo.dcbh.scftjzmj > 0) {
      qlxxExMhList.scftjzmj = huInfo.dcbh.scftjzmj;
    } else {
      qlxxExMhList.scftjzmj = huInfo.dcbh.ycftjzmj;
    }
    qlxxExMhList.fwyt1 = huInfo.dcbh.fwyt1;
    return qlxxExMhList;
  },
  //权利信息扩展整理
  setQlxxEx(data) {
    if (!data.children) return;
    data.children.forEach((element) => {
      if (!element.qlxxEx) {
        element.qlxxEx = {
          sqdjyy: data.subFlowname,
        };
      }
      if (element.bdcdjzmh) {
        element.qlxxEx.bdcdjzmh = element.bdcdjzmh;
      }
    });
  },
  //权利信息验证
  qlxxTableValidate(formData, config) {
    let message = '';
    config.forEach((pitem, index) => {
      if (formData.children[index].qlxxExMhs && formData.children[index].qlxxExMhs.length > 0) {
        //将不动产权证书填写内容配置提取出来
        let allParams = getAllParams(pitem);
        if (formData.children[index].qlxxExMhs === null || formData.children[index].qlxxExMhs.length === 0) {
          return (message = '0');
        }
        if (formData.children[index].qlxxExMhs !== null || formData.children[index].qlxxExMhs.length !== 0) {
          formData.children[index].qlxxExMhs.forEach((qlxx) => {
            allParams.forEach((params) => {
              if ((!qlxx[params] && qlxx[params] !== 0) || qlxx[params] === '') {
                message = '请确认所有不动产表格信息都填写完整';
                return message;
              }
            });
          });
        }
      }
    });

    function getAllParams(config) {
      let array = [];
      config.config.forEach((pitem) => {
        if (pitem.key === 'qlxxExMhs' && pitem.type === 'table-qlxx') {
          pitem.dialogFormConfig.forEach((item) => {
            item.value.forEach((i) => {
              i.rule &&
                i.rule.map((rules) => {
                  for (let key in rules) {
                    if (key === 'required') {
                      array.push(i.key);
                    }
                  }
                });
            });
          });
        }
      });
      return array;
    }
    return message;
  },
};

export default qlxxService;
