/**
 * base64 转 Blob对象
 * @param urlData
 */
export function base64UrlToBlob(urlData) {
  //去掉url的头，并转换为byte
  if (!urlData) {
    return false;
  }
  let bytes = window.atob(urlData.split(',')[1]);
  //处理异常,将ascii码小于0的转换为大于0
  let ab = new ArrayBuffer(bytes.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/png' });
}
