<template>
  <div>
    <div class="check-all">
      <el-checkbox
        v-if="!selectMode"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
    </div>
    <el-table :data="tableData" border class="table">
      <el-table-column
        label="实际层"
        header-align="center"
        align="center"
        width="150px"
        prop="sjc"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-if="!selectMode"
            :disabled="scope.row.units.length > 0 ? false : true"
            v-model="scope.row.checked"
            @change="changeHandler('row', scope.$index, scope.row)"
          ></el-checkbox>
          <span class="e-span">第{{ scope.row.sjc }}层</span>
        </template>
      </el-table-column>
      <el-table-column
        label="名义层"
        header-align="center"
        align="center"
        width="150px"
        prop="myc"
      >
        <template slot-scope="scope">
          <span class="e-span">第{{ scope.row.myc }}层</span>
        </template>
      </el-table-column>

      <template v-for="(unitItem, index) in unitArr">
        <el-table-column
          :label="unitItem.unit + '单元'"
          :key="index"
          prop="units"
          header-align="center"
          :width="150 * unitItem.maxHs + 'px'"
        >
          <template slot-scope="scope">
            <div
              class="hh-item"
              v-for="(item, hhIndex) in scope.row.units[index]"
              :key="hhIndex"
            >
              <div>
                <el-checkbox
                  v-if="!selectMode"
                  v-model="item.checked"
                  @change="changeHandler('hu', hhIndex, item, scope.$index)"
                ></el-checkbox>
                <el-radio
                  v-else
                  v-model="radioVal"
                  :label="item.id"
                  @change="changeRadioHandler(item)"
                ></el-radio>
                <span>{{ item.shbw }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <el-button @click="submit">提交</el-button>
  </div>
</template>

<script>
/**@props
 * @param {Boolean} selectMode - 选择模式 默认为多选.
 * @param {Array} unitArr -单元信息 [{unit: '单元号', maxHs: '每个单元最多多少户'}]
 * @param {tableData} unitArr -表格数据 [{sjc: '实际层', myc: '名义层', checked: '选中状态', units:[[该层此单元的户]]}]
 * @description 楼盘表的表格组件.
 */
export default {
  props: {
    selectMode: {
      type: Boolean,
      default: false
    },
    unitArr: {
      type: Array,
      default: () => {
        return [
          {
            unit: 1,
            maxHs: 2
          },
          {
            unit: 2,
            maxHs: 3
          }
        ];
      }
    },
    tableData: {
      type: Array,
      default: () => {
        return [
          {
            sjc: '2',
            myc: '2',
            checked: false,
            units: [
              [
                {
                  id: '11',
                  shbw: '1-101',
                  checked: false
                },
                {
                  id: '12',
                  shbw: '1-102',
                  checked: false
                }
              ],
              [
                {
                  id: '21',
                  shbw: '2-101',
                  checked: false
                },
                {
                  id: '22',
                  shbw: '2-102',
                  checked: false
                },
                {
                  id: '23',
                  shbw: '2-103',
                  checked: false
                }
              ]
            ]
          },
          {
            sjc: '1',
            myc: '1',
            checked: false,
            units: [
              [
                {
                  id: '112',
                  shbw: '1-101',
                  checked: false
                }
              ]
            ]
          }
        ];
      }
    }
  },
  data() {
    return {
      checkedData: [],
      radioVal: '',
      checkAll: false
      // unitArr: [
      //   {
      //     unit: 1,
      //     maxHs: 2
      //   },
      //   {
      //     unit: 2,
      //     maxHs: 3
      //   }
      // ],
      // tableData: [
      //   {
      //     sjc: '2',
      //     myc: '2',
      //     checked: false,
      //     units: [
      //       [
      //         {
      //           id: '11',
      //           shbw: '1-101',
      //           checked: false
      //         },
      //         {
      //           id: '12',
      //           shbw: '1-102',
      //           checked: false,
      //         }
      //       ],
      //       [
      //         {
      //           id: '21',
      //           shbw: '2-101',
      //           checked: false,
      //         },
      //         {
      //           id: '22',
      //           shbw: '2-102',
      //           checked: false,
      //         },
      //         {
      //           id: '23',
      //           shbw: '2-103',
      //           checked: false,
      //         }
      //       ]
      //     ]
      //   },
      //   {
      //     sjc: '1',
      //     myc: '1',
      //     checked: false,
      //     units: [
      //       [
      //         {
      //           id: '112',
      //           shbw: '1-101',
      //           checked: false
      //         }
      //       ]
      //     ]
      //   }
      // ]
    };
  },
  methods: {
    changeHandler(type, index, item, floorNum) {
      // 每层
      if (type === 'row') {
        if (item.checked) {
          this.changeData(item, true);
          const AllCheckedFlag = this.setTableRoewChecked(this.tableData);
          // console.log('全选：'+AllCheckedFlag)
          if (AllCheckedFlag) {
            this.checkAll = true;
          }
        } else {
          this.checkAll = false;
          this.changeData(item, false);
        }
      } else if (type === 'hu') {
        // console.log(item)
        if (item.checked) {
          // console.log('户选中')
          const rowCheckedFlag = this.setTableRoewChecked(
            this.tableData[floorNum].units
          );
          if (rowCheckedFlag) {
            this.tableData[floorNum].checked = true;
          }
          const AllCheckedFlag = this.setTableRoewChecked(this.tableData);
          if (AllCheckedFlag) {
            this.checkAll = true;
          }
        } else {
          // console.log('户未选择')
          if (this.tableData[floorNum].checked) {
            this.tableData[floorNum].checked = false;
            this.checkAll = false;
          }
        }
      }
    },
    // 改变数据的选中状态
    changeData(item, bool) {
      const units = item.units;
      units.forEach((itemUnit) => {
        itemUnit.forEach((item) => {
          item.checked = bool;
        });
      });
      // console.log('转化后')
      // console.log(item)
      // return item;
    },
    // 若每层的每户全部选中 则整层为选中状态
    setTableRoewChecked(arr) {
      // console.log('扁平化')
      const result = this.flatten(arr);
      // console.log(result);
      return result.every((item) => {
        return item.checked === true;
      });
    },
    // 数组扁平化
    flatten(arr) {
      var newArr = arr.reduce((prev, current) => {
        return prev.concat(
          Array.isArray(current) ? this.flatten(current) : current
        );
      }, []);
      return newArr;
    },
    // 全选按钮事件
    handleCheckAllChange(val) {
      if (val) {
        // console.log('全选')
        this.tableData.map((item) => {
          item.checked = true;
          this.changeData(item, true);
        });
      } else {
        // console.log('全不选')
        this.tableData.map((item) => {
          item.checked = false;
          this.changeData(item, false);
        });
      }
    },
    // 单选户
    changeRadioHandler(item) {
      // console.log(item)
      this.checkedData[0] = item;
    },
    // 提交
    submit() {
      this.checkedData = [];
      if (!this.selectMode) {
        // console.log(this.tableData)
        this.tableData.forEach((rowItem) => {
          // console.log(rowItem.units)
          rowItem.units.forEach((unitItem) => {
            unitItem.forEach((item) => {
              // console.log(item)
              if (item.checked) {
                this.checkedData.push(item);
              }
            });
          });
        });
      }
      console.log(this.checkedData);
    }
  }
};
</script>

<style lang="scss" scoped>
.hh-item{
  display: inline-block;
  box-sizing: border-box;
  width: 149px;
  padding: 12px 5px;
  border-right: 1px solid #ebeef5;
  &:last-child {
    border-right: none;
  }
}
.table {
  /deep/ .cell {
    padding: 0;
  }
  /deep/ td {
    padding: 0 !important;
    .cell {
      padding: 0 !important;
    }
  }
}
/deep/ .el-checkbox:last-of-type {
  margin-right: 5px;
}
.check-all {
  padding: 10px;
  text-align: left;
}
/deep/ .el-radio {
  margin-right: 5px;
  .el-radio__label {
    display: none;
  }
}
</style>
