<template>
  <span>{{ dicSpan }}</span>
</template>

<script>
/**
 * 字典文本组件
 */
//import { localDataDic } from '@/utils/local-data';
export default {
  props: ["type", "binddata"],
  data() {
    return {};
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    dicSpan() {
      if (this.type) {
        const dic = localStorage.IEBDC_DIC_DATA || localStorage.DIC_DATA;
        this.allDic = JSON.parse(dic).dic;
        const dicArr = this.allDic.find((item) => {
          return item.label === this.type;
        });
        // 判断子集非空
        if (dicArr && dicArr.childrens) {
          const filterObj = dicArr.childrens.filter((item) => {
            if (typeof this.binddata === "string") {
              return item.value === this.binddata;
            } else if (typeof this.binddata === "number")
              return item.value === this.binddata.toString();
          });
          if (filterObj.length > 0) {
            return filterObj[0].label;
          } else {
            return "";
          }
        }
      }
    },
  },
};
</script>
