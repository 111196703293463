/**
 * @description 添加一个cookie
 * @method addCookie
 * @param {String} name cookie名称
 * @param {String} value cookie值
 * @param {Number} expiresDays 多少天后会过期
 */
export function addCookie(name, value, expiresDays) {
  let cookieString = name + '=' + escape(value);
  if (expiresDays > 0) {
    let date = new Date();
    date.setTime(date.getTime() + expiresDays * 24 * 3600 * 1000);
    cookieString = cookieString + ';expires=' + date.toGMTString();
  }
  cookieString += ';path=/';
  document.cookie = cookieString;
};
/**
 * @method removeCookie
 * @param {String} name 要删除的cookie名称
 */
export function removeCookie(name) {
  let date = new Date();
  date.setTime(date.getTime() - 1);
  let cookieString = name + '=temp' + ';expires=' + date.toGMTString();
  cookieString += ';path=/';
  document.cookie = cookieString;
};

/**
 * 批量删除
 * @param keys
 */
export function removeCookiePatch(keys) {
	keys.forEach((k) => {
		removeCookie(k);
	});
};

/**
 * @description 获取指定名称的cookie值
 * @method getCookie
 * @param {String} name cookie名称
 * @return {String} cookie值
 */
export function getCookie(name) {
  let strCookie = document.cookie;
  let arrCookie = strCookie.split('; ');
  for (let i = 0; i < arrCookie.length; i++) {
    let arr = arrCookie[i].split('=');
    if (arr[0] === name) {
      return unescape(arr[1]);
    }
  }
  return '';
};
/**
 * 清空登录缓存值
 * @method clearCookie
 * @static
 */
export function clearCookie() {
  let strCookie = document.cookie;
  let arrCookie = strCookie.split('; ');
  for (let i = 0; i < arrCookie.length; i++) {
    let arr = arrCookie[i].split('=');
    this.removeCookie(arr[0]);
  }
};
