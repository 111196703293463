<template>
  <el-dialog
    :visible.sync="visible"
    center
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :before-close="close"
    width="70%"
  >
    <!-- 标题 -->
    <div slot="title" class="header-title text-left">
      <span>不动产信息</span>
    </div>
    <!-- 表单区域 -->
    <dynamic-form-old
      class="bod"
      v-if="formvisible"
      ref="form"
      :form-config="formConfig"
      :formModel="qlxxData"
      :disabled="disabled"
    />
    <!-- 底部操作按钮 -->
    <span slot="footer" v-show="this.receiveParams.buttonService && !disabled">
      <el-button @click="submit" size="small" type="primary">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import applicantOperatorService from '@/pages/iebdc/api/apply/applicant-operator';
import qlxxService from '@/pages/iebdc/api/apply/applicant-qlxx';
export default {
  props: {
    formConfig: Array,
    disabled: Boolean
  },
  data() {
    return {
      // 弹窗状态
      visible: false,
      //表单显示
      formvisible: false,
      // 当前弹窗操作类型(保存、编辑)
      currentOperateType: '',
      //表单数据
      qlxxData: {},
      //传入的数据
      receiveParams: {}
    };
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData'])
  },
  methods: {
    //初始化申请数据
    initqlxxData(params, qlxxExMhs) {
      this.qlxxData = Object.assign(qlxxExMhs, params.rowData);
    },
    getQlxxInfo(params) {
      if (params.rowData.bdcdyh) {
        applicantOperatorService[params.searchService]({ bdcdyh: params.rowData.bdcdyh }).then((res) => {
          if (res.success && res.data) {
            const qlxxExMhs = qlxxService.getQlxxByHousetable(res.data) || {};
            this.openDialog(params, qlxxExMhs);
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.openDialog(params, {});
      }
    },
    openDialog(params, qlxxExMhs) {
      console.log('openDialog', params, qlxxExMhs);
      //当前传入参数数据
      this.receiveParams = params;
      //没有就初始化数据
      this.initqlxxData(params, qlxxExMhs);
      //显示弹框
      this.visible = true;
      this.formvisible = true;
      this.$nextTick(() => {
        // 清空表单验证
        this.$refs.form.resetFields();
      });
    },
    // 打开弹窗
    open(params) {
      console.log('open', params);
      this.getQlxxInfo(params);
    },
    // 接口提交成功
    success(msg) {
      this.$emit('refresh');
      this.$parent.visible = false;
      this.visible = false;
      this.formvisible = false;
      this.$message.success(msg);
    },
    // 新增权利人
    submit() {
      const otherParams = {
        qlxxExMhList: JSON.parse(JSON.stringify(this.receiveParams.qlxxList)),
        wwywh: this.flowInfoIng.ywh,
        qlxxId: this.flowInfoIng.id
      };
      let params = this.$refs.form.getData();
      for (var k in params) {
        if (params[k] === null) {
          delete params[k];
        }
      }
      if (this.receiveParams.index >= 0) {
        otherParams.qlxxExMhList[this.receiveParams.index] = params;
      } else {
        otherParams.qlxxExMhList.push(params);
      }
      const hblc = ['H999001'].includes(this.flowInfoIng.code)
      console.log('this.flowInfoIng', this.flowInfoIng)
      console.log('this.otherParams', otherParams)
      this.$refs.form.$refs.form.validate((valid) => {
        if (valid) {
          applicantOperatorService[this.receiveParams.buttonService](otherParams).then((res) => {
            if (res.success) {
              // 提交成功
              // 同步另一表单不动产信息
              if (this.processData.length > 1 && !hblc) {
                this.sameBdcqzh(otherParams.qlxxExMhList);
              } else {
                this.success('保存成功');
              }
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error('请完善表单');
        }
      });
    },
    //保存相同的不动产信息
    sameBdcqzh(qlxxExMhList) {
      let ywh = this.flowInfoIng.wwywh; //相同的不动产的子业务号
      let index = this.flowInfoIng.index; //相同的对应的表单
      //找到对应的另一个表格中的不动产信息对饮的业务号和index
      if (Number(this.flowInfoIng.index) === 0) {
        ywh = this.flowInfoIng.wwywh + '-2';
        index = 1;
      } else if (Number(this.flowInfoIng.index) === 1) {
        ywh = this.flowInfoIng.wwywh + '-1';
        index = 0;
      }
      //循环给对应的不动产信息赋值，相应保存
      let sameqlxxExMhList = [];
      if (this.processData[index]['qlxxExMhs']) {
        sameqlxxExMhList = JSON.parse(JSON.stringify(this.processData[index]['qlxxExMhs']));
      } else {
        return;
      }
      sameqlxxExMhList.forEach((qlxx) => {
        qlxxExMhList.forEach((xgqlxx) => {
          if (xgqlxx.bdcdyh === qlxx.bdcdyh) {
            for (let key in qlxx) {
              if (xgqlxx[key]) {
                qlxx[key] = xgqlxx[key];
              }
            }
          }
        });
      });
      const otherParams = {
        qlxxExMhList: sameqlxxExMhList,
        wwywh: ywh,
        qlxxId: this.flowInfoIng.id
      };
      applicantOperatorService[this.receiveParams.buttonService](otherParams).then((res) => {
        if (res.success) {
          // 提交成功
          // 同步另一表单不动产信息
          this.success('保存成功');
        } else {
          this.$message.error(res.message);
        }
      });
    },
    close() {
      this.visible = false;
      this.formvisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
@import 'src/styles/public';
/deep/ .el-dialog__header {
  margin: 0 26px;
  padding: 20px;
  font-size: $font-size-medium;
  font-weight: bold;
  border-bottom: $border-base;
}
/deep/ .el-dialog--center .el-dialog__body {
  @extend .scrollbar-default;
  padding: 0 26px 30px;
}
.header-title {
  font-weight: bold;
  font-size: $font-size-medium;
  .el-button {
    margin-left: $spacing-medium;
  }
}
.bod {
  border-bottom: 1px solid #ddd;
}
</style>
