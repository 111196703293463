<template>
  <div class="wrap">
    <!-- 循环组件 -->

    <div v-for="(config, i) in tableConfig" :key="i" style="min-width: 750px">
      <table cellpadding="0" cellspacing="0" border="0" width="100%" v-if="config.type === 'table-sqr'">
        <tr v-for="(iconfig, j) in config.data" :key="j">
          <td width="50px" class="table-row-title" v-if="j === 0" :rowspan="config.data.length">
            <!-- 表格标题 -->
            <div class="type-title">
              <h5>申请人情况</h5>
            </div>
          </td>
          <td class="table-row">
            <!-- 表格区域 -->
            <common-sqr
              ref="sqr"
              @getData="getData"
              :commonTableConfig="iconfig"
              :formModel="formData"
              :disabled="disabled"
              :sameDisable="getSameDisable(iconfig.category)"
            ></common-sqr>
          </td>
        </tr>
      </table>

      <table cellpadding="0" cellspacing="0" border="0" width="100%">
        <tr v-if="config.type === 'table-qlxx' && showtable">
          <td width="50px" class="table-row-title">
            <!-- 表格标题 -->
            <div class="type-title">
              <h5>不动产情况</h5>
            </div>
          </td>
          <td class="table-row">
            <!-- 表格区域 -->
            <common-qlxx
              ref="qlxxExMhs"
              v-if="config.type === 'table-qlxx' && showtable"
              @getData="getData"
              :formIndex="formIndex"
              :commonTableConfig="config"
              :formModel="formData"
              :disabled="disabled"
            ></common-qlxx>
          </td>
        </tr>
      </table>

      <table cellpadding="0" cellspacing="0" border="0" width="100%">
        <tr v-if="config.type === 'form' && ((config.key === 'qlxxExMhs' && !showtable) || config.key !== 'qlxxExMhs')">
          <td width="100px" class="table-row-title">
            <!-- 表格标题 -->
            <div class="type-title">
              <h5>{{ config.tableTitle }}</h5>
            </div>
          </td>
          <td class="table-row">
            <!-- 表格区域 -->
            <dynamic-form-old
              ref="qlxx"
              @setData="setData"
              :form-config="config.formConfig"
              :keyName="config.key"
              :formModel="config.key === 'qlxxExMhs' ? formData[config.key][0] || {} : formData[config.key] || {}"
              :disabled="disabled"
              :getAllTableColumnConfig="getAllTableColumnConfig"
            />
          </td>
        </tr>
      </table>
    </div>

    <!-- <dynamic-form-old
        v-if="config.type === 'form' && ((config.key === 'qlxxExMhs' && !showtable) || config.key !== 'qlxxExMhs')"
        ref="qlxx"
        @setData="setData"
        :form-config="config.formConfig"
        :keyName="config.key"
        :formModel="config.key === 'qlxxExMhs' ? formData[config.key][0] || {} : formData[config.key] || {}"
        :disabled="disabled"
        :getAllTableColumnConfig="getAllTableColumnConfig"
      /> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { sameSqr } from '@public/applyConfig/sameSqr';
export default {
  props: {
    configs: {
      type: Array,
      default: () => [],
    },
    formData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    getAllTableColumnConfig: {
      type: Array,
      default: () => [],
    },
    formIndex: {
      //当前tab所在大表格
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
    ...mapState('wysq-data', ['processData']),
    // 计算表格配置，可根据后台返回数据，进行不同渲染，目前未处理 TODO
    tableConfig() {
      return this.configs;
    },
    showtable() {
      return this.flowInfoIng.sqrlx === 'NORMAL' ? false : true;
    }
  },
  methods: {
    ...mapMutations('wysq-data', ['setFormDataKey']),
    // 重新获取数据
    getData() {
      this.$emit('getProcessData');
    },
    //保存值
    setData(data) {
      data.formIndex = this.formIndex;
      this.setFormDataKey(data);
    },
    //相同的申请人
    getSameDisable(category) {
      let boolean = false;
      sameSqr.forEach((data) => {
        if (data.code === this.flowInfoIng.code) {
          if (category === data.same.form1) {
            boolean = true;
          }
        }
      });
      return boolean;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/styles/common-variables';
.wrap {
  border-radius: $border-radius-base;
  background-color: $background-color-layout;
  border-bottom: 1px solid #c6d5ea;
}
.table-row {
  border-top: 1px solid #c6d5ea;
  border-right: 1px solid #c6d5ea;
  .zwg {
    width: calc(100% - 100px);
  }
}
.table-row-title {
  border-top: 1px solid #c6d5ea;
  border-left: 1px solid #c6d5ea;
  background-color: #f2f6fc;
  width: 50px;
  padding: 0px 10px;
  > div {
    min-height: 32px;
  }
}
.type-title {
  min-height: 32px;
  display: block;
  text-align: center;
  h5 {
    margin: 10px;
    font-size: 15px;
    font-weight: 600;
  }
  .el-button {
    margin: 10px;
  }
}
/deep/.el-form-item__content {
  .el-date-editor--date {
    width: 100%;
  }
}

/** ie浏览器字颜色设置*/
/deep/ input:-ms-input-placeholder {
  color: #b4bccc !important;
}
</style>
