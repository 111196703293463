import request from 'manager/utils/request';
import { REST_SERVICE } from 'manager/config';

const payVerify = {
  //查询缴费结果列表
  selectPayApplyPageList(params) {
    return request({
      url: REST_SERVICE.payVerify.selectPayApplyPageList,
      method: 'get',
      params
    });
  },
  selectByParams(data) {
    return request({
      url: REST_SERVICE.payVerify.selectByParams,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    });
  },
  savePayApply(data) {
    return request({
      url: REST_SERVICE.payVerify.savePayApply,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    });
  },
  checkApply(data) {
    return request({
      url: REST_SERVICE.payVerify.checkApply,
      method: 'post',
      // headers: {
      //   'Content-Type': 'application/json;charset=UTF-8'
      // },
      data
    });
  },
  saveDuplicatePayApply(data) {
    return request({
      url: REST_SERVICE.payVerify.saveDuplicatePayApply,
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data
    });
  }
};
export default payVerify;
